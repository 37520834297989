import React from 'react'
import {  toAbsoluteUrl } from 'src/helpers'
import a from "public/asset/image/NPCL_Login_Page_with_Oct.png"
type Props = {}

const P2penrgyBenefits = (props: Props) => {
    const IMG_P2P_ENERGY_SCREEN_1=toAbsoluteUrl('/asset/image/NPCL_Dashboard_Img.png')
    const IMG_ROUNDED_1=toAbsoluteUrl('/asset/image/rounded_1.png')
    const IMG_ROUNDED_2=toAbsoluteUrl('/asset/image/rounded_2.png')
    const IMG_ROUNDED_3=toAbsoluteUrl('/asset/image/rounded_3.png')
    const IMG_P2P_ENERGY_BENEFITS_BG=toAbsoluteUrl('/asset/image/P2PBenifitBackground.png')
    
    return (
        <div className='h-[600px] p-8' style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_BENEFITS_BG + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className='max-w-[80vw] mx-auto'>
                <div className='flex flex-col justify-center items-center space-y-2'>
                    {/* <span className='text-sm uppercase '>Peer-to-Peer Solar Energy Trading for a Greener Tomorrow</span> */}
                    {/* <span className='h-[2px] bg-primary w-[50px]'></span> */}
                    <span className='font-bold text-3xl'>Benefits of P2P Solar Energy Trading </span>
                </div>
                <div className=' grid grid-cols-2 mt-16 ' >
                    <div>
                        <img src={IMG_P2P_ENERGY_SCREEN_1} style={{ height: "400px" }} />
                    </div>
                    <div className='flex justify-center mt-[50px]' >
                        <div className='space-y-8 max-w-[80%]  relative'>
                            <div className='flex space-x-2 items-center  '>
                                <img src={IMG_ROUNDED_1} style={{ height: "50px",zIndex:10}} />
                                <div>Turn your excess energy into earnings or buy clean energy directly from your community.</div>
                            </div>
                            <div className='flex space-x-2 items-center  '>
                                <img src={IMG_ROUNDED_2} style={{ height: "50px",zIndex:10}} />
                                <div>Get clean, renewable energy at lower prices than the traditional grid.</div>
                            </div>
                            <div className='flex space-x-2 items-center  '>
                                <img src={IMG_ROUNDED_3} style={{ height: "50px",zIndex:10}} />
                                <div>Support a sustainable energy system and reduce carbon emissions by trading clean energy.</div>
                            </div>
                            <div className='absolute h-[50%] top-0 bg-gray-50 w-[30px] border-r-2 border-r-orange-500 border-dotted'></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default P2penrgyBenefits