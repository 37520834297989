import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { axiosWithToken } from "src/service/axioseSetup";
import axios, { AxiosError } from "axios";
import { PaginationState } from "src/helpers";

// Interface for wallet state
interface IWalletData {
    amount: number | undefined;
    userId: string | undefined;
    loading: boolean;
    error: string | null;
    walletHistory: {
        data: any[],
        payload: {
            pagination: PaginationState
        }
    } | undefined
}

const DEFAULT_DATA: any = {
    data: [],
    payload: {
        pagination: {
            page: 1,
            items_per_page: 10
        }
    }
}

// Initial state
const initialState: IWalletData = {
    amount: undefined,
    userId: undefined,
    loading: false,
    error: null,
    walletHistory: DEFAULT_DATA
};

// Async thunk for fetching wallet amount
export const fetchWalletAmountByUserId: any = createAsyncThunk(
    "wallet/fetchWalletAmountByUserId",
    async (data: any, thunkApi: any) => {
        try {
            // Replace this with your actual API call
            const response = await axiosWithToken.get("/transaction/wallet-balance").then((resp: any) => resp.data)
            return response.data.balance; // Assuming API returns { amount: number }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));

            } else {
                // toast.error(error.message)
                thunkApi.dispatch(setError(error?.message));
            }
        }
    }
);

export const fetchWalletHistory: any = createAsyncThunk(
    "wallet/fetchWalletHistory",
    async (query: any, thunkApi: any) => {
        try {
            // Replace this with your actual API call
            console.log({query});
            
            const response = await axiosWithToken.get(`/transaction/wallet-history?${query || ""}`).then((resp: any) => resp.data)
            let responseData = response.data;
            if (response.data?.data && response.data?.data.length > 0) {
                const pagination = response.data?.payload?.pagination
                const data = response.data?.data.map((d: any, indx: any) => {
                    return {
                        srNo: ((Number(pagination.page) - 1) * pagination.items_per_page) + (indx + 1),
                        ...d
                    }
                })
                return { data, payload: response.data?.payload }
            }
            // return response.data; // Assuming API returns { amount: number }
        } catch (_error) {
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));

            } else {
                // toast.error(error.message)
                thunkApi.dispatch(setError(error?.message));
            }
        }
    }
);


const walletSlicer = createSlice({
    name: "wallet/slice",
    initialState,
    reducers: {
        updateWalletAmount(state, action: PayloadAction<number>) {
            state.amount = action.payload;
        },
        updateUserId(state, action: PayloadAction<string>) {
            state.userId = action.payload;
        },
        clearWallet(state) {
            state.amount = undefined;
            state.userId = undefined;
            state.error = null;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload.message;
        },
        resetError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWalletAmountByUserId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWalletAmountByUserId.fulfilled, (state, action: PayloadAction<number>) => {
                state.loading = false;
                state.amount = action.payload;
            })
            .addCase(fetchWalletAmountByUserId.rejected, (state, action) => {
                state.loading = false;
                // state.error = action.payload || "Failed to fetch wallet amount";
            })
            .addCase(fetchWalletHistory.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.walletHistory = DEFAULT_DATA
            })
            .addCase(fetchWalletHistory.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.walletHistory = action.payload;
            })
            .addCase(fetchWalletHistory.rejected, (state, action) => {
                state.loading = false;
                state.walletHistory = DEFAULT_DATA
                // state.error = action.payload || "Failed to fetch wallet amount";
            });
    },
});

// Export actions
export const { updateWalletAmount, updateUserId, clearWallet, setError, resetError, } = walletSlicer.actions;

// Selector for wallet state
export const getWalletReducerState = (state: RootState) => state.wallet;

// Export reducer
export default walletSlicer.reducer;