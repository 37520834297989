import * as Yup from "yup";

export interface ICreatePassword {
    password: string | undefined;
    confirm_password: string | undefined;
    checks?: Boolean;
}

export const IntialValuesCreatePassword = {
    password: '',
    confirm_password: "",
    checks: false
}

// export const CreatePasswordValidation = Yup.object().shape({
//     password: Yup.string()
//         .required("Password is required")
//         .min(8, 'Password must contain 8 or more characters')
//         .max(18, 'Password must be less than or equal to 18 characters')
//         .test('lowercase', 'Password must contain at least 1 lowercase letter', value => /[a-z]/.test(value))
//         .test('uppercase', 'Password must contain at least 1 uppercase letter', value => /[A-Z]/.test(value))
//         .test('number', 'Password must contain at least 1 number', value => /[0-9]/.test(value))
//         .test('special', 'Password must contain at least 1 special character', value => /[!@#$%^&*(),.?":{}|<>]/.test(value)),

//     confirm_password: Yup.string()
//         .required("Confirm Password is required"),
//     // .min(8, 'Password must contain 8 or more characters')
//     // .max(18, 'Password must be less than or equal to 18 characters')
//     // .test('lowercase', 'Password must contain at least 1 lowercase letter', value => /[a-z]/.test(value))
//     // .test('uppercase', 'Password must contain at least 1 uppercase letter', value => /[A-Z]/.test(value))
//     // .test('number', 'Password must contain at least 1 number', value => /[0-9]/.test(value))
//     // .test('special', 'Password must contain at least 1 special character', value => /[!@#$%^&*(),.?":{}|<>]/.test(value)),
//     checks: Yup.boolean().oneOf(
//         [true],
//         "You must accept the terms and conditions"
//     ),
// })

const passwordValidation = Yup.string()
  .min(8, "Password must be at least 8 characters")
  .max(18, 'Password must be less than or equal to 18 characters')
  .test('lowercase', 'Password must contain at least 1 lowercase letter', value => /[a-z]/.test(value ?? ''))
  .test('uppercase', 'Password must contain at least 1 uppercase letter', value => /[A-Z]/.test(value ?? ''))
  .test('number', 'Password must contain at least 1 number', value => /[0-9]/.test(value ?? ''))
  .test('special', 'Password must contain at least 1 special character', value => /[!@#$%^&*(),.?":{}|<>]/.test(value ?? ''))

export const ChangePasswordValidation = Yup.object().shape({
    password: passwordValidation.required("Password is required"),
    checks: Yup.boolean().oneOf(
        [true],
        "You must accept the terms and conditions"
    ),
    // confirm_password: passwordValidation.test('passwords-match', 'Passwords must match', function (value) {
    //     return this.parent.password === value;
    // }),
    confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('password')], 'Confirm Password does not match'),
});
