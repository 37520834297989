import React from 'react'
import OnboardingImg from "../../../../src/assets/images/OnboardingImg.svg"
import { Outlet } from 'react-router-dom'
const OnboardingLayout = () => {
    return (
        <div className='w-full h-screen'>
            <div className='w-full h-screen flex'>
                <div className='w-[25%] h-full bg-primaryBlue flex justify-center'>
                    <img src={OnboardingImg} alt='onboarding' />
                </div>
                <div className='w-[75%] h-full'>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default OnboardingLayout