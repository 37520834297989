export const API_URL = process.env.REACT_APP_BASE_URL + "/api/v1";
export const AUTH_URL = process.env.REACT_APP_BASE_URL_AUTH + '/api/v1/web'

export const AUTH_URL_PROFILE=process.env.REACT_APP_BASE_URL_AUTH + '/api/v1'
export const SSE_URL = process.env.REACT_APP_BASE_URL_SSE
export const DASHBOARD_URL = process.env.REACT_APP_BASE_URL_DASHBOARD + '/sse/api'
export const VIEW_BILL_PDF = process.env.REACT_APP_BASE_URL_BILL 
export const API_URL_BLOCKCHAIN = process.env.REACT_APP_BASE_URL_BLOCKCHAIN_URL
export const NOTIFICATION_SERVICES=process.env.REACT_APP_BASE_URL_NOTIFICATION_SERVICES+"/api/v1"
export const P2P_LANDING_PAGE=process.env.REACT_APP_BASE_URL_P2P_LANDING_PAGE
export const REPORT_SERVICE=process.env.REACT_APP_BASE_URL_REPORT_SERVICES+"/api/v1"
// Auth API

export const URL_LOGIN_OTP = AUTH_URL + "/auth/login-by-cno-phone";
export const URL_LOGIN_OTP_VALIDATE = AUTH_URL + "/auth/login-via-consumer-otp-validate";
export const URL_VERIFY_TOKEN = AUTH_URL + "/auth/verify-token";
export const URL_LOGIN_USERNAME_PASSWORD = AUTH_URL + "/auth/login";
export const URL_LOGIN_PHONE_NUMBER_FORGOT_PASSWORD = AUTH_URL + '/auth/login-via-phone-otp'
export const URL_LOGIN_CONSUMER_NUMBER_FORGOT_PASSWORD = AUTH_URL + '/auth/forgot-password-2'
export const URL_FORGOT_PASSWORD = AUTH_URL + '/auth/reset-password'
export const URL_NEW_RESEND_OTP=AUTH_URL+"/auth/resend-otp"

// Regestration url
export const URL_USER_REGESTRATION = API_URL + "/registration/user";

// dropdown url
export const URL_GET_ALL_STATE = API_URL + "/state/web/getAll";
export const URL_GET_DISCOM = (id: string) => API_URL + "/discoms/web/get" + "/" + id;
export const URL_GET_METER_NUMBER = (id: string) => API_URL + "/meter/get" + "/" + id
// StepwiseRegration

export const URL_STEP_1_LOAD_DETAILS = API_URL + "/load_profile/register";
export const URL_STEP_2_METERS_DETAILS = API_URL + "/meter/register";
export const URL_STEP_3_PLANT_DETAILS = API_URL + "/plant/register";

// Profile API
export const URL_EDIT_PROFILE = (id: string) => API_URL + "/user/profile" + "/" + id;
export const URL_CHANGE_PASSWORD = AUTH_URL + "/auth/change-password";
export const LOAD_DETAILS_TRADE_API = (id: string) => API_URL + '/load_profile/get' + '/' + id
export const PLANT_DETAILS_TRADE_API = (id: string) => API_URL + '/plant/get' + '/' + id
export const METER_DETAILS_TRADE_API = (id: string) => API_URL + '/meter/get' + '/' + id
export const NEW_DETAIL_API= (id: string) =>AUTH_URL_PROFILE+"/user/profile-2"+"/"+id
export const NEW_PROFILE_API=(id: string) => AUTH_URL_PROFILE + '/user/profile-2' + '/' + id
// Dashboard Api

export const URL_CALENDER_DATA = (id: string) => API_URL + "/calendar/fetch" + "/" + id;
export const URL_PROSUMER_LIST = API_URL + "/bid/buyers-list";
export const URL_BID_API = API_URL + "/bid/register";
export const URL_CONSUMER_LIST = API_URL + "/ask/suppliers-list";
export const URL_ASK_API = API_URL + "/ask/register";
export const URL_SUMMARY_DASHBOARD_API = API_URL + '/dashboard/get/user/trends'
export const CROUSAL_URL=API_URL+"/banner/get"
export const NEW_URL_SUMMARY_DASHBOARD_API=API_URL+"/dashboard/v2"
export const NEW_TOP_PROSUMER_CONSUMER_URL=API_URL+"/dashboard/top-rankers"
export const NEW_OTS_LINE_CHART=API_URL+"/dashboard/activity-chart"

// export const URL_OTS_GRAPH = API_URL + "/dashboard/get/weekly/trends";
export const URL_MARKET_DEPTH = API_URL + "/order/get/market/depth";
// export const URL_NEXT_TRADES = API_URL + "/dashboard/new/weekly/trades";
export const URL_NEXT_TRADES = DASHBOARD_URL + "/ltp"
export const URL_OTS_GRAPH = DASHBOARD_URL + "/ots";

// History Api
export const EVENT_HISTORY_DATA = API_URL + "/event-history/get-all";

// Trade prosumer trading 

// export const TRADE_PROSUMER_LIST_URL = API_URL + "/order/getAll";
export const TRADE_PROSUMER_LIST_URL = API_URL + "/order/prefential-list";
export const TRADE_CONFIRMATION_PROSUMER_LIST_URL = API_URL + "/order/confirmation";
// export const TRADE_ORDER_REQUEST = API_URL + "/trade/request";
export const TRADE_ORDER_REQUEST = API_URL + "/trade/prefential-request";

// Direct Trading

// export const DIRECT_TRADE_ORDER_LIST_URL = API_URL + '/trade/weekly/trades'
// export const DIRECT_TRADE_ORDER_LIST_URL = API_URL + '/trade/market-trade-data'
// export const DIRECT_TRADE_ORDER_REQUEST = API_URL + '/order/request/direct-trade'
export const DIRECT_TRADE_ORDER_LIST_URL = API_URL + '/trade/time-block'
export const DIRECT_TRADE_ORDER_REQUEST = API_URL + '/order/request/timeblock'


// dropdown Config

export const DROP_DOWN_CONFIG_API = API_URL + "/dropdown/config";
export const DROP_DOWN_TIMEBLOCK_API=API_URL+"/dropdown/timeblock"
// ORDER HISTORY

export const ORDER_HISTORY_URL = API_URL + "/order/get-user-orders";
export const ORDER_HISTORY_TRADE_URL = (id: string) => API_URL + "/order/get/trades" + "/" + id;
// export const ORDER_HISTORY_TRADE_BLOCKCHAIN_URL = (id: string) => API_URL_BLOCKCHAIN + "/chaincode/getTrades" + "/" + id;
export const ORDER_HISTORY_TRADE_BLOCKCHAIN_URL = (id: string) => API_URL_BLOCKCHAIN + "/chaincode/getTradesByStatus" + "/" + id;

export const CANCEL_ORDER=(id: string) =>API_URL+"/order/cancel-order" +"/"+ id;
export const MODIFY_ORDER=(id: string) =>API_URL+"/order/modify-order" +"/"+ id

export const LIMIT_FOR_MODIFY_AND_CANCEL_ORDER=API_URL+"/order/user-limit-summary"
// Bill Api

// export const BILL_API_URL = API_URL + "/bills/details";
export const BILL_API_URL = API_URL + "/bills/summary-new";
export const DAILY_TRANSACTION_INVOICE_URL=API_URL+"/bills/all-transactions-new"
export const TRANSACTION_INVOICE_URL=API_URL+"/bills/daily-summary-new"

export const NEW_BILL_API_URL=VIEW_BILL_PDF+"/generate"

export const DOWNLOAD_INVOICE=REPORT_SERVICE+"/download-user-bill"
// SSE URL

export const SSE_OTS_URL = SSE_URL + '/ots'
// export const SSE_LTP_URL = SSE_URL + '/ltp'
export const SSE_LTP_URL = API_URL + '/sse/events'
export const SSE_OTS_URL_WEEKLY = SSE_URL + '/ots/weekly'
export const SSE_OTS_URL_MONTHLY = SSE_URL + '/ots/monthly'
// export const SSE_MARKETH_DEPTH = SSE_URL + "/market_depth"
// export const SSE_MARKETH_DEPTH = API_URL + "/sse/market-depth"
export const SSE_MARKETH_DEPTH = API_URL + "/sse/market-depth"
export const SSE_OTS_NEW_URL = SSE_URL + '/web/ots'
export const SSE_RECENTS_TRANSACTION_URL = SSE_URL + '/transactions'
export const SSE_CANDLESTICK_CHART_URL = SSE_URL + '/hourly_chart'

// Testing Verification Report Api

export const TESTING_VERIFICATION_API = API_URL + '/order/fetch-data'


// contact us and faq page

export const CONTACT_US_PAGE_UPDATE = AUTH_URL_PROFILE + "/contact-us/contact"
export const CONTACT_US_PAGE_GET = API_URL + "/contact-us/contacts"
export const FAQ_PAGE_GET = API_URL + "/faq/faqs"


// New OnBoarding Screen API
export const CHECK_ON_BOARDING = AUTH_URL + "/onboarding/check-onboarding"
export const CHECK_ELIGIBILITY = AUTH_URL + "/onboarding/npcl/verify-token"

// export const CHECK_ELIGIBILITY = AUTH_URL + "/onboarding/check-eligibility"
export const FETCH_USER_DETAILS = AUTH_URL + "/onboarding/fetch-user"
export const REGISTER_USER = AUTH_URL + "/onboarding/register"
export const LOGIN_WITHOUT_PASSWORD = AUTH_URL + "/auth/login-by-cno-phone-email"


// consumer list

export const GET_CONSUMER_LIST=(id: string) =>AUTH_URL+'/onboarding/consumers-list'+"/" + id
// export const UPDATE_CONSUMER_LIST= (id: string) => AUTH_URL + "/onboarding/default" + "/" + id;

export const UPDATE_CONSUMER_LIST = (id: string) => AUTH_URL + "/onboarding/default" + "/" + id

// portfolio 
export const PORTFOLIO_OTS=API_URL+"/dashboard/portfolio-ots"
export const PORTFOLIO_CONSUMPTION_GENRATION=API_URL+"/dashboard/generation-consumption"
export const DAILY_CONSUMPTION_BAR_CHART=REPORT_SERVICE+"/meter-readings/reading-summary"
export const HALF_HOURLY_ENERGY_IMPORT_EXPORT=REPORT_SERVICE+"/meter-readings/daily-lp-readings"
export const TWO_HOURLY_ENERGY_IMPORT_EXPORT=REPORT_SERVICE+"/meter-readings/daily-tb-readings"
// Notification services
export const NOTIFICATION_SERVICES_URL=NOTIFICATION_SERVICES+"/fetch-notifications/user"

export const NOTIFICATION_COUNT=NOTIFICATION_SERVICES+"/fetch-notifications-count/user"