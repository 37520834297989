import { Globe } from "lucide-react";
import MobileDetect from "mobile-detect";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../../assets/images/P2PLogo.png";
import Loader from "../../../../components/ui/common/Loader/Loader";
import { stringifyObjectToRequestQuery } from "../../../../helpers";
import {
  checkEligibilityUser,
  onboardingReducerState,
  onboardingStatus,
  resetOnboardingState,
  updateOnboardingPhoneNumber,
} from "../../../../redux/Slicer/onboardingSlicer";
import { RootState } from "../../../../redux/store";
import { useOnlineStatus } from "../../Core";

const ConsumerBasicDetails = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [userObject, setUserObject] = useState<any>()

  const { onBoardingData, verifyTokenData } = useSelector((state: RootState) =>
    onboardingReducerState(state)
  );


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryString = stringifyObjectToRequestQuery(userObject && userObject);

  const appScheme = `p2p://onboard?${queryString}`;
  const appStoreUrl = `https://apps.apple.com/app/idYOUR_APP_ID?${queryString}`; // iOS App Store URL with parameter
  const playStoreUrl = `https://play.google.com/store/apps/details?id=com.collectionbuddy&${queryString}`; // Android Play Store URL with parameter
  const [showDialog, setShowDialog] = useState<boolean>(false);


  useEffect(() => {
    // console.log('reset state')
    dispatch(resetOnboardingState());
  
  }, []);


  useEffect(() => {
    const params = search && search?.split("?")[1].split("&");

    let searchParams: any = {};
    params &&
      params.forEach((o: any) => {
        let splitParam = o?.split("=");
        searchParams[splitParam[0]] = splitParam[1];
      });
    // setUserObject(searchParams)

    setUserObject(searchParams && searchParams);
    // console.log(searchParams)

    // dispatch(updateuserObject(searchParams));
  //  console.log('search param')
    return () => {};
  }, [search]);



  useEffect(() => {
    // console.log('useEffect checkEligibility',JSON.stringify(userObject))
    if (userObject && Object.keys(userObject)?.length > 0 && userObject?.token) {
      // dispatch(updateUrlOBJ(userObject));
      dispatch(checkEligibilityUser(userObject));
      // console.log(' checkEligibility api call')
    } else return;
  }, [userObject]);

  // useEffect(() => {
  //   if (verifyTokenData && Object?.keys(verifyTokenData)?.length > 0&&verifyTokenData?.consumerNo) {
  //     dispatch(onboardingStatus({ consumerNo: verifyTokenData?.consumerNo }));
  //     localStorage.setItem("x-apiKey", verifyTokenData?.apiKey);
  //   } else return;
  // }, [verifyTokenData]);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile()) {
      setIsMobile(true);
    }
  }, []);

  const handleFallback = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Android/i)) {
      const timeout = 1000; // 2 seconds
      const startTime = new Date().getTime();
      const appWindow: any = window.open(appScheme, "_blank");
      setTimeout(() => {
        const endTime = new Date().getTime();
        if (endTime - startTime < timeout) {
          // App is installed
          // Close the current window or tab
          window.close();
        } else {
          // App is not installed
          appWindow.location.replace(playStoreUrl);
        }
      }, timeout);
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      const timeout = 1000; // 2 seconds
      const startTime = new Date().getTime();
      const appWindow: any = window.open(appScheme, "_blank");
      setTimeout(() => {
        const endTime = new Date().getTime();
        if (endTime - startTime < timeout) {
          // App is installed
          // Close the current window or tab
          window.close();
        } else {
          // App is not installed
          appWindow.location.replace(appStoreUrl);
        }
      }, timeout);
    } else {
      alert("Unable to open the app. Please install it from the store.");
    }
  };

  const continueOnWeb = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    const userAgent = navigator?.userAgent;
    if (
      userAgent?.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      )
    ) {
      setIsMobile(true);
      setShowDialog(true);
    }
  }, [onBoardingData, verifyTokenData, navigate, showDialog, userObject]);

  useEffect(() => {
    if (
      onBoardingData &&
      Object.keys(onBoardingData).length > 0 && userObject?.token &&
      !showDialog
    ) {
      if (onBoardingData?.isOnboard) {
        navigate("/auth/onboarding/otp-onboarding");
      } else if (onBoardingData?.isOnboard === false) {
        // if (verifyTokenData && Object.keys(verifyTokenData).length > 0) {
        // if (verifyTokenData?.isEligible) {
        navigate("/auth/onboarding/new-user");
        // } else if (verifyTokenData?.isEligible === false) {
        //   navigate("/auth/onboarding/user-notfound");
        // }else return
        // }else return
      } else return;
    } else return;
  }, [onBoardingData, showDialog]);

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 z-40"
        style={{
          backgroundColor: showDialog ? "rgba(0, 0, 0, 0.5)" : "transparent",
        }}
      >
        <div className="w-full h-full flex justify-center items-center">
          <div className="">
            <h1 className="w-full text-xl text-center text-black font-bold uppercase my-2">
              Welcome{" "}
            </h1>
            <p className="w-full text-center text-sm font-semibold text-black">
              P2P ENERGY TRADING PLATFORM
            </p>
            <div className="w-full flex justify-center items-center">
              <Loader />
            </div>
            <p
              className="w-full text-center text-sm font-normal uppercase"
              data-testid="text-proccesing"
            >
              Please wait, do not close or reload page while processing
            </p>
          </div>
        </div>
        {isMobile && showDialog && (
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          />
        )}
        {isMobile && showDialog && (
          <div
            className="bg-gray-800 text-white p-4 rounded-md fixed bottom-0 left-0 right-0 md:mx-auto md:w-max z-50 sm:w-full "
            style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}
          >
            <h2 className="text-lg font-bold text-center mb-2">
              See P2P in...
            </h2>
            <div className="space-y-4">
              <div className="flex justify-between  gap-4 mb-1">
                <div className="flex space-x-2 items-center">
                  <div
                    className="w-14 h-14  rounded-md flex justify-center items-center"
                    style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}
                  >
                    <img src={Logo} alt="P2P App" className="w-10 h-10" />
                  </div>

                  <div className="text-lg font-bold">P2P App</div>
                </div>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold w-24 h-10  rounded-full text-sm"
                  onClick={handleFallback}
                  data-testid="btnOpenAndroid"
                >
                  Open
                </button>
              </div>
              <div className="flex justify-between items-center gap-4 mb-1">
                <div className="flex space-x-2 items-center">
                  <div
                    className="w-14 h-14  rounded-md flex justify-center items-center"
                    style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}
                  >
                    {" "}
                    <Globe className="w-10 h-10" />
                  </div>

                  <div className="text-lg font-bold">Browser</div>
                </div>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold w-24 h-10 rounded-full text-sm"
                  onClick={continueOnWeb}
                >
                  Open
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConsumerBasicDetails;
