import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ForgotLayOut from './Component/ForgotLayOut'
import EnterMobileNo from './Component/EnterMobileNo'
import ForgotOtpScreen from './Component/ForgotOtpScreen'
import ResetPassword from './Component/ResetPassword'


const ForgotRoutePage = () => {
  return (
    //     <>


    //     <Routes >
    //         <Route element={< ForgotLayOut/>}>

    //             <Route path="/*" element={<EnterMobileNo />} />
    //             <Route path="/otp-forgot" element={<ForgotOtpScreen />} />
    //             <Route path="/reset-pasword" element={<ResetPassword />} />
    //         </Route>
    //     </Routes>




    // </>

    <>
      <Routes>
        <Route element={<ForgotLayOut />}>
          <Route path="/" element={<EnterMobileNo />} />
          <Route path="/otp-forgot" element={<ForgotOtpScreen />} />
          <Route path="/otp-forgot/reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </>
  )
}

export default ForgotRoutePage
