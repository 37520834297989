import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { ENUM_STATUS_CODE, stringifyRequestQuery } from "../../helpers";
import { RootState } from "../store";

import { updateError, updateErrorCode } from "./errorHadlingSlicer";
import { getLoadDetailsTradeApi, getMeterDetailsTradeApi, getPlantDetailsTradeApi } from "../../service/services";

interface ProfileProps {
    error: boolean,
    message: string | undefined,
    isLoading: boolean,
    profileStep: number,
    currentUserInfo: any,
    dataChanged: boolean,
    plantDetailData: any,
    loadDetailsData: any,
    loadDetail:any
    meterDetails:any
    plantDetail:any
}

const profileInitialState: ProfileProps = {
    error: false,
    message: undefined,
    isLoading: false,
    profileStep: 1,
    plantDetailData: {},
    loadDetailsData: {},
    currentUserInfo: {
        form_1_loadDetails: {
            monthlyAverage: undefined,
            dailyAverage: undefined,
            load: undefined,
        },
        form_2_MeterDetails: {
            serialNumber: undefined,
            meterNo: undefined,
            manufacturer: undefined,
            model: undefined,
        },
        form_3_PlantDetails: {
            meterNo: undefined,
            type: "solar",

            installedCapacity: undefined,

            moduleType: undefined,
            numberOfPanels: undefined,
            mountingType: undefined,
            azimuth: undefined,
            tiltAngle: undefined,
            inverterManufacturer: undefined,
            numberOfInverters: undefined,
            substation: undefined,
            feeder: undefined,
            transformer: undefined,
            moduleManufacturer: undefined,
            plantCode: undefined

        }
    },

    
    dataChanged: false,

    loadDetail:{
        monthlyAverage: undefined,
        dailyAverage: undefined,
        load: undefined,
    },
    meterDetails:{
        serialNumber: undefined,
        meterNo: undefined,
        manufacturer: undefined,
        model: undefined,
    },
    plantDetail:{
        meterNo: undefined,
        type: "solar",

        installedCapacity: undefined,

        moduleType: undefined,
        numberOfPanels: undefined,
        mountingType: undefined,
        azimuth: undefined,
        tiltAngle: undefined,
        inverterManufacturer: undefined,
        numberOfInverters: undefined,
        substation: undefined,
        feeder: undefined,
        transformer: undefined,
        moduleManufacturer: undefined,
        plantCode: undefined
    }
}
const transformToQueryString = (data: any) => {
    const filterkeys: string[] = [
        "start_date",
        "end_date",
        "purchase_type",
        "user",
        'orderType',
        ' date'
    ];
    const f_keys: string[] = [""];
    const q_keys: string[] = [""];

    let filters: any = {};
    let _f: any = {};
    let _q: any = {};

    data &&
        Object.entries(data).map(([key, value]) => {
            if (filterkeys.length > 0 && filterkeys.includes(key)) {
                filters[key] = value;
            } else if (f_keys.length > 0 && f_keys.includes(key)) {
                _f[key] = value;
            } else if (q_keys.length > 0 && q_keys.includes(key)) {
                _q[key] = value;
            }
        });



    return stringifyRequestQuery({
        filter: filters,
        f: _f,
        q: _q,
        items_per_page: data.items_per_page,
        page: data.page,
    });
};

export const directLoadDetailApi: any = createAsyncThunk(
    'profile/directLoadDetailApi',
    async (data: any, thunkApi: any) => {

        try {
            const queryString = data?.user;

            const response: any = await getLoadDetailsTradeApi(queryString);
//   thunkApi.dispatch(updateErrorCode(response.code))
            if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
                return response?.data;
            }
            else {
                const errorMessage = response.data?.message;
                thunkApi.dispatch(updateErrorCode(response.data?.code))
                // thunkApi.dispatch(updateError(errorMessage))
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {

            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {

                thunkApi.dispatch(setError(error.response?.data.message));
                thunkApi.dispatch(updateErrorCode(error.response?.data.code))
                thunkApi.dispatch(updateError(error.response?.data.message))
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {

                thunkApi.dispatch(updateError(error.message))
            }
            // thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
)

export const directMeterDetailApi: any = createAsyncThunk(
    'profile/directMeterDetailApi',
    async (data: any, thunkApi: any) => {

        try {
            const queryString = data?.user;

            const response: any = await getMeterDetailsTradeApi(queryString);
            // thunkApi.dispatch(updateErrorCode(response.code))
            if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
                return response?.data;
            }
            else {
                const errorMessage = response.data?.message;
                thunkApi.dispatch(updateErrorCode(response.data?.code))
                // thunkApi.dispatch(updateError(errorMessage))
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {

            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {

                thunkApi.dispatch(setError(error.response?.data.message));
                thunkApi.dispatch(updateErrorCode(error.response?.data.code))
                thunkApi.dispatch(updateError(error.response?.data.message))
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {

                thunkApi.dispatch(updateError(error.message))
            }
            // thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
)

export const directPlantDetailApi: any = createAsyncThunk(
    'profile/directPlantDetailApi',
    async (data: any, thunkApi: any) => {

        try {
            const queryString = data?.user;

            const response: any = await getPlantDetailsTradeApi(queryString);
            // thunkApi.dispatch(updateErrorCode(response.code))
            if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
                return response?.data;
            }
            else {
                const errorMessage = response.data?.message;
                thunkApi.dispatch(updateErrorCode(response.data?.code))
                // thunkApi.dispatch(updateError(errorMessage))
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {

            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {

                thunkApi.dispatch(setError(error.response?.data.message));
                thunkApi.dispatch(updateErrorCode(error.response?.data.code))
                thunkApi.dispatch(updateError(error.response?.data.message))
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {

                thunkApi.dispatch(updateError(error.message))
            }
            // thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
)


export const profileSlicer = createSlice({
    name: 'profile/profileSlicer',
    initialState: profileInitialState,
    reducers: {
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        setProfileStep(state, action: PayloadAction<any>) {
            state.profileStep = action.payload
        },

    },
    extraReducers: (builder) => {
        builder.addCase(directPlantDetailApi.fulfilled, (state, action) => {
            // console.log(action?.payload)
            state.plantDetailData = action?.payload;

            state.isLoading = false;
            state.error = false;
            state.message = undefined;
if(action.payload){
            state.plantDetail = {
                    meterNo: action?.payload?.meterNo,
                    type: action?.payload?.type,

                    installedCapacity:action?.payload?.installedCapacity,

                    moduleType: action?.payload?.moduleType,
                    numberOfPanels: action?.payload?.numberOfPanels,
                    mountingType: action?.payload?.mountingType,
                    azimuth: action?.payload?.azimuth,
                    tiltAngle: action?.payload?.tiltAngle,
                    inverterManufacturer: action?.payload?.inverterManufacturer,
                    numberOfInverters: action?.payload?.numberOfInverters,
                    substation: action?.payload?.substation,
                    feeder: action?.payload?.feeder,
                    transformer: action?.payload?.transformer,
                    moduleManufacturer: action?.payload?.moduleManufacturer,
                    plantCode: action?.payload?.plantCode
        
                }
            }
        });
        builder.addCase(directPlantDetailApi.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
            state.plantDetailData = {};
        });
        builder.addCase(directPlantDetailApi.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            state.message = payload || "Error Occured";
        });
        builder.addCase(directMeterDetailApi.fulfilled, (state, action) => {
            console.log(action?.payload)
            // state.loadDetailsData = action?.payload;

            state.isLoading = false;
            state.error = false;
            state.message = undefined;
            if(action?.payload){
                state.meterDetails = 
                    {
                        serialNumber: action?.payload[0]?.serialNumber,
                        meterNo: action?.payload[0]?.meterNo,
                        manufacturer: action?.payload[0]?.manufacturer,
                        model: action?.payload[0]?.model,
                    
    
                };
            }
        });
        builder.addCase(directMeterDetailApi.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
            state.loadDetailsData = {};
        });
        builder.addCase(directMeterDetailApi.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            state.message = payload || "Error Occured";
        });
        // load details
        builder.addCase(directLoadDetailApi.fulfilled, (state, action) => {
            // console.log(action?.payload)
            state.loadDetailsData = action?.payload;
            // console.log(action?.payload)
            state.isLoading = false;
            state.error = false;
            state.message = undefined;

        if(action.payload){
            state.loadDetail = {
             
                    monthlyAverage: action?.payload?.monthlyAverage,
                    dailyAverage: action?.payload?.dailyAverage,
                    load: action?.payload?.load,
                }
            
        }
        })
        builder.addCase(directLoadDetailApi.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
  
        });
        builder.addCase(directLoadDetailApi.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            state.message = payload || "Error Occured";
        });
    }

})


export const { setError, resetError, resetChangedState, setProfileStep,  } = profileSlicer.actions;
export const profileReducerState= (state: RootState) => state.profile;
export default profileSlicer.reducer;