import { useEffect, useState } from 'react';
import { Button } from 'src/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'src/components/ui/dialog';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { NUMBER_PATTERN } from 'src/helpers';
import PaymentSuccessDialog from './PaymentSuccessDialog';
import PaymentFailerDialog from './PaymentFailerDialog';
import { axiosWithToken } from 'src/service/axioseSetup';
import { useDispatch } from 'react-redux';
import { fetchWalletAmountByUserId } from 'src/redux/Slicer/walleteSlicer';
import { useNavigate } from 'react-router-dom';

type Props = {
    showPaymentForm: boolean, setShowPaymentDialog: (e: any) => void
}

interface RazorpayOptions {
    key: string;
    amount: number;
    currency: string;
    name: string;
    description: string;
    order_id: string;
    handler: (response: any) => void;
    prefill?: {
        email?: string;
        contact?: string;
    };
    theme?: {
        color: string;
    };
    modal?: {
        ondismiss?: () => void;
    };
}

interface Razorpay {
    new(options: RazorpayOptions): {
        open: () => void;
        on: (event: string, callback: (response: any) => void) => void;
    };
}
// Extend the Window interface to include Razorpay
declare global {
    interface Window {
        Razorpay: any; // You can refine this type further if you have Razorpay's type definitions
    }
}

const PaymentForm = ({ showPaymentForm, setShowPaymentDialog }: Props) => {
    // components/PaymentForm.jsx



    const [amount, setAmount] = useState<string>(''); // Use string for controlled input
    const [inputError, setinputError] = useState("")
    const [isLoadingScript, setIsLoadingScript] = useState<boolean>(false)
    const [transactionId, setTransactionId] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showFailure, setShowFailure] = useState<boolean>(false);
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
    const [showForm, setshowForm] = useState<boolean>(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Load Razorpay script dynamically
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        script.onerror = () => {
            console.error('Failed to load Razorpay script');
            setErrorMessage('Failed to load payment gateway.');
            setShowFailure(true);
        };
        document.body.appendChild(script);
        setErrorMessage("")
        setinputError("")
        setAmount("")
        // Cleanup script on unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        setErrorMessage("")
        setinputError("")
        setAmount("")
        setshowForm(showPaymentForm)

        return () => { };
    }, [showPaymentForm]);

    const startPayment = async () => {
        setIsLoadingScript(true)
        if (!amount || parseFloat(amount) <= 0.9) {
            setinputError('Please enter a valid amount or greater than 0 RS. ');
            setIsLoadingScript(false)
            return;
        }

        if (!scriptLoaded || !window.Razorpay) {
            setErrorMessage('Payment gateway not loaded. Please try again.');
            setShowFailure(true);
            setIsLoadingScript(false)
            return;
        }

        try {
            // Create order on server
            setinputError("")
            const response = await axiosWithToken
                .post('/transaction/create-order', {
                    amount: parseFloat(amount),// Convert to paise (Razorpay expects amount in smallest unit)
                    notes: ['Sample description'],
                })
                .then((res) => res.data);

            console.log({ response });
            setIsLoadingScript(false)

            if (response.code === 200) {

                const { amount, id: order_id, key: rz_key } = response.data;

                // Initialize Razorpay payment
                const options: RazorpayOptions = {
                    key: rz_key,
                    amount: amount,
                    currency: 'INR',
                    name: 'Secure Payment',
                    description: 'Complete your payment securely',
                    order_id: order_id,
                    theme: { color: '#007bff' },
                    handler: async function (response: any) {
                        try {
                            const verifyRes = await axiosWithToken
                                .post('/transaction/verify-payment', {
                                    razorpay_order_id: order_id,
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    razorpay_signature: response.razorpay_signature,
                                })
                                .then((res) => res.data);

                            if (verifyRes.success) {
                                setTransactionId(response.razorpay_payment_id);
                                setShowSuccess(true);
                                dispatch(fetchWalletAmountByUserId())
                                setShowPaymentDialog(false)
                            } else {
                                setErrorMessage('Payment verification failed.');
                                setShowFailure(true);

                            }
                        } catch (error) {
                            console.error('Verification error:', error);
                            setErrorMessage('Payment verification failed.');
                            setShowFailure(true);
                            setShowPaymentDialog(false)
                        }
                    },
                    prefill: {
                        email: 'customer@example.com',
                        contact: '9876543210',
                    },
                    modal: {
                        ondismiss: function () {
                            console.log('Payment popup closed by user.');
                            setShowPaymentDialog(false)
                        },
                    },
                };
                setshowForm(false)
                const rzp = new window.Razorpay(options);
                rzp.open();
                setShowPaymentDialog(false)
            }
        } catch (error) {
            console.error('Payment error:', error);
            setErrorMessage('An error occurred during payment.');
            setShowFailure(true);
            setShowPaymentDialog(false)
        }
    };

    console.log({ isLoadingScript });


    return (
        <>
            <Dialog open={showForm} onOpenChange={(e) => setShowPaymentDialog(e)}  >
                <DialogContent className="sm:max-w-[425px] p-0 rounded-lg overflow-hidden">
                    <DialogHeader className='bg-primary text-white p-2 h-12'>
                        <DialogTitle className='text-center mt-2'>Enter Payment Details</DialogTitle>
                    </DialogHeader>
                    <div className='flex flex-col items-center  p-4'>
                        <div className='flex flex-col '>
                            <Label htmlFor="amount" className="form-label">Enter Amount (INR):</Label>
                            <Input
                                type="number"
                                id="amount"
                                className="form-control mb-3"
                                placeholder="Enter amount"
                                value={amount}
                                onChange={(e) => {
                                    const val = e.target.value.replace(NUMBER_PATTERN, "")
                                    setAmount(val)
                                    setinputError("")
                                }}
                                required
                            />
                            <p className='text-destructive text-xs'>{inputError || ""}</p>
                        </div>
                        <div className='flex space-x-2'>
                            <Button onClick={() => {
                                startPayment()
                                setAmount("")
                            }} size={'sm'} className='mt-2 bg-primary text-white ' >Continue</Button>
                            <Button onClick={() => {
                                setshowForm(false)
                                setShowPaymentDialog(false)
                                setAmount("")
                            }} size={'sm'} className='mt-2 bg-red-100 text-destructive ' >Cancel</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <PaymentSuccessDialog show={showSuccess} setShow={(e: any) => setShowSuccess(e)} transactionId={transactionId} />
            <PaymentFailerDialog show={showFailure} setShow={(e: any) => setShowFailure(e)} message={errorMessage} />

            <Dialog open={isLoadingScript} onOpenChange={(e) => { }}  >
                <DialogContent hideCoseIcon className="sm:max-w-[325px] p-0 rounded-lg overflow-hidden">
                    <div className='p-4'>
                        <div className='flex justify-center ' >
                            <span>Loading...</span>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}


export default PaymentForm