import { Smartphone } from "lucide-react";
import React, { InputHTMLAttributes, useEffect } from "react";

import { Input } from "../../input";
import { Label } from "../../label";
import { cn } from "../../../../helpers";

interface InputsComp extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClass?: string;
  errorMessage?: string;
  iconName: any;
  regex?: any;
  onchange?: (e: any) => void;
  value?: any;
  placeholder?: string;
  pattern?: string;
}

const LInputComp: React.FC<InputsComp> = ({
  label,
  placeholder,
  labelClass,
  errorMessage,
  regex,
  iconName,
  onchange,
  value,
  
  ...rest
}) => {
  const defaultRegex = /^\d*\.?\d*$/;


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!regex) {
      onchange && onchange(e.target.value);
      return;
    }

    // Use the provided regex or the default one
    const currentRegex = defaultRegex;

    // Validate the input value against the regex
    if (currentRegex.test(e.target.value) || e.target.value === "") {
      onchange && onchange(e.target.value);
    }
  };


  useEffect(() => {
    // Ensure value is always a string before sanitizing
    const sanitizedValue = (value || "").replace(/[a-zA-Z]/g, ""); // Remove alphabets
    if (sanitizedValue !== value&&regex) {
      onchange && onchange(sanitizedValue); // Call onchange if value changes
    }
  }, [value, onchange]); 
 
  return (
    <div className="w-full">
      <div className="w-full ">
        <div className="w-full relative  shadow-sm">
          <Label
            htmlFor={rest.id}
            className={`text-grayLable text-sm font-bold ml-2 ${labelClass}`}
          >
            {label}
          </Label>
          <div
            className="w-full pointer-events-none h-[80%]  absolute flex justify-start items-center px-2"
            data-testid="l-icon"
          >
            {iconName(cn("text-gray-400 font-bold mt-3"))}
          </div>
          <Input
            value={value&&value}
            onChange={handleChange}
            placeholder={placeholder}
            // pattern={pattern}
            className="block w-full border  border-input border-[#575757]  rounded-md py-1.5 pl-7 pr-20 placeholder:text-gray-400 placeholder:text-xs "
            {...rest}
          />
        </div>
        <p
          className="text-sm text-red-600 ml-2 font-medium"
          data-testid={"l-errorMess"}
        >
          {errorMessage}
        </p>
      </div>
    </div>
  );
};

export default LInputComp;
