import React, { useEffect, useRef, useState } from "react";
import Modal from "src/components/ui/common/Dialog/Modal";
import Modal_md from "src/components/ui/common/Dialog/Modal_md";
import UserActivityMessage from "./UserActivityMessage";
import { useAuth } from "src/modules/Auth/Core";

const UserActivityPopUp = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const timeoutThreeMinuteRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutFiveMinuteRef = useRef<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);
  const{logout}=useAuth()
  const resetTimer = () => {
   
    if (timeoutThreeMinuteRef.current) {
      clearTimeout(timeoutThreeMinuteRef.current);
    }

    if (timeoutFiveMinuteRef.current) {
      clearTimeout(timeoutFiveMinuteRef.current);
    }
   
    timeoutThreeMinuteRef.current = setTimeout(() => {
      setIsPopupVisible(true); 
    }, 180000); 

    timeoutFiveMinuteRef.current = setTimeout(() => {
      setIsPopupVisible(false); 
      logout()
    }, 300000);
  };

  useEffect(() => {
  
    const handleActivity = () => {
      setIsPopupVisible(false);
      resetTimer();
    };

    
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("click", handleActivity);

   
    resetTimer();

   
    return () => {
      if (timeoutThreeMinuteRef.current) {
        clearTimeout(timeoutThreeMinuteRef.current);
      }
      if (timeoutFiveMinuteRef.current) {
        clearTimeout(timeoutFiveMinuteRef.current);
      }
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, []);

  useEffect(() => {
    if (isPopupVisible) {
      setOpen(isPopupVisible);
    } else return;
  }, [isPopupVisible]);
  return (
    <>
      <Modal_md open={open} setOpen={setOpen} children={<UserActivityMessage setOpen={setOpen}/>} />
    </>
  );
};

export default UserActivityPopUp;
