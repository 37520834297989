import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useReducer,
  ReactNode,
} from "react";

// import { LayoutSplashScreen } from '../../../Helpers'
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import Cookies from "universal-cookie";

import { useLocation, useNavigate } from "react-router-dom";
import { getUserByToken } from "../../../service/services";
import { ENUM_STATUS_CODE } from "../../../helpers";
// import { getUserByToken } from "../Login/Context/core/_requests";

// import Splashscreen from "../Splashscreen";
interface AuthProviderProps {
  children: ReactNode;
}
type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  // console.log({ auth });

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<AuthProviderProps> = ({ children }) => {
  const { auth, logout, setCurrentUser, currentUser, saveAuth } = useAuth();

  // console.log("Auth Check", currentUser, auth);
  const navigate = useNavigate();
  const didRequest = useRef(false);
  const cookies = new Cookies();
  const { pathname, search } = useLocation();

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    if (auth && !currentUser && auth.api_token) {
      // console.log("cheked")
      requestUser(auth.api_token);
    } else {
      setShowSplashScreen(false);
    }

    return () => {};
    // eslint-disable-next-line
  }, [currentUser, auth?.api_token]);

  const requestUser = async (apiToken: string) => {
    try {
      const data: any = await getUserByToken();

      // console.log( data?.data )

      if (data && data?.code == ENUM_STATUS_CODE?.SUCCESS) {
        setCurrentUser({
          ...currentUser,
          isAuthenticated: data?.data?.isAuthenticated,
          user_id: data?.data?.user,
          email: data?.data?.email,
          phone: data?.data?.phone,
          name:data?.data?.name
        });
        saveAuth({
          ...auth,
          isLoadDetailsAvl: data?.data?.isLoadDetailsAvl,
          isMeterDetailsAvl: data?.data?.isMeterDetailsAvl,
          isPlantDetailsAvl: data?.data?.isPlantDetailsAvl
            ? data?.data?.isPlantDetailsAvl
            : false,
          isVerified: data?.data?.isVerified,
          userCode: data?.data?.usercode,
          endUserID: data?.data?.endUserID,
          isDefault: data?.data?.isDefault,
          consumerNumber: data?.data?.consumerNumber,
          verificationRemark:data?.data?.verificationRemark
        });

        if (cookies.get("kt-last-url")) {
          let lastpath = pathname + search || cookies.get("kt-last-url") || "";
          navigate({ pathname: lastpath });
        }
      } else {
        localStorage.setItem("kt-last-url", pathname + search);
        cookies.set("kt-last-url", pathname + search, { path: "/" });
        logout();
      }
    } catch (error) {
      // console.error(error);
      if (!auth?.api_token) {
        logout();
      }
    } finally {
      setShowSplashScreen(false);
    }
    // return () => (<></>);
  };

  return showSplashScreen ? <></> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
