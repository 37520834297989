const AUTH_LOCAL_STORAGE_KEY = 'x-apiKey'

const getX_ApiKey = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }


  try {
    
    const auth:string = lsValue
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    // console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

export {getX_ApiKey}