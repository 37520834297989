import React, { useEffect, useState } from "react";

import LoginImage from "../../../../assets/images/20824344_6343823 1.png";
import LoginViaPhone from "./LoginViaPhone";
import { cn, ENUM_LOGIN_TAB } from "../../../../helpers";
import LoginViaConsumerNo from "./LoginViaConsumerNo";
import LoginViaEmail from "./LoginViaEmail";
import { useDispatch } from "react-redux";
import { resetOnboardingState } from "src/redux/Slicer/onboardingSlicer";
import BannerImag from "../../../../assets/images/consumerportalbanner-final.png"
const ILoginScreen = () => {
  const [tab, setTab] = useState(ENUM_LOGIN_TAB?.CONSUMERnO);
  const dispatch=useDispatch()
  useEffect(()=>{
    dispatch(resetOnboardingState())
    },[])
  return (
    <div className="w-full p-4 h-[100%] md:rounded-xl  md:border md:border-gray-100 md:bg-white lg:flex lg:flex-row-reverse lg:items-center lg:justify-center ">
      <div className="p-2 w-full md:flex md:justify-center  ">
        <img
          src={LoginImage}
          alt="IMAG"
          className="md:w-3/4  lg:w-3/4 "
          data-testid="tab-login-img"
        />
      </div>
      <div className="w-full md:mt-8 lg:mt-0">
        <div className="w-full ml-20 space-y-5">
          <div
            className="w-full text-xl font-bold text-GlobalColor "
            data-testid="tab-login"
          >
            Login
          </div>
          {/* <div className="w-full flex space-x-4 ">
            <div
              className={cn(
                "text-grayLable text-sm font-normal cursor-pointer hover:underline hover:underline-offset-2",
                {
                  "text-red-500 font-bold underline-red-500 underline underline-offset-4":
                    tab === ENUM_LOGIN_TAB?.EMAIL,
                }
              )}
              onClick={() => setTab(ENUM_LOGIN_TAB?.EMAIL)}
               data-testid="email-tab"
            >
              Email
            </div>
            <div
              className={cn(
                "text-grayLable text-sm font-normal cursor-pointer hover:underline hover:underline-offset-2",
                {
                  "text-red-500 font-bold underline-red-500 underline underline-offset-4":
                    tab === ENUM_LOGIN_TAB?.PHONE,
                }
              )}
              onClick={() => setTab(ENUM_LOGIN_TAB?.PHONE)}
               data-testid="phone-tab"
            >
              Phone
            </div>
            <div
              className={cn(
                "text-grayLable text-sm font-normal cursor-pointer hover:underline hover:underline-offset-2",
                {
                  "text-red-500 font-bold underline-red-500 underline underline-offset-4":
                    tab === ENUM_LOGIN_TAB?.CONSUMERnO,
                }
              )}
              onClick={() => setTab(ENUM_LOGIN_TAB?.CONSUMERnO)}
               data-testid="consumerNo-tab"
            >
              Consumer Number
            </div>
          </div> */}
                      <div
              className={cn(
                "text-grayLable text-sm font-normal cursor-pointer hover:underline hover:underline-offset-2 ",
                {
                  "text-text_primaryDarkBlue font-bold underline-red-500 underline underline-offset-4 ":
                    tab === ENUM_LOGIN_TAB?.CONSUMERnO,
                }
              )}
              onClick={() => setTab(ENUM_LOGIN_TAB?.CONSUMERnO)}
               data-testid="consumerNo-tab"
            >
              Consumer Number
            </div>
        </div>
        <div className="-mt-4">
        {tab === "phone" ? <LoginViaPhone /> : null}
        {tab === "email" ? <LoginViaEmail /> : null}
        {tab === "consumerNo" ? <LoginViaConsumerNo /> : null}
        </div>
      </div>
    </div>
  );
};

export default ILoginScreen;
