import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { QueryState, stringifyRequestQuery } from "../../helpers"
// import { toast } from 'react-toastify';
import { getEventHistoryData, getOrderHistory, getOrderHistoryTrade } from "../../service/services";
import axios, { AxiosError } from "axios";

import { toast } from "sonner";
import { updateError } from "./errorHadlingSlicer";


interface HistoryProps extends QueryState {
    message: string | undefined
    error: boolean,
    dataChanged: boolean,
    isLoading: boolean,
    orderHistraryTradeLoading:boolean
    historyData: any,
    start_date: string | undefined,
    end_date: string | undefined,
    user: string | undefined,
    status: 'all' | 'new' | 'full_trade'
    orderData: any,
    page: number
    items_per_page: 10 | 30 | 50 | 100,
    order_id: string | undefined
    tradeData: any
    tradeOrder_Id: string | undefined,
}

const initialState: HistoryProps = {
    message: undefined,
    error: false,
    dataChanged: false,
    isLoading: false,
    orderHistraryTradeLoading:false,
    historyData: [],
    start_date: undefined,
    end_date: undefined,
    user: undefined,
    page: 1,
    items_per_page: 10,
    status: 'all',
    orderData: [],
    order_id: undefined,
    tradeData: [],
    tradeOrder_Id: undefined
}

const transformToQueryString = (data: any) => {

    const filterkeys: string[] = ["start_date", "end_date", 'status', 'user',];
    const f_keys: string[] = [""]
    const q_keys: string[] = ["user"];

    let filters: any = {};
    let _f: any = {};
    let _q: any = {};

    data && Object.entries(data).map(([key, value]) => {
        if (filterkeys.length > 0 && filterkeys.includes(key)) {
            filters[key] = value
        }
        else if (f_keys.length > 0 && f_keys.includes(key)) {
            _f[key] = value
        }
        else if (q_keys.length > 0 && q_keys.includes(key)) {
            _q[key] = value
        }
    })

    return stringifyRequestQuery({
        filter: filters,
        f: _f,
        q: _q,
        items_per_page: data.items_per_page,
        page: data.page,


    })

}

export const fetchEventHistory: any = createAsyncThunk(
    'dashboard/fetchEventHistory',
    async (data: any, thunkApi: any) => {

        try {
            const getFilterState = thunkApi.getState().history;
            // console.log(getFilterState);

            let newFilterState = { ...getFilterState }

            delete newFilterState["items_per_page"];
            delete newFilterState["page"];

            const queryString = transformToQueryString(getFilterState);
            // console.log({ queryString })
            const response: any = await getOrderHistory(queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                toast.error(errorMessage)
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                // toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                toast.error(error.message,)
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }

)

export const orderHistory: any = createAsyncThunk(
    'history/orderHistory',
    async (data: any, thunkApi: any) => {

        try {
            const getFilterState = thunkApi.getState().history;
            const filterState = { ...getFilterState, ...data }
            // console.log(filterState)
            let newFilterState = {
                ...filterState,

            }

            delete newFilterState["items_per_page"]
            delete newFilterState["page"]
            if (getFilterState.status === 'all') {
                delete newFilterState["status"];
            }
            const queryString = transformToQueryString(newFilterState);

            const response: any = await getOrderHistory(queryString);



            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;

                thunkApi.dispatch(updateError(errorMessage))
              
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {

            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {

                thunkApi.dispatch(setError(error.response?.data.message));
                thunkApi.dispatch(updateError(error.response?.data.message))

                // toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                // toast.error(error.message)
                thunkApi.dispatch(updateError(error.message))

            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }


)

export const orderHistoryTrade: any = createAsyncThunk(
    'history/orderHistoryTrade',
    async (data: any, thunkApi: any) => {

        try {
            const getFilterState = thunkApi.getState().history;
            const getId = getFilterState?.order_id
           const response: any = await getOrderHistoryTrade(getId);



            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;

                thunkApi.dispatch(updateError(errorMessage))
                // toast.error(errorMessage)
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {

            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {

                thunkApi.dispatch(setError(error.response?.data.message));
                thunkApi.dispatch(updateError(error.response?.data.message))

                // toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                // toast.error(error.message)
                thunkApi.dispatch(updateError(error.message))

            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }


)

const historySlicer = createSlice(
    {
        name: 'historySlicer',
        initialState,
        reducers: {
            updateStartDate(state, action: PayloadAction<any>) {
                state.start_date = action.payload
            },

            updateEndDate(state, action: PayloadAction<any>) {
                state.end_date = action.payload
            },
            updateOrderId(state, action: PayloadAction<any>) {
                state.order_id = action.payload
            },
            resetChangedState(state) {
                state.dataChanged = false;
            },
            setError: (state, action: PayloadAction<any>) => {
                state.error = true;
                state.message = action.payload.message;
            },
            resetError: (state) => {
                state.error = false;
                state.message = undefined;
            },
            setFilters: (state, action: PayloadAction<{
                end_date: string,
                start_date: string,
            }>) => {
                state.end_date = action.payload.end_date;
                state.start_date = action.payload.start_date;
            },
            OrderCategory: (state, action: PayloadAction<any>) => {
                state.status = action.payload
            },
            setQKeys: (state, action: PayloadAction<{
                user: string | undefined,
                // code: string | undefined,
                // name: string | undefined,
            }>) => {
                // console.log(action);
                state.user = action.payload.user;
                // state.code = action.payload.code;
                // state.name = action.payload.name;
                // console.log(state?.name);
            },
        },
        extraReducers: (builder) => {
            builder.addCase(fetchEventHistory.fulfilled, (state, { payload }) => {
                state.historyData = payload;
                state.isLoading = false;
                state.error = false;
                state.message = undefined
            });
            builder.addCase(fetchEventHistory.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.message = "";
                state.historyData = []
            });
            builder.addCase(fetchEventHistory.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = true;
                // state.message = payload || 'Error Occured'
            });

            builder.addCase(orderHistory.fulfilled, (state, { payload }) => {
                state.orderData = payload;
                state.isLoading = false;
                state.error = false;
                state.message = undefined;
                // state.items_per_page = payload?.payload?.pagination?.items_per_page;
                // state.page = payload?.payload?.pagination?.page;
                // state.total_records = payload?.payload?.pagination?.total_records;
                // state.total = payload?.payload?.pagination?.total;

            });
            builder.addCase(orderHistory.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.message = "";
                state.orderData = []
            });
            builder.addCase(orderHistory.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = true;
                state.message = payload || 'Error Occured'
            });

            builder.addCase(orderHistoryTrade.fulfilled, (state, { payload }) => {
                state.tradeData = payload?.trades;
                state.tradeOrder_Id = payload?.order?._id

                
                state.orderHistraryTradeLoading = false;
                state.error = false;
                state.message = undefined;
                // state.items_per_page = payload?.payload?.pagination?.items_per_page;
                // state.page = payload?.payload?.pagination?.page;
                // state.total_records = payload?.payload?.pagination?.total_records;
                // state.total = payload?.payload?.pagination?.total;

            });
            builder.addCase(orderHistoryTrade.pending, (state) => {
                state.orderHistraryTradeLoading = true;
                state.error = false;
                state.message = "";
                state.tradeData = []
            });
            builder.addCase(orderHistoryTrade.rejected, (state, { payload }) => {
                state.orderHistraryTradeLoading = false;
                state.error = true;
                state.message = payload || 'Error Occured'
            });
        }

    }
)

export const { setError, resetError, resetChangedState, updateEndDate, updateStartDate, setQKeys, setFilters, OrderCategory, updateOrderId } = historySlicer.actions;
export const historyReducerState = (state: RootState) => state.history;
export default historySlicer.reducer;