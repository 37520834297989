import { ChevronDown, ChevronUp } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/components/ui/accordion'
import ConsumerDetails from './ConsumerDetails'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { fetchUser, onboardingReducerState } from 'src/redux/Slicer/onboardingSlicer'

type Props = {}

const ConsumerListAccordian = (props: Props) => {
    const [accordianVal, setAccordianVal] = useState('')
    const { fetchUserData, phone }: any = useSelector((state: RootState) => onboardingReducerState(state))

    const basicDetails = useMemo(() => fetchUserData?.consumers, [fetchUserData])
    // console.log(basicDetails, "Consumers Details");

    const dispatch = useDispatch()

    useEffect(() => {
        if (phone) {
            dispatch(fetchUser())
        }
        return () => { }
    }, [phone])

    return (
        <div className='w-full'>
            <Accordion type="single" collapsible className="w-full space-y-1 border"
                onValueChange={(val: string) => setAccordianVal(val)} 
                defaultValue='item-1'>
                {basicDetails && basicDetails?.length > 0 && basicDetails.map((data: any, index: number) =>
                    <AccordionItem value={`item-${index}`} key={index} >
                        <AccordionTrigger className='w-full px-2  hover:no-underline border bg-[#F5F5F5]' >
                            <div className='w-full flex justify-between'>
                                <div className='flex space-x-4 items-center'>
                                    <div className='font-bold text-sm text-textLightBlack'>{data?.consumerNo ? data?.consumerNo : "-"}</div>
                                </div>
                                <div>
                                    {
                                        accordianVal === `item-${index}` ? (<div className='w-5 h-5  flex justify-center items-center'><ChevronUp /></div>) : (<div className='w-5 h-5  flex justify-center items-center'><ChevronDown /></div>)
                                    }
                                </div>
                            </div>
                        </AccordionTrigger>
                        <AccordionContent>
                            <ConsumerDetails data={data && data} />
                        </AccordionContent>
                    </AccordionItem>

                )}
            </Accordion>

        </div>
    )
}

export default ConsumerListAccordian