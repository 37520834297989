import React, { useEffect, useMemo } from "react";
import BasicDetails from "./Components/BasicDetails";
import ConsumerListAccordian from "./Components/ConsumerListAccordian";
import { Button } from "src/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { fetchUser, onboardingReducerState } from "src/redux/Slicer/onboardingSlicer";
import Loader from "src/components/ui/common/Loader/Loader";
import NewUserErrorHanding from "./Components/NewUserErrorHanding";

type Props = {};

const NewUserMain = (props: Props) => {
  const navigate = useNavigate();
const dispatch=useDispatch()
  const { fetchUserData,fetchuserLoading,verifyTokenData }: any =
    useSelector((state: RootState) => onboardingReducerState(state));

  const basicDetails = useMemo(() => fetchUserData, [fetchUserData]);

  // console.log(basicDetails);
  useEffect(() => {
    if (verifyTokenData&&Object.keys(verifyTokenData)?.length>0) {
        dispatch(fetchUser({consumerNo:verifyTokenData?.consumerNo}))
    }else return
    return () => { }
}, [verifyTokenData])
  return (
    <>
      {fetchuserLoading ? (
        <div className="h-screen flex justify-center items-center">
          <Loader />
        </div>
      ) : 
      fetchUserData && Object.keys(fetchUserData)?.length > 0 
      ? (
        <div className="p-6 font-Montserrat">
          <div className="mb-10">
            <div className="text-gray-500 font-bold">User Onboarding</div>
            <div className="text-gray-600 text-xs">
              Peer To Peer Energy Trading Application
            </div>
          </div>

          <div className="p-2">
            <BasicDetails />
          </div>
          <div className="mb-4">
            <div className="mb-2">
              <div className="font-semibold text-gray-400 text-sm ">
                Consumer Lists
              </div>
              <div className="h-1  inset-x-0 bg-green-400 w-1/4"></div>
            </div>
            <ConsumerListAccordian />
          </div>

          <div className="flex flex-row-reverse">
            <Button
              className="text-sm  w-[200px] bg-primaryDarkBlue hover:bg-primaryDarkBlue text-white"
              disabled={!basicDetails || Object.keys(basicDetails).length === 0}
              onClick={() => navigate("/auth/onboarding/create-password")}
            >
              Next
            </Button>
          </div>
        </div>
      ) : (
        <div className="h-screen flex justify-center items-center">
          <NewUserErrorHanding />
        </div>
      )}
    </>
  );
};

export default NewUserMain;
