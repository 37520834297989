import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card } from "src/components/ui/card";
import {
  fetchWalletHistory,
  getWalletReducerState,
} from "src/redux/Slicer/walleteSlicer";
import { RootState } from "src/redux/store";
import { columns } from "./Tabel/components/columns";
import { DataTable } from "./Tabel/components/data-table";
import { DataTablePaginationServer } from "./Tabel/components/data-table-pagination_server";

const WalletHistoryMain = () => {
  const dispatch = useDispatch();
  const { walletHistory, loading } = useSelector((state: RootState) =>
    getWalletReducerState(state)
  );
  const navigate = useNavigate();
  const currentUserConsumerType = JSON.parse(
    localStorage.getItem("accessToken") as any
  )?.consumerType;

  if (currentUserConsumerType != "prosumer") {
    navigate("/");
  }

  useEffect(() => {
    dispatch(fetchWalletHistory());
    return () => {};
  }, []);

  return (
    <Card className="w-full h-full flex flex-col  p-2 space-y-4 overflow-y-scroll">
      <div>Wallet History</div>
      <div className="w-full flex flex-col flex-1 relative space-y-2">
        <div className="w-full flex-1 ">
        <DataTable
          isLoading={loading}
          columns={columns}
          data={walletHistory?.data || []}
          pagination={walletHistory?.payload?.pagination}
        />
        </div>
        <div className="w-full">
        <DataTablePaginationServer
          pagination={
            walletHistory?.payload.pagination || { page: 1, items_per_page: 10 }
          }
          onChangeItemPerPage={(e: any) => {
            dispatch(fetchWalletHistory(`page=${1}&items_per_page=${e}`));
          }}
          onChangePage={(e: any) => {
            dispatch(
              fetchWalletHistory(
                `page=${e}&items_per_page=${walletHistory?.payload.pagination.items_per_page}`
              )
            );
          }}
        />
        </div>

      </div>
    </Card>
  );
};

export default WalletHistoryMain;
