import axios, { AxiosError } from "axios";
import { Bell } from "lucide-react";
import millify from "millify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ENUM_STATUS_CODE,
  notificationDateKeys,
  stringifyObjectToRequestQuery,
  stringifyRequestQuery,
} from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import { data } from "src/modules/Portfolio/ProsumerTrading/Component/Table/data";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Slicer/errorHadlingSlicer";
import {
  notificationCountApi,
  notificationState,
} from "src/redux/Slicer/notificationSlicer";
import { RootState } from "src/redux/store";
import { getNotificationServicesCount } from "src/service/services";

const NotificationBell = () => {
  const [notificationDate, setNotificationDate] = useState("");
  // const [count, setCount] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const { pathname } = useLocation();
  const date = localStorage.getItem(notificationDateKeys);

  const { count } = useSelector((state: RootState) => notificationState(state));
  useEffect(() => {
    if (date) {
      setNotificationDate(date);
    } else return;
  }, [pathname, date]);

  // const henadleNotificationCount = async (postData: any) => {
  //   setLoading(true);

  //   try {
  //     let apiResp: any = await getNotificationServicesCount(postData);
  //     dispatch(updateErrorCode(apiResp.code));
  //     if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {

  //       setCount(apiResp?.count);
  //     } else {
  //       // dispatch(updateError(apiResp.message));
  //       dispatch(updateErrorCode(apiResp.code));
  //       setCount(0);
  //     }
  //     return apiResp.data;
  //   } catch (err) {
  //     let error = err as Error | AxiosError;
  //     if (axios.isAxiosError(error)) {
  //       // dispatch(updateError(error.response?.data.message));
  //       dispatch(updateErrorCode(error.response?.data.code));
  //       setCount(0);
  //     } else {
  //       dispatch(updateError(error.message));
  //       setCount(0);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  useEffect(() => {
    const a = {
      userId: auth?.user_id,
      startDate: notificationDate,
    };
    const queryString = stringifyObjectToRequestQuery(a);
    if (notificationDate && pathname!=='/profile/notifications') {
      // henadleNotificationCount(queryString);
      dispatch(notificationCountApi(a));
    } else return;
  }, [notificationDate, pathname]);

  const navigate = useNavigate();

  return (
    <div className="relative cursor-pointer "  onClick={() => navigate("/profile/notifications")}>
      <Bell
        className="w-6 h-6 text-text_primaryDarkBlue "
       
      />
      {count ? (
        <div className="w-5 h-5 bg-primaryDarkBlue rounded-full text-white text-[10px] flex justify-center items-center absolute -mt-8 ml-3">
          {millify(count)}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotificationBell;
