import React, { useState } from "react";
import { Smartphone, Lock, LockIcon, EyeOff, Eye } from "lucide-react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import axios, { AxiosError } from "axios";
// import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";

import {
  updateLogin,
  updateLoginData,
} from "../../../../redux/Slicer/loginSlicer";
import { loginApi } from "../../../../service/services";

import Buttons from "../../../../components/ui/common/Button/Buttons";
import {
  LoginViaPhoneInitialVal,
  LoginViaPhoneProps,
  validationSchemaLoginViaPhone,
} from "./modules";

import LInputComp from "../../../../components/ui/common/InputComp/LInputComp";
import { ENUM_STATUS_CODE } from "../../../../helpers";
import {
  updateError,
  updateErrorCode,
} from "../../../../redux/Slicer/errorHadlingSlicer";

const LoginViaPhone = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { toast } = useToast()
  const formik = useFormik<LoginViaPhoneProps>({
    initialValues: LoginViaPhoneInitialVal,
    onSubmit: async (values, { resetForm, setValues }) => {
      // console.log('Form Values:', values);

      const postData = {
        phone: values.phone,
        password: values.password,
        type: "phone",
        consumerNo: null,
        email: null,
      };
      if (postData) {
        // dispatch(updatePhoneNumber(values.phone))
        dispatch(updateLogin(postData));
        henadleSendOtpApi(postData);
      }
    },

    validationSchema: validationSchemaLoginViaPhone,
  });

  const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const henadleSendOtpApi = async (postData: any) => {
    setLoading(true);
    try {
      let apiResp = await loginApi(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        navigate("login/otp");
        dispatch(updateLoginData(apiResp?.data));

        formik.resetForm();
        formik.setValues(LoginViaPhoneInitialVal);
      } else {
        dispatch(updateError(apiResp.message));
        dispatch(updateErrorCode(apiResp.code));
        // toast.error(apiResp.message)
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateError(error.response?.data.message));
        dispatch(updateErrorCode(error.response?.data.code));
        // toast.error(error.response?.data.message)
      } else {
        dispatch(updateError(error.message));
        // dispatch(updateErrorCode(error.response?.data.code))
        // toast.error(error.message)
      }
    } finally {
      setLoading(false); // Set loading state to false when request completes (whether success or failure)
    }
  };

  return (
    <div className="w-full p-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full  md:flex md:flex-col md:items-center">
          <div className="w-full md:w-3/4 ">
            <LInputComp
              iconName={(className: string) => (
                <Smartphone className="lg:w-5 lg:h-5  w-6 h-6 md:mr-2 -mt-3" />
              )}
              // label='Enter Phone Number'
              placeholder="Enter Phone Number"
              id="phone"
              type="text"
              maxLength={10}
              regex={new RegExp("/[^0-9]+/g")}
              errorMessage={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : ""
              }
              value={formik.values.phone}
              onchange={(e: any) => formik.setFieldValue("phone", String(e))}
            />
          </div>
          <div className="w-full md:w-3/4 ">
            <div className=" w-full relative mt-2  shadow-sm ">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2">
                <LockIcon className="lg:w-6 lg:h-6  w-6 h-6 md:mr-2 p-1" />
              </div>
              <Input
                className="block w-full border-input border-grayLable   py-1.5 pl-8 pr-20 placeholder:text-gray-400 placeholder:text-xs md:placeholder:text-sm lg:placeholder:text-sm "
                placeholder="**********"
                type={showPassword ? "text" : "password"}
                id="password"
                data-qa="passwords"
                // value={formik.values.password}
                // onChange={(e:any)=> formik.setFieldValue("password", e.target.value)}
                {...formik.getFieldProps("password")}
              />
              <span className="cursor-pointer absolute inset-y-0 right-2 flex items-center">
                {showPassword ? (
                  <EyeOff size={16} onClick={togglePasswordVisibility} />
                ) : (
                  <Eye size={16} onClick={togglePasswordVisibility} />
                )}
              </span>
            </div>
            <p
              className="text-sm text-red-600 ml-2 font-medium"
              data-testid="passw-login"
            >
              {" "}
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""}
            </p>
          </div>

          <div className="w-full md:w-3/4 flex justify-between items-center">
            <div className="w-full md:w-3/4">
              <div className="flex  items-center mt-3 mb-2  ">
                <Checkbox
                  id="checks"
                  {...formik.getFieldProps("checkvalue")}
                  checked={formik.values.checkvalue === "yes"}
                  onCheckedChange={() =>
                    formik.setFieldValue("checkvalue", "yes")
                  }
                  className=" border border-input border-grayLable"
                  data-qa="checkbox"
                />
                <Label
                  htmlFor="check"
                  className="text-xs md:text-sm text-[#212529] font-normal ml-2 lg:text-sm"
                >
                  Remember Me
                </Label>
              </div>
              <p className="text-xs  text-red-500 ml-4 md:ml-10  ">
                {formik.touched.checkvalue && formik.errors.checkvalue
                  ? formik.errors.checkvalue
                  : ""}{" "}
              </p>
            </div>

            <Link
              className="text-red-500 text-sm font-normal whitespace-nowrap hover:underline cursor-pointer hover:underline-offset-4 px-2"
              to={"/auth/forgot-password"}
            >
              Forgot Password?
            </Link>
          </div>
          <div className=" w-full md:w-3/4">
            <Buttons
              btnName="Login"
              data-testid="loginbtn"
              type="submit"
              // disabled={!formik.isValid || formik.isSubmitting}
              id="loginbtn"
              loading={loading}
            />
          </div>

          <p className="md:w-3/4 mt-4 text-center text-zinc-600 text-xs md:text-sm font-medium lg:text-sm ">
            Don't have account?{" "}
            <span className="font-bold text-red-500">
              <Link to="/auth/register">Sign Up</Link>
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default LoginViaPhone;
