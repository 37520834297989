import { Route, Routes } from 'react-router-dom'
import ILoginScreen from '../../modules/Auth/Login/Components/ILoginScreen'
import React from 'react'
import LoginOtp from '../../modules/Auth/Login/Components/LoginOtp'
import LoginLayout from './Component/LoginLayout'
import NewLoginPage from 'src/modules/Auth/Login/NewLoginPage'
import NewOtpPage from 'src/modules/Auth/Login/NewOtpPage'

const LoginsRoutes = () => {
    return (
        <>


            <Routes >
                <Route element={<LoginLayout />}>
                    <Route path="/" element={<NewLoginPage />} />
                    <Route path="/otp" element={<NewOtpPage />} />
                </Route>
            </Routes>




        </>
    )
}

export default LoginsRoutes
