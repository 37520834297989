import axios, { AxiosError, AxiosResponse } from "axios";
import {
  axiosWithOnboardingAuth,
  axiosWithToken,
  axiosWithTokenAuth,
} from "./axioseSetup";
import {
  BILL_API_URL,
  CANCEL_ORDER,
  CHECK_ELIGIBILITY,
  CHECK_ON_BOARDING,
  CONTACT_US_PAGE_GET,
  CONTACT_US_PAGE_UPDATE,
  CROUSAL_URL,
  DAILY_CONSUMPTION_BAR_CHART,
  DAILY_TRANSACTION_INVOICE_URL,
  DIRECT_TRADE_ORDER_LIST_URL,
  DIRECT_TRADE_ORDER_REQUEST,
  DROP_DOWN_CONFIG_API,
  DROP_DOWN_TIMEBLOCK_API,
  EVENT_HISTORY_DATA,
  FAQ_PAGE_GET,
  FETCH_USER_DETAILS,
  GET_CONSUMER_LIST,
  HALF_HOURLY_ENERGY_IMPORT_EXPORT,
  LIMIT_FOR_MODIFY_AND_CANCEL_ORDER,
  LOAD_DETAILS_TRADE_API,
  LOGIN_WITHOUT_PASSWORD,
  METER_DETAILS_TRADE_API,
  MODIFY_ORDER,
  NEW_BILL_API_URL,
  NEW_DETAIL_API,
  NEW_OTS_LINE_CHART,
  NEW_PROFILE_API,
  NEW_TOP_PROSUMER_CONSUMER_URL,
  NEW_URL_SUMMARY_DASHBOARD_API,
  NOTIFICATION_COUNT,
  NOTIFICATION_SERVICES_URL,
  ORDER_HISTORY_TRADE_BLOCKCHAIN_URL,
  ORDER_HISTORY_TRADE_URL,
  ORDER_HISTORY_URL,
  PLANT_DETAILS_TRADE_API,
  PORTFOLIO_CONSUMPTION_GENRATION,
  PORTFOLIO_OTS,
  REGISTER_USER,
  TESTING_VERIFICATION_API,
  TRADE_CONFIRMATION_PROSUMER_LIST_URL,
  TRADE_ORDER_REQUEST,
  TRADE_PROSUMER_LIST_URL,
  TRANSACTION_INVOICE_URL,
  TWO_HOURLY_ENERGY_IMPORT_EXPORT,
  UPDATE_CONSUMER_LIST,
  URL_ASK_API,
  URL_BID_API,
  URL_CALENDER_DATA,
  URL_CHANGE_PASSWORD,
  URL_CONSUMER_LIST,
  URL_EDIT_PROFILE,
  URL_FORGOT_PASSWORD,
  URL_LOGIN_CONSUMER_NUMBER_FORGOT_PASSWORD,
  URL_LOGIN_OTP,
  URL_LOGIN_OTP_VALIDATE,
  URL_LOGIN_PHONE_NUMBER_FORGOT_PASSWORD,
  URL_LOGIN_USERNAME_PASSWORD,
  URL_MARKET_DEPTH,
  URL_NEW_RESEND_OTP,
  URL_NEXT_TRADES,
  URL_OTS_GRAPH,
  URL_PROSUMER_LIST,
  URL_STEP_1_LOAD_DETAILS,
  URL_STEP_2_METERS_DETAILS,
  URL_STEP_3_PLANT_DETAILS,
  URL_SUMMARY_DASHBOARD_API,
  URL_USER_REGESTRATION,
  URL_VERIFY_TOKEN,
} from "./apis";
import { PostDataEregestration_1 } from "../../src/modules/Auth/ERegistration/Context/ERegistrationComponent/EregistrationForm_1";
import { getAuth } from "../../src/modules/Auth/Core";
import { toast } from "sonner";
import { convertToCamelCase } from "src/helpers";

// Auth Api function

export const regestrationApi = (postData: PostDataEregestration_1) => {
  return axios
    .post(`${URL_USER_REGESTRATION}`, postData)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const loginApi = (postData: any) => {
  return axios
    .post(`${URL_LOGIN_OTP}`, postData)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const loginOtpValidateApi = (postData: any) => {
  return axios
    .post(`${URL_LOGIN_OTP_VALIDATE}`, postData)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const loginUsernamePasswordApi = (postData: any) => {
  return axios
    .post(`${URL_LOGIN_USERNAME_PASSWORD}`, postData)
    .then((d: AxiosResponse<any>) => d?.data);
};

export function getUserByToken(): Promise<AxiosResponse> {
  const auth = getAuth();
  return axiosWithToken
    .post(
      URL_VERIFY_TOKEN
      //     {
      //     headers : {
      //         'Authorization': `Bearer ${auth?.api_token}`
      //     }
      // }
    )
    .then((d: AxiosResponse) => d.data);
}

export const resendOtp = (postData: any) => {
  return axios
    .post(`${URL_NEW_RESEND_OTP}`, postData)
    .then((d: AxiosResponse<any>) => d?.data);
};

// Forgot Password

export const loginViaPhoneForgotPassword = (postData: any) => {
  return axios
    .post(`${URL_LOGIN_PHONE_NUMBER_FORGOT_PASSWORD}`, postData)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const loginViaConsumerNumberForgotPassword = (postData: any) => {
  return axios
    .post(`${URL_LOGIN_CONSUMER_NUMBER_FORGOT_PASSWORD}`, postData)
    .then((d: AxiosResponse<any>) => d?.data);
};

export function forgotPasswordApi(postData: string): Promise<AxiosResponse> {
  // console.log(URL_FORGOT_PASSWORD)
  return axiosWithTokenAuth
    .post(`${URL_FORGOT_PASSWORD}`, postData)
    .then((d: AxiosResponse) => d.data);
}

// stepwise Regestration Api

export function getLoadDetails(postData: string): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${URL_STEP_1_LOAD_DETAILS}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getMeterDetails(postData: string): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${URL_STEP_2_METERS_DETAILS}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getPlantDetails(postData: string): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${URL_STEP_3_PLANT_DETAILS}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// dashboard Api

export function getCalenderData(
  id: string,
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_CALENDER_DATA(id)}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getProsumerList(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_PROSUMER_LIST}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getConsumerList(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_CONSUMER_LIST}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function submitBidApi(postData: string): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${URL_BID_API}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function submitAskApi(postData: string): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${URL_ASK_API}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getSummaryDashboardApi(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_SUMMARY_DASHBOARD_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getCrosualApi(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${CROUSAL_URL}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getNewSummaryApi(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NEW_URL_SUMMARY_DASHBOARD_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getToPProsumerConsumerList(
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NEW_TOP_PROSUMER_CONSUMER_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}
export function getNewOtsLineChart(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NEW_OTS_LINE_CHART}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}
// Histoty Function

export function getEventHistoryData(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${EVENT_HISTORY_DATA}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getBlockchainTradeApi(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${ORDER_HISTORY_TRADE_BLOCKCHAIN_URL(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// trade api
export function getTradeProsumerListData(
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${TRADE_PROSUMER_LIST_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTradeProsumerTradeConfirmation(
  postData: any
): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${TRADE_CONFIRMATION_PROSUMER_LIST_URL}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTradeProsumerTradeOrderRequest(
  postData: any
): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${TRADE_ORDER_REQUEST}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

//DIRECT TRADE API FUNCTION

// export function getDirectTradeOrderList(query: any): Promise<AxiosResponse> {
//   return axiosWithToken
//     .get(`${DIRECT_TRADE_ORDER_LIST_URL}?${query}`)
//     .then((d: AxiosResponse) => d.data)
//     .catch((d: AxiosError) => d.response?.data);
// }

export function getDirectTradeOrderList(query: any): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${DIRECT_TRADE_ORDER_LIST_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}
export function getLoadDetailsTradeApi(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${LOAD_DETAILS_TRADE_API(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getMeterDetailsTradeApi(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${METER_DETAILS_TRADE_API(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getPlantDetailsTradeApi(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${PLANT_DETAILS_TRADE_API(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTradeDirectTradeOrderRequest(
  postData: any
): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${DIRECT_TRADE_ORDER_REQUEST}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getNewAllDetailsTradeApi(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NEW_DETAIL_API(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// dropdown list
export function getDropdownConfigtData(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${DROP_DOWN_CONFIG_API}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTimeBlockDropDownApi(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${DROP_DOWN_TIMEBLOCK_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getOTSGraphDetails(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_OTS_GRAPH}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// export function getNextTrades(): Promise<AxiosResponse> {
//   return axiosWithToken
//     .get(`${URL_NEXT_TRADES}`)
//     .then((d: AxiosResponse) => d.data);
// }

export function getNextTrades(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_NEXT_TRADES}?${query}`)
    .then((d: AxiosResponse) => d.data);
}
export function getMarketDepth(
  postData: any,
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${URL_MARKET_DEPTH}?${query}`, postData)
    .then((d: AxiosResponse) => d.data);
}
// order history
export function getOrderHistory(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${ORDER_HISTORY_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getOrderHistoryTrade(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${ORDER_HISTORY_TRADE_URL(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function putCancelOrder(
  id: string,
  postData: any
): Promise<AxiosResponse> {
  return axiosWithToken
    .put(`${CANCEL_ORDER(id)}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function putModifyOrder(
  id: string,
  postData: any
): Promise<AxiosResponse> {
  return axiosWithToken
    .put(`${MODIFY_ORDER(id)}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getLimitForModifyCancelOrder(
  postData: any
): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${LIMIT_FOR_MODIFY_AND_CANCEL_ORDER}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// BILL DETAILS

export function getBillDetails(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${BILL_API_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getDailyTransactionInvoice(
  query?: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${DAILY_TRANSACTION_INVOICE_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTransactionInvoice(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${TRANSACTION_INVOICE_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getNewBillDetails(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NEW_BILL_API_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// PROFILE SCREEN
export function changePassword(postData: any): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${URL_CHANGE_PASSWORD}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getUserDetails(id: any): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_EDIT_PROFILE(id)}`)
    .then((d: AxiosResponse) => d.data?.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getNewProfileDetails(id: any): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NEW_PROFILE_API(id)}`)
    .then((d: AxiosResponse) => d.data?.data)
    .catch((d: AxiosError) => d.response?.data);
}
// Testing Verification APi function
export function getTestingVerificationApi(
  query?: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${TESTING_VERIFICATION_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// contact us and faq page

export function getContactUs(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${CONTACT_US_PAGE_GET}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getFaqPage(): Promise<AxiosResponse> {
  return axios
    .get(`${FAQ_PAGE_GET}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function postContactUsPage(postData: any): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${CONTACT_US_PAGE_UPDATE}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// New OnBoarding Flow
export function getOnBoardingStatus(query?: string): Promise<AxiosResponse> {
  return axiosWithOnboardingAuth
    .get(`${CHECK_ON_BOARDING}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function checkUserEligibilty(query?: string): Promise<AxiosResponse> {
  return axios
    .get(`${CHECK_ELIGIBILITY}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function fetchUserDetails(query?: string): Promise<AxiosResponse> {
  return axiosWithOnboardingAuth
    .get(`${FETCH_USER_DETAILS}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function userRegistration(postData: any): Promise<AxiosResponse> {
  return axiosWithOnboardingAuth
    .post(`${REGISTER_USER}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function loginWithoutOtp(postData: any): Promise<AxiosResponse> {
  return axiosWithOnboardingAuth
    .post(`${LOGIN_WITHOUT_PASSWORD}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// consumer list

export function getUserListApi(query: string): Promise<AxiosResponse> {
  return axiosWithOnboardingAuth
    .get(`${GET_CONSUMER_LIST(query)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function updateConsumerUser(id: string): Promise<AxiosResponse> {
  // console.log(UPDATE_CONSUMER_LIST)
  return axiosWithOnboardingAuth
    .put(`${UPDATE_CONSUMER_LIST(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// portfolio API
export function getPortFolioOts(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${PORTFOLIO_OTS}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getDailyConsumptionGraphData(postData: any): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${DAILY_CONSUMPTION_BAR_CHART}`,postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function halfHourlyImportExportGraph(postData: any): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${HALF_HOURLY_ENERGY_IMPORT_EXPORT}`,postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function twoHourlyImportExportGraph(postData: any): Promise<AxiosResponse> {
  return axiosWithToken
    .post(`${TWO_HOURLY_ENERGY_IMPORT_EXPORT}`,postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getPortFolioConsumptionGenration(
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${PORTFOLIO_CONSUMPTION_GENRATION}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// Notification servicess
export function getNotificationServicesUrl(
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NOTIFICATION_SERVICES_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getNotificationServicesCount(
  query: string
): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${NOTIFICATION_COUNT}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// report download

export const downloadUserReport = async (url: string,setLoading:(val:boolean)=>void) => {
  setLoading(true)
  try {
    const response = await fetch(url);

    if (response.ok) {
      // throw new Error("Report not available.");

      const blob = await response.blob();

      const downloadUrl = response?.url;

      return window.open(downloadUrl, "_blank");
      // const a = document.createElement("a");
      // a.href = downloadUrl;
      // a.download = "user-report.pdf";
      // document.body.appendChild(a);
      // a.click();
      // a.remove()
    } else {
      toast.error("Report not available or failed to download.");
    }
  } catch (error) {
    toast.error("Report not available or failed to download.");
  }finally{
    setLoading(false)
  }
};
