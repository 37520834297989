import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { stringifyRequestQuery } from "src/helpers";
import { getNotificationServicesCount } from "src/service/services";
import { updateError, updateErrorCode } from "./errorHadlingSlicer";
import { RootState } from "../store";

interface NotificationProps {
  error: boolean;
  message: string | undefined;
  isLoading: boolean;
  dataChanged: boolean;
  startDate: string | undefined;
  count: number;
}

const intialValue: NotificationProps = {
  error: false,
  message: undefined,
  isLoading: false,
  dataChanged: false,
  count: 0,
  startDate: "",
};

const transformToQueryString = (data: any) => {
  const filterkeys: string[] = ["phone", "email", "consumerNo", "token","userId","startDate"];
  const f_keys: string[] = [""];
  const q_keys: string[] = [""];

  let filters: any = {};
  let _f: any = {};
  let _q: any = {};

  data &&
    Object.entries(data).map(([key, value]) => {
      if (filterkeys.length > 0 && filterkeys.includes(key)) {
        filters[key] = value;
      } else if (f_keys.length > 0 && f_keys.includes(key)) {
        _f[key] = value;
      } else if (q_keys.length > 0 && q_keys.includes(key)) {
        _q[key] = value;
      }
    });

  return stringifyRequestQuery({
    filter: filters,
    f: _f,
    q: _q,
    items_per_page: data.items_per_page,
    page: data.page,
  });
};

export const notificationCountApi: any = createAsyncThunk(
  "notification/notificationCountApi",
  async (data: any, thunkApi: any) => {
    try {
      let newFilterState = { ...data };

      const queryString = transformToQueryString(newFilterState);

      if (queryString !== undefined) {
        const response: any = await getNotificationServicesCount(queryString);

        if (response?.code === 200) {
       
          return response;
        } else {
          const errorMessage = response.data?.message;

          thunkApi.dispatch(updateErrorCode(response.data.code));

          return thunkApi.rejectWithValue(errorMessage);
        }
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
      }

      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const notificationSlicer = createSlice({
  name: "notification/notificationSlicer",
  initialState: intialValue,
  reducers: {
    resetChangedState(state) {
      state.dataChanged = false;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload.message;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },

    resetNotificationState: (state) => {
      return {
        ...state,
        ...intialValue,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(notificationCountApi.fulfilled, (state, action) => {
      state.count = action?.payload?.count && action?.payload?.count;
      state.isLoading = false;
      state.error = false;
      state.message = undefined;
     
    });
    builder.addCase(notificationCountApi.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.count = state.count;
    });
    builder.addCase(notificationCountApi.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.message = payload || "Error Occured";
      state.count = 0;
    });
  },
});

export const {
  setError,
  resetError,
  resetChangedState,
  resetNotificationState,
} = notificationSlicer.actions;

export const notificationState = (state: RootState) => state.notification;
export default notificationSlicer.reducer;
