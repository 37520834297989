import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios, { AxiosError } from "axios";

import { stringifyRequestQuery } from "../../helpers";
import { getBillDetails } from "../../service/services";
import { RootState } from "../store";

import { updateError } from "./errorHadlingSlicer";


interface HistoryProps {
    message: string | undefined
    error: boolean,
    dataChanged: boolean,
    isLoading: boolean,
    billDetailsData: any,
    billHistoryData: any,
}
// 
const initialState: HistoryProps = {
    message: undefined,
    error: false,
    dataChanged: false,
    isLoading: false,
    billHistoryData: [],
    billDetailsData: [],
}

const transformToQueryString = (data: any) => {

    const filterkeys: string[] = ["start_date", "end_date", 'bym', 'user'];
    const f_keys: string[] = [""]
    const q_keys: string[] = ["user"];

    let filters: any = {};
    let _f: any = {};
    let _q: any = {};

    data && Object.entries(data).map(([key, value]) => {
        if (filterkeys.length > 0 && filterkeys.includes(key)) {
            filters[key] = value
        }
        else if (f_keys.length > 0 && f_keys.includes(key)) {
            _f[key] = value
        }
        else if (q_keys.length > 0 && q_keys.includes(key)) {
            _q[key] = value
        }
    })

    // console.log({ filters, _f, _q });


    return stringifyRequestQuery({
        filter: filters,
        f: _f,
        q: _q,
        items_per_page: data.items_per_page,
        page: data.page
    })

}

export const billDetailsApi: any = createAsyncThunk(
    'bill/billDetailsApi',
    async (data: any, thunkApi: any) => {
        try {
            const getFilterState = thunkApi.getState().bill;


            let newFilterState = {
                ...data,
            }

            const queryString = transformToQueryString(newFilterState);

            const response: any = await getBillDetails(queryString);



            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                // toast.error(errorMessage)
                thunkApi.dispatch(updateError(errorMessage));
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                // toast.error(error.response?.data.message)
                thunkApi.dispatch(updateError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                // toast.error(error.message)
                thunkApi.dispatch(updateError(error.message));
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
)

export const billHistorysApi: any = createAsyncThunk(
    'bill/billHistorysApi',
    async (data: any, thunkApi: any) => {
        try {
            const getFilterState = thunkApi.getState().bill;


            let newFilterState = {
                ...data,
            }

            const queryString = transformToQueryString(newFilterState);

            const response: any = await getBillDetails(queryString);



            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                // toast.error(errorMessage)
                thunkApi.dispatch(updateError(errorMessage));
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                // toast.error(error.response?.data.message)
                thunkApi.dispatch(updateError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                // toast.error(error.message)
                thunkApi.dispatch(updateError(error.message));
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
)

export const billSlicer = createSlice({
    name: 'billSlicer',
    initialState,
    reducers: {
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
    }, extraReducers: (builder) => {
        builder.addCase(billDetailsApi.fulfilled, (state, action) => {

            state.billDetailsData = action?.payload;
            state.isLoading = false;
            state.error = false;
            state.message = undefined;


        });
        builder.addCase(billDetailsApi.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
            state.billDetailsData = [];
        });
        builder.addCase(billDetailsApi.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            // state.message = payload?.message || 'Error Occured'
        });

        builder.addCase(billHistorysApi.fulfilled, (state, action) => {

            state.billHistoryData = action?.payload;
            state.isLoading = false;
            state.error = false;
            state.message = undefined;


        });
        builder.addCase(billHistorysApi.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
            state.billHistoryData = [];
        });
        builder.addCase(billHistorysApi.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            // state.message = payload?.message || 'Error Occured'
        });

    }
})

export const { setError, resetError, resetChangedState, } = billSlicer.actions;
export const billReducerState = (state: RootState) => state.bill;
export default billSlicer.reducer;