import { ChevronLeft } from "lucide-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "src/components/ui/card";
import { P2P_LANDING_PAGE } from "src/service/apis";

const NewUserErrorHanding = () => {
    const navigate=useNavigate()
  return (
    <div className="w-full flex justify-center items-center font-Roboto">
      <Card className="w-1/3 h-[30%] p-4 space-y-3">
        <p className="text-light_red text-sm text-center font-semibold">
          Consumer Number Not Found
        </p>
        <div
        //   to={`${P2P_LANDING_PAGE}`}
          className="space-x-2 flex justify-center items-center hover:underline hover:underline-offset-4 text-sm font-medium cursor-pointer"
       onClick={()=>window.open(`${P2P_LANDING_PAGE}`,'_self')}
       >
          <ChevronLeft className="w-5 h-5"/>
          Back To Landing Page{" "}
        </div>
      </Card>
    </div>
  );
};

export default NewUserErrorHanding;
