import React from 'react'
import { Route, Routes } from 'react-router-dom'
import OnboardingLayout from './Component/OnboardingLayout'
import ConsumerBasicDetails from 'src/modules/Auth/Onboarding/BasicDetails/ConsumerBasicDetails'
import OtpOnbording from 'src/modules/Auth/Onboarding/OtpVerification/OtpOnbording'
import ConsumerErrorMessage from 'src/modules/Auth/Onboarding/ErrorMessage/ConsumerErrorMessage'
import NewUserMain from 'src/modules/Auth/Onboarding/NewUser/NewUserMain'
import CreatePasswordMain from 'src/modules/Auth/Onboarding/CreatePassword/CreatePasswordMain'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { onboardingReducerState } from 'src/redux/Slicer/onboardingSlicer'


const OnboardingRoutes = () => {
  const { onBoardingData,  } = useSelector(
    (state: RootState) => onboardingReducerState(state)
  );
  return (
    <>
      <Routes >
        <Route element={<OnboardingLayout />}>
    
          <Route path="/*" element={<ConsumerBasicDetails />} />
          <Route path='/otp-onboarding' element={<OtpOnbording />} />
          <Route path='/user-notfound' element={<ConsumerErrorMessage />} />
          <Route path="/new-user" element={<NewUserMain />} />
          <Route path="/create-password" element={<CreatePasswordMain />} />
        </Route>
      </Routes>
    </>
  )
}

export default OnboardingRoutes