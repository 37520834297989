import React from "react";
import { Button } from "src/components/ui/button";
import { useAuth } from "src/modules/Auth/Core";
interface UserActivityMessageprops {
  setOpen: (val: boolean) => void;
  
}
const UserActivityMessage = ({ setOpen }: UserActivityMessageprops) => {
  const { logoutHandling } = useAuth();
  return (
    <div className="space-y-4 font-Montserrat">
      <h1 className="text-xl font-bold text-grayLable">Do you wish to continue ?</h1>
      <div className="w-full flex justify-around item-center">
      <Button onClick={() => logoutHandling()}  className="bg-red-500 hover:bg-red-500 text-white">No</Button>
        <Button onClick={() => setOpen(false)}  className="bg-green_1 hover:bg-green_1 text-white">Yes</Button>
        
      </div>
    </div>
  );
};

export default UserActivityMessage;
