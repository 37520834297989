import axios, { AxiosError } from "axios";

const QUERIES = {
  USERS_LIST: "users-list",
};

const months: any = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const MonthNumbers: any = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const startCase = (string: any) => {
  return (
    string &&
    string
      ?.replace(/[\W_]+/g, " ")
      .replace(/(?<=[a-z])([A-Z])/g, " $1")
      .replace(/^[a-z]/, (match: any) => match.toUpperCase())
  );
};

const camelCase = (string: any) => {
  return (
    string &&
    string
      ?.replace(/[\W_]+(.|$)/g, (_: any, chr: any) => " " + chr.toUpperCase())
      .trim()
  );
};

const isNumeric = (num: any) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);

const isValidStr = (str: any, isCampleCase = false) => {
  if (str != null && str != undefined) {
    if (isNumeric(str)) {
      return str;
    }

    if (isCampleCase) {
      return startCase(camelCase(str));
    } else return str;
  } else return "-";
};

function convertToCamelCase(str: any) {
  // const words = str.split('_');
  // const camelCaseString = words.map((word: any, index: any) => {
  //   return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  // }).join(' ');
  // return camelCaseString;

  return startCase(camelCase(str));
}

function convertToCamelCaseNormal(str: any) {
  // const result = str.replace(/([A-Z])/g, " $1");
  // const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  // return finalResult;
  return startCase(camelCase(str));
}

export const getNext30days = () => {
  const today = new Date();
  const nextThirtyDays = [];

  for (let i = 0; i < 28; i++) {
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + i);
    const dateString = nextDay
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(/,/g, "");
    const dateParts = dateString.split(" ");
    const dateObject = {
      fullDate: dateString,
      date: dateParts[1],
      month: dateParts[0],
      year: dateParts[2],
    };
    nextThirtyDays.push(dateObject);
  }

  // console.log(nextThirtyDays);
  return nextThirtyDays;
};

const bussinessDaysInMonth = (m: any, y: any) => {
  var days = new Date(y, m, 0).getDate();

  var sundays = [8 - new Date(m + "/01/" + y).getDay()];
  var saturday = [7 - new Date(m + "/01/" + y).getDay()];

  var allDates = [...Array(days)].map((_, i) => i + 1);
  allDates.splice(
    allDates.findIndex((d: any) => d == sundays),
    1
  );
  allDates.splice(
    allDates.findIndex((d: any) => d == saturday),
    1
  );

  for (var i = sundays[0] + 7; i < days; i += 7) {
    sundays.push(i);
    allDates.splice(
      allDates.findIndex((d: any) => d == i),
      1
    );
  }

  for (var i = saturday[0] + 7; i < days; i += 7) {
    saturday.push(i);
    allDates.splice(
      allDates.findIndex((d: any) => d == i),
      1
    );
  }

  var working_until_days = [];
  var remaining_days = [];
  var currentDate = new Date();
  var curr_date = currentDate.getDate();

  for (let index = 0; index < allDates.length; index++) {
    if (allDates[index] < curr_date) {
      working_until_days.push(allDates[index]);
    } else {
      remaining_days.push(allDates[index]);
    }
  }

  //console.log({ sundays, saturday, allDates, working_until_days, remaining_days });

  return {
    holydays: [...sundays, ...saturday].sort(),
    total_working_days: allDates,
    working_until_days,
    remaining_days,
  };
};

const getMonthNumber = (date: any) => {
  var dt = new Date(date);
  // console.log({ dt, date });
  // console.log("MonthNumbers :", MonthNumbers[dt.getMonth()])
  return MonthNumbers[dt.getMonth()];
};

const getFormatDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return y + "-" + m + "-" + d;
};

const getFormattedDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate();
  var m = MonthNumbers[dt.getMonth()];
  var y = dt.getFullYear();

  // Ensure day and month have leading zeros if needed
  var formattedDay = d < 10 ? "0" + d : d;
  // var formattedMonth = m < 10 ? '0' + m : m;

  return y + "-" + m + "-" + formattedDay;
};

export const getDateInBymFormat = (dateString: string): string => {
  const dateParts = dateString && dateString?.split("-");
  const year = dateParts && dateParts[0];
  const month = dateParts && dateParts[1];
  const day = dateParts && dateParts[2];

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Convert month to its corresponding name
  const formattedMonth = monthNames[parseInt(month, 10) - 1]; // Subtract 1 as month index starts from 0

  // Format the date as '1 March, 2024'
  return `${day} ${formattedMonth}, ${year}`;
};

// const getStartDateAndEndDateOfMonth = (date?: any) => {
//   var dt = date ? new Date(date) : new Date();

//   var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
//   var d = firstDate.getDate(), m = MonthNumbers[dt.getMonth()], y = firstDate.getFullYear();
//   firstDate = getFormattedDate(new Date(y + "-" + m + "-" + d));

//   var lastDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
//   var d = lastDate.getDate(), m = MonthNumbers[dt.getMonth()], y = lastDate.getFullYear();
//   lastDate = getFormattedDate(new Date(y + "-" + m + "-" + d));

//   return { firstDate, lastDate }

// }

const calculateMonthsFromBym = (billYearMonth?: any) => {
  if (!billYearMonth || typeof billYearMonth !== "string") {
    // Handle invalid or missing input
    return { current: "", previous: "", twoMonthsAgo: "" };
  }

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [month, year] = billYearMonth.split("-");

  if (months.indexOf(month) === -1 || isNaN(parseInt(year, 10))) {
    // Handle invalid month or year format
    return { current: "", previous: "", twoMonthsAgo: "" };
  }

  const currentMonthIndex = months.indexOf(month);
  const currentYear = parseInt(year, 10);

  const current = billYearMonth;

  let previousMonthIndex = currentMonthIndex - 1;
  let previousYear = currentYear;
  if (previousMonthIndex < 0) {
    previousMonthIndex = 11; // Set to December
    previousYear--;
  }
  const previous = `${months[previousMonthIndex]}-${previousYear
    .toString()
    .slice(-2)}`;

  let twoMonthsAgoIndex = currentMonthIndex - 2;
  let twoMonthsAgoYear = currentYear;
  if (twoMonthsAgoIndex < 0) {
    twoMonthsAgoIndex += 12;
    twoMonthsAgoYear--;
  }
  const twoMonthsAgo = `${months[twoMonthsAgoIndex]}-${twoMonthsAgoYear
    .toString()
    .slice(-2)}`;

  return { current, previous, twoMonthsAgo };
};

const getStartDateAndEndDateOfMonth = (date?: any) => {
  const dt = date ? new Date(date) : new Date();
  const year = dt.getFullYear();
  const month = dt.getMonth();

  const firstDate = getFormattedDate(new Date(year, month, 1));

  const lastDay = new Date(year, month + 1, 0).getDate();
  const lastDate = getFormattedDate(new Date(year, month, lastDay));

  return { firstDate, lastDate };
};

const getStartDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();

  var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
  var d = firstDate.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = firstDate.getFullYear();
  firstDate = y + "-" + m + "-" + d;

  return { firstDate };
};

const getStartDateWithoutObject = (date?: any) => {
  var dt = date ? new Date(date) : new Date();

  var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
  var d = firstDate.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = firstDate.getFullYear();
  // firstDate =

  return y + "-" + m + "-" + d;
};

const getCurrentMonthYearNo = (_date?: any) => {
  var dt = _date ? new Date(_date) : new Date();
  var date = dt.getDate(),
    month = MonthNumbers[dt.getMonth()],
    year = dt.getFullYear();
  return { month, year, date };
};

const converMonthYearToMonthYearNo = (shortMonthYear?: any) => {
  const [m, y] = shortMonthYear && shortMonthYear.split("-");
  const getMonthIndex = months.findIndex((mm: any) => mm == m);
  var dt = shortMonthYear
    ? new Date("20" + y + "-" + (getMonthIndex + 1) + "-01")
    : new Date();
  var d = dt.getDate(),
    month = MonthNumbers[dt.getMonth()],
    year = dt.getFullYear();
  return { month, year };
};

const getFormatDateKLocale = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return d + "/" + m + "/" + y;
};

const convertShortMonthToDate = (shortMonthYear?: any) => {
  const [month, year] = shortMonthYear.split("-");
  const getMonthIndex = months.findIndex((m: any) => m == month);
  var m = getMonthIndex + 1;
  return 20 + year + "-" + MonthNumbers[m - 1] + "-" + "01";
};

const getFormatToTime = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    m = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds();
  var ampm = h >= 12 ? "pm" : "am";
  return h + ":" + m + ":" + s + " " + ampm;
};

const getFormatToDateTime = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    min = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds(),
    d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  var ampm = h >= 12 ? "pm" : "am";
  return (
    y + "-" + m + "-" + d + " " + "," + h + ":" + min + ":" + s + " " + ampm
  );
};

const getFormatToDateTimeWithoutSecondsTogether = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    min = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds(),
    d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  var ampm = h >= 12 ? "pm" : "am";
  return y + "-" + m + "-" + d + " " + ", " + h + ":" + min + " " + ampm;
};

const getFormatToDateTimeWithoutSeconds = (date?: any) => {
  const dt = date ? new Date(date) : new Date();
  const hours = dt.getHours();
  const minutes = dt.getMinutes();
  const seconds = dt.getSeconds();
  const milliseconds = dt.getMilliseconds();
  const day = dt.getDate();
  const month = MonthNumbers[dt.getMonth()];
  const year = dt.getFullYear();
  const ampm = hours >= 12 ? "pm" : "am";

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes} ${ampm}`;
  // const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds} ${ampm}`;

  return { date: formattedDate, time: formattedTime };
};



const getFormatToDays = (date?: any) => {
  const currentDate = new Date();
  const providedDate = date ? new Date(date) : new Date();

  const differenceInSeconds = Math.floor(
    (currentDate.getTime() - providedDate.getTime()) / 1000
  );

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} second${
      differenceInSeconds !== 1 ? "s" : ""
    } ago`;
  }

  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minute${
      differenceInMinutes !== 1 ? "s" : ""
    } ago`;
  }

  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 24) {
    return `${differenceInHours} hour${differenceInHours !== 1 ? "s" : ""} ago`;
  }

  const differenceInDays = Math.floor(differenceInHours / 24);
  return `${differenceInDays} day${differenceInDays !== 1 ? "s" : ""} ago`;
};

const getShortMonthYear = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return (
    months[dt.getMonth()].toString().toUpperCase() +
    "-" +
    y.toString().slice(-2)
  );
};

const subtractMonthsFromDate = (date?: any, subtractMonths = 1) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = dt.getMonth() + 1 - subtractMonths,
    y = dt.getFullYear();
  return y + "-" + MonthNumbers[m - 1] + "-" + "01";
};

const subtractYearsFromDate = (date?: any, subtractMonths = 1) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return y - subtractMonths + "-" + m + "-" + "01";
};

function convertMMYYYYToMMMMYYYY(dateString: any) {
  const [monthAbbr, year] = dateString.split("-");
  const monthAbbreviations: any = {
    JAN: "January",
    FEB: "February",
    MAR: "March",
    APR: "April",
    MAY: "May",
    JUN: "June",
    JUL: "July",
    AUG: "August",
    SEP: "September",
    OCT: "October",
    NOV: "November",
    DEC: "December",
  };

  const monthName = monthAbbreviations[monthAbbr.toUpperCase()];

  if (!monthName) {
    throw new Error("Invalid month abbreviation");
  }

  const formattedDate = `${monthName} ${year}`;
  return formattedDate;
}

export const downloadFile = (
  filePath: string,
  fileName = "Example-PDF-file.pdf",
  indx: number
) => {
  try {
    fetch(filePath, {
      method: "GET",
      headers: {
        "Content-Type": "application/jpeg",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link && link.parentNode && link.parentNode.removeChild(link);
      });
  } catch (err) {
    let error = err as Error | AxiosError;
    if (axios.isAxiosError(error)) {
    
    } else {
      // console.log(error)
    }
  }
};

function calculateNextDate(currentDate: Date, dt: number) {
  const date = new Date(currentDate);

  const nextDate = new Date(date);
  nextDate.setDate(date.getDate() + dt);

  const formattedNextDate = nextDate;

  return formattedNextDate;
}

// covert UCT TO IST

export const getUCTToISTTTime = (a: Date) => {
  let d = new Date(a);
  return new Date(d.getTime() + 5.5 * 60 * 60 * 1000).toLocaleString();
};

export const getStartDateAndEndDateOfMonthFull=()=> {
	let date = new Date();

	let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	let obj={
    firstDay:firstDay,
    lastDay:lastDay
}
return obj
}

export const dateForFirstOfMonthAndYesterdayDate=()=> {
	let date = new Date();

const yesterday = new Date(date);
yesterday.setDate(new Date(date).getDate() - 1);

	let firstDay = new Date(new Date(yesterday).getFullYear(), new Date(yesterday).getMonth(), 1);
  const sameDayLastMonth = new Date(yesterday);
  sameDayLastMonth.setMonth(date.getMonth() - 1);

  // Check if the date needs adjustment for months with fewer days
  if (sameDayLastMonth.getDate() !== yesterday.getDate()) {
    sameDayLastMonth.setDate(0); // Set to last day of the previous month if it overflows
  }

  const sameDayLastYear = new Date(yesterday);
  sameDayLastYear.setFullYear(yesterday.getFullYear() - 1);

  // Check if the date needs adjustment for leap years or shorter months
  if (sameDayLastYear.getDate() !== yesterday.getDate()) {
    sameDayLastYear.setDate(0); // Set to last day of the previous year if it overflows
  }; // Set to last day of the previous month if it overflows
  
	let obj={
    yesterday:yesterday,
    firstDay:firstDay,
    today:date,
    sameDayLastMonth: sameDayLastMonth,
    sameDayLastYear: sameDayLastYear,
}
return obj
}

export const getlastThirtyDateAndLastSevenDates=() =>{
  const currentDate = new Date();

  // Get date 7 days ago
  const prev7Days = new Date();
  const yesterDay=new Date()
  yesterDay.setDate(currentDate.getDate() - 1);
  prev7Days.setDate(currentDate.getDate() - 7);

  // Get date 30 days ago
  const prev30Days = new Date();
  prev30Days.setDate(currentDate.getDate() - 30);


  return {
    currentDate: currentDate,
    prev7Days: prev7Days,
    prev30Days: prev30Days,
    yesterDay:yesterDay
  };
}


// const color = [
//   "#1FC777", "#7750CE", "#CAB54B", "#117E4D", '#FBAA49', "#FD5EC3", "#333333", "#FC228A", "#7E5348", "#E0465E", "#C4C4C4",
//   "#B93555", "#A161D9", "#808080", "#5A9DF9", "#1487BE", "#0b4c8c", "##de4e2b", '#e8912d', '#e8912d', '#007a5a'
// ]

const blue = ["#d6e3f8", "#5a9df9", "#2146c7", "#0b4c8c", "#002a5c"];
const green = ["#bceed6", "#7bd5b8", "#1fc777", "#1e9870", "#117e4d"];
const red = ["#ffd3d3", "#ffa6a6", "#e5383b", "#c42348", "#d90429"];
const orange = ["#fdceb3", "#fa9d66", "#ff773d", "#de4e2b", "#e62314"];
const yellow = ["#ffffb7", "#ffea86", "#ffd454", "#fdb833", "#e8912d"];
const pink = ["#ffebf8", "#feafe1", "#fc228a", "#f20089", "#da007b"];
const voilet = ["#f2ebf8", "#c9ace2", "#9258c5", "#5b377b", "#3d0e61"];

let ERROR_CODES = [400, 500, 404, 401];

enum ENUM_CONSUMER_TYPE {
  CONSUMER = "consumer",
  PROSUMER = "prosumer",
}

enum ENUM_STATUS_CODE {
  SUCCESS = 200,
  AUTHENDICATE = 401,
  CREATE=201,
  RUN_TIME_ERROR=503,
  SESSION_EXPIRED=440
}

enum ENUM_PURCHASE_TYPE {
  BUY = "buy",
  SELL = "sell",
}

export enum BILL_TRANSACTION{
  DAILY="daily",
  TRANSACTION='transaction'
}

enum ENUM_ORDER_HISTORY_STATUS {
  ALL = "all",
  PENDING = "pending",
  COMPLEATED = "completed",
}

enum ENUM_ORDER_HISTORY_STATUS_NEW {
  NEW = "new",
  PENDING = "pending",
  MATCHED = "matched",
  PARTIAL_EXECUTED = "partial_executed",
  PARTIAL_TRADED = "partial_traded",
  FULL_EXECUTED = "full_executed",
  CANCELLED = "cancelled",
  REJECTED = "rejected",
  FULLY_TRADED = "fully_traded",
  PARTIAL_SETTLED='partial_settled',
  FULL_SETTED='full_settled'
}

enum ENUM_ORDER_CATEGORY {
  INTRADAY = "intraday",
  DAY_AHEAD = "day_ahead",
  TERM_AHED='term_ahead'
}
enum ENUM_ORDER_TYPE {
  MARKET = "market",
  LIMIT = "limit",
}
enum ENUM_ORDER_HISTORY_TRADE__STATUS {
  EXPIRED = "expire",
  REJECTED = "rejected",
  PASS = "pass",
  WAITING = "waiting",
  INPROGRESS = "InProgress",
  PENDING = "pending",
  Partially_Completed='Partially_Completed',
  Completed="Completed",
  FAILED='failed',
  UNKNOWN='unknown',
}

enum SETTLEMENT_STATUS{
  FAILED='failed',
 SETTLED ="settled"
}

enum ENUM_PROFILE_SCREEN {
  ACCOUNT = "account",
  PROFILE_DETAIL = "profile_detail",
  CONTACT = "contact",
}

export enum ENUM_ACCOUNT_DETAILS_TAB {
  EDIT_PROFILE = "edit_profile",
  CHANGE_PASSWORD = "change_password",
}

export enum ENUM_IS_VERIFIED {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING='PENDING'
}

export enum ENUM_OTS_DAY_WEEK_MONTH {
  DAY = "day",
  WEEK = "weekly",
  MONTH = "monthly",
}

export enum ENUM_OTS_SWITCH_FILTER_STATE {
  USER = "user",
  MARKET = "market",
}

export enum ENUM_VERIFICATION_REPORT_FILTER {
  BUYER = "buyer",
  SUPPLIER = "supplier",
}

export enum ENUM_ONBOARDING {
  ALREADY_P2P_USER = "Already_P2p_User",
  NEW_USER = "new_user",
  USER_NOT_EXISTS = "User_Not_Exists",
  STATIC_PAGE = "static_page",
}
export enum ENUM_LOGIN_TAB {
  PHONE = "phone",
  CONSUMERnO = "consumerNo",
  EMAIL = "email",
}

export enum ENUM_NEW_TRADE_MARKET_TYPE {
  INTRADAY = "intraday",
  DAY_AHEAD = "day_ahead",
  TERM_AHED = "term_ahead",
}

export enum ENUM_NEW_TRADE_FILTER_TAB {
  SMART_NATCHING = "smart_matching",
  SELF_SELECTION = "self_selection",
}

export enum ENUM_IMPORT_EXPORT {
  IMPORT = "import",
  EXPORT = "export",
}

export enum ENUM_PORTFOLI_HISTORY_TAB {
  MONTHLY = "monthly",
  PAST = "past",
}
export const notificationDateKeys = "localstartDate";

export function getInitials(name: any): string {
  const names = name.split(" ");
  const initials = names.map((name: any) => name.charAt(0)).join("");
  return initials.toUpperCase();
}

export function getArrayOfCurrentMonthDates() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  // Get the first and last date of the current month
  const firstDate = new Date(year, month, 1);
  
  
  const lastDate = new Date(year, month + 1, 0); // 0th day of the next month gives us the last day of the current month

  // Create an array of dates
  const dates = [];
  for (let day = firstDate.getDate(); day <= lastDate.getDate(); day++) {
    dates.push(new Date(year, month, day));
  }

  return dates;
}

export  const NUMBER_PATTERN="^[0-9]+$"
export const REGEXP_ONLY_DIGITS_AND_CHARS = "^[a-zA-Z0-9]+$";
export {
  QUERIES,
  bussinessDaysInMonth,
  convertToCamelCase,
  getShortMonthYear,
  getFormatDate,
  getFormatToTime,
  getFormatToDateTime,
  getFormatDateKLocale,
  convertShortMonthToDate,
  getCurrentMonthYearNo,
  converMonthYearToMonthYearNo,
  getStartDateAndEndDateOfMonth,
  subtractMonthsFromDate,
  subtractYearsFromDate,
  convertToCamelCaseNormal,
  isValidStr,
  getStartDate,
  ERROR_CODES,
  getFormatToDateTimeWithoutSeconds,
  getStartDateWithoutObject,
  getFormattedDate,
  getFormatToDays,
  getFormatToDateTimeWithoutSecondsTogether,
  calculateMonthsFromBym,
  convertMMYYYYToMMMMYYYY,
  calculateNextDate,
  ENUM_CONSUMER_TYPE,
  ENUM_STATUS_CODE,
  ENUM_PURCHASE_TYPE,
  ENUM_ORDER_HISTORY_STATUS,
  ENUM_ORDER_CATEGORY,
  ENUM_ORDER_HISTORY_TRADE__STATUS,
  ENUM_ORDER_TYPE,
  ENUM_PROFILE_SCREEN,
  ENUM_ORDER_HISTORY_STATUS_NEW,
  SETTLEMENT_STATUS
};
