import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'src/components/ui/dialog'

type Props = {
    show: boolean, setShow: (e: any) => void, transactionId: string
}

const PaymentSuccessDialog = ({ show, setShow, transactionId }: Props) => {
    return (
        <Dialog open={show} onOpenChange={(e) => setShow(e)}  >
            <DialogContent className="sm:max-w-[425px] p-0 rounded-lg overflow-hidden">
                <DialogHeader className='bg-green-600 text-white p-2 h-12'>
                    <DialogTitle className='text-center mt-2'>Payment Successful 🎉</DialogTitle>
                </DialogHeader>
                <div className='flex flex-col items-center  p-4'>
                    <p>Your payment was successful! 🎊</p>
                    <strong>Transaction ID:</strong> <span>{transactionId}</span>
                    <Button onClick={()=>setShow(false)} size={'sm'} className='mt-2 bg-slate-100 text-gray-600 border-gray-600 w-[150px] hover:text-white hover:bg-primaryMediumBlue' >Close</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentSuccessDialog