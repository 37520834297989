import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: 'srNo',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="SrNo." />
    ),
  },
  {
    accessorKey: 'txnTime',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell({ row: { original: { txnTime } } }) {
      return <div>{dayjs(txnTime).format('DD/MM/YYYY')}</div>
    },
  },
  {
    accessorKey: 'lastBalance',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Last Balance (₹)" />
    ),
    cell({ row: { original: { lastBalance } } }) {
      return <div>{Number(lastBalance || 0).toFixed(2)}</div>;
    },
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount (₹)" />
    ),
    cell({ row: { original: { operation, amount } } }) {
      if (operation == "CREDIT") {
        return <div className="font-semibold text-green-500">+ {Number(amount || 0).toFixed(2)}</div>;
      }
      else if (operation == "DEBIT") {
        return <div className="font-semibold text-red-500">- {Number(amount || 0).toFixed(2)}</div>;
      }
    },
  },
  {
    accessorKey: 'method',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Method" />
    ),
  },
  {
    accessorKey: 'operation',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Transaction" />
    ),
    cell({ row: { original: { operation } } }) {
      if (operation == "CREDIT") {
        return <div className="font-semibold text-green-500">{operation}</div>;
      }
      else if (operation == "DEBIT") {
        return <div className="font-semibold text-red-500">{operation}</div>;
      }
    },
  },


];
