import {toAbsoluteUrl } from 'src/helpers'
import a from "public/asset/image/p2p_energy_app_bg_banner.png"
type Props = {}
const IMG_GOOGLE_PLAY=toAbsoluteUrl('/asset/image/googleplay.png')
const IMG_P2P_DOWNLOAD_APP=toAbsoluteUrl('/asset/image/p2p_energy_app.png')
const IMG_P2P_DOWNLOAD_APP_BG_BANNER=toAbsoluteUrl('/asset/image/p2p_energy_app_bg_banner.png')
const IMG_P2P_ENERGY_BENEFITS_BG=toAbsoluteUrl('/asset/image/P2PBenifitBackground.png')
const P2pDownloadApp = (props: Props) => {
    return (
        <div className='w-full' style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_BENEFITS_BG + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className='max-w-[80vw] mx-auto h-[350px]' style={{ backgroundImage: "url(" + IMG_P2P_DOWNLOAD_APP_BG_BANNER + ")", backgroundRepeat: "no-repeat", backgroundSize: "100% 80%", backgroundPosition: "center" }}>
                <div className=' grid grid-cols-2 h-full  '>
                    <div className='flex items-center h-full  p-8 '>
                        <div className='flex flex-col space-y-8 '>
                            <div>
                                <div className='text-4xl font-bold text-white'>Download Trading App </div>
                                <div className='h-[2px] mt-4 bg-primary w-[50px]'></div>
                            </div>
                            <div className=' text-white uppercase'>Discover global markets with <br/> an easy-to-use app.</div>
                            <div className=''>
                                <img src={IMG_GOOGLE_PLAY} style={{ height: "50px" }} />
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <img src={IMG_P2P_DOWNLOAD_APP} style={{height:"350px"}}  />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default P2pDownloadApp