import React, { useMemo } from 'react'
import MeterDetails from './MeterDetails'
import { basic } from './modules'
import { convertToCamelCase } from 'src/helpers'



const ConsumerDetails = (data: any) => {


    const basicDetails = useMemo(() => data?.data, [data])

    // console.log(basicDetails, "consumer Details");

    return (
        <div className='p-4'>
            <div className='w-full grid grid-cols-3 gap-y-2 mb-4'>
                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Consumer Number</div>
                    <div className='text-xs font-bold text-black'>{basicDetails?.consumerNo ? basicDetails?.consumerNo : "-"}</div>
                </div>

                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Consumer Type</div>
                    <div className='text-xs font-bold text-black'>{basicDetails?.consumerType ? convertToCamelCase(basicDetails?.consumerType) : "-"}</div>
                </div>

                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Status</div>
                    {<div className='text-xs font-bold text-green-500'>{basicDetails?.isActive ? "Active" : "-"}</div>}
                </div>
            </div>

            <div className='w-full grid grid-cols-2 gap-y-2 mb-4'>
                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Consumer Address</div>
                    <div className='text-xs font-bold text-black'>{basicDetails?.address ? basicDetails?.address : "-"}</div>
                </div>

                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Pincode</div>
                    <div className='text-xs font-bold text-black'>{basicDetails?.pinCode ? basicDetails?.pinCode : "-"}</div>
                </div>
            </div>

            <div className='w-full grid grid-cols-3 gap-y-2 mb-4'>
                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Installed Capacity</div>
                    <div className='text-xs font-bold text-black'>{basicDetails?.plantDetails[0]?.installedCapacity ? basicDetails?.plantDetails[0]?.installedCapacity : "-"}</div>
                </div>

                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Sanctioned Load / Contractual Load</div>
                    <div className='text-xs font-bold text-black'>{basicDetails?.plantDetails[0]?.contractedCapacity ? basicDetails?.plantDetails[0]?.contractedCapacity : "-"}</div>
                </div>
            </div>

            <div className='space-y-2'>
                <div className='font-semibold text-gray-400 text-sm '>Meters Details</div>
                <div className='w-full grid grid-cols-3 gap-y-2 mb-4'>
                    < MeterDetails data={basicDetails && basicDetails?.meters} />
                </div>
            </div>

        </div>
    )
}

export default ConsumerDetails