import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

interface PortFolioProps {
    error: boolean,
    isLoading: boolean,
    message: string | undefined,
    dataChanged: boolean,
    rowData: any[]
}

const initialState: PortFolioProps = {
    error: false,
    isLoading: false,
    message: undefined,
    dataChanged: false,
    rowData: []
}

export const portfolioSlicer = createSlice({
    name: 'portfolioSlicer',
    initialState,
    reducers: {
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        updateRowData: (state, action: PayloadAction<any>) => {
            state.rowData = action.payload
        }
    }

})

export const { setError, resetChangedState, resetError, updateRowData } = portfolioSlicer.actions;
export const portFolioReducerState = (state: RootState) => state.portFolio;
export default portfolioSlicer.reducer;