
import React, { useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from 'react-router-dom';
import { AuthInit, useAuth } from './modules/Auth/Core';

import { useDispatch } from 'react-redux';
import { dropdownConfig } from './redux/Slicer/dropDownSlice';

import ErrorBoundryMain from './modules/ErrorBoundry/ErrorBoundryMain';




function App() {

  const [open, setOpen] = useState(false)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  const dispatch = useDispatch();
  const { auth, currentUser } = useAuth();


  useEffect(() => {
    dispatch(dropdownConfig())
  }, [])


  return (
    <div className='w-full flex h-full overflow-hidden'>
      <QueryClientProvider client={queryClient}>
        {/* <OnlineStatusProvider> */}
        <AuthInit>
          <Outlet />
      
          {/* <Toaster position="top-center" richColors/> */}
          <ErrorBoundryMain />
        </AuthInit>
        {/* </OnlineStatusProvider> */}
      </QueryClientProvider>
      
    </div>
  )
}

export default App;
