import { Wallet } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"
import PaymentFailerDialog from 'src/modules/PaymenModule/PaymentFailerDialog'
import PaymentForm from 'src/modules/PaymenModule/PaymentForm'
import PaymentSuccessDialog from 'src/modules/PaymenModule/PaymentSuccessDialog'
import { fetchWalletAmountByUserId, getWalletReducerState } from 'src/redux/Slicer/walleteSlicer'
import { RootState } from 'src/redux/store'

type Props = {}

const WalletBtn = (props: Props) => {

    const [showPaymentForm, setshowPaymentForm] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { amount, loading } = useSelector((state: RootState) => getWalletReducerState(state))
const navigate=useNavigate()
    useEffect(() => {
        dispatch(fetchWalletAmountByUserId())
        return () => { }
    }, [])



    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <div className="flex space-x-2 items-center hover:bg-yellow-50 p-2 rounded-sm cursor-pointer ">
                        <Wallet className="text-yellow-500" size={20} /> <span className="flex space-x-1 items-center">{loading ? <>Loading....</> : <><span className=" text-gray-400">₹</span><span className="text-sm"> {amount || "00.0"}</span></>}</span>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                    <DropdownMenuLabel>Wallet</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                        <DropdownMenuItem onClick={() => {navigate("/wallet-history") }}>
                            Wallet History
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => {navigate("/wallet-details") }}>
                            Wallet Details
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => setshowPaymentForm(true)}>
                            Add payment
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
            <PaymentForm showPaymentForm={showPaymentForm} setShowPaymentDialog={(e) => setshowPaymentForm(e)} />
        </>
    )
}

export default WalletBtn