import { AlertCircle } from 'lucide-react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'src/components/ui/button'
import Modal_md from 'src/components/ui/common/Dialog/Modal_md'

type Props = {
    children: React.ReactNode
}

const AccessAuthorityCheck = ({ children }: Props) => {

    let accessAuthority = JSON.parse(localStorage.getItem("accessToken") as any)?.accessAuthority
    // let accessAuthority = ""
    const navigate = useNavigate()
    if (accessAuthority == "actual_owner") {
        return (
            <div className='w-full h-full'>{children}</div>
        )
    }
    else {
        return (
            <Modal_md open={true} hideCrossIcon={true}>
                <div className='flex flex-col space-y-4 w-full h-full'>
                    <div className='text-destructive text-xl font-bold flex items-center space-x-2 justify-center'><span><AlertCircle size={20} /></span><span>Alert</span></div>
                    <div className='text-sm space-y-1 p-2'>
                        <p className='text-center'>Dear User, your trading access is currently on hold due to an unpaid EMI.To resume access, please ensure your wallet has sufficient balance to cover the EMI.</p>
                        {/* <p className='text-center'></p> */}
                    </div>
                    <div className='flex justify-center'>
                        <Button onClick={() => navigate(-1)} className='w-[150px]'>Back</Button>
                    </div>
                </div>
            </Modal_md>
        )
    }

}
 
export default AccessAuthorityCheck