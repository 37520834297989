import { useFormik } from 'formik'
import React, { useState } from 'react'
import OtpImage from 'src/assets/images/1231.png'
import Buttons from 'src/components/ui/common/Button/Buttons'
import InputPassword from 'src/components/ui/common/InputComp/InputPassword'
import { ResetPasswordeProps, ResetPasswordInitialVal, validationSchemaResetPassword } from './modules'
import { useNavigate } from 'react-router-dom'
import { forgotPasswordApi } from 'src/service/services'
import { toast } from 'sonner';
import axios, { AxiosError } from 'axios'
import { ENUM_STATUS_CODE } from 'src/helpers'
import { useDispatch } from 'react-redux'
import { updateError, updateErrorCode, updateSuccessMess } from 'src/redux/Slicer/errorHadlingSlicer'
import { useAuth } from '../../Core'
const ResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const{auth}=useAuth()
 
const dispatch=useDispatch()
  const formik = useFormik<ResetPasswordeProps>({
    initialValues: ResetPasswordInitialVal,
    onSubmit: async (values, { resetForm, setValues }) => {
      // console.log('Form Values:', values);

      let postData = {
        ...values
      }

      if (postData) {
        henadleResetOtpApi(postData)
      }
      resetForm()
      // setValues(ResetPasswordInitialVal)
    },

    validationSchema: validationSchemaResetPassword
  })

  const henadleResetOtpApi = async (postData: any) => {
    setLoading(true)

    try {
      let apiResp: any = await forgotPasswordApi(postData);
      dispatch(updateErrorCode(apiResp.code))
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        navigate('/login')
        // dispatch(updateLoginData(apiResp?.data))
        dispatch(updateSuccessMess(apiResp.message))
        // toast.success(apiResp.message)
        formik.resetForm();
        formik.setValues(ResetPasswordInitialVal)
      } else {
        dispatch(updateErrorCode(apiResp.code))
        dispatch(updateError(apiResp.message))
        // toast.error(apiResp.message)
      }
      return apiResp.data
    } catch (err) {
      let error = err as Error | AxiosError
      if (axios.isAxiosError(error)) {
        dispatch(updateErrorCode(error.response?.data.code))
        dispatch(updateError(error.response?.data.message))
        // toast.error(error.response?.data.message)
      }
      else {
        dispatch(updateError(error.message))
        // toast.error(error.message)
      }
    } finally {
      setLoading(false); // Set loading state to false when request completes (whether success or failure)
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='w-full h-[100%] my-2 space-y-6 md:rounded-xl  md:border md:border-gray-100 md:bg-white lg:flex lg:flex-row-reverse lg:items-center'>
        <div className='w-full flex justify-center items-center '>
          <img src={OtpImage} className='w-3/4 lg:w-3/4' />
        </div>
        <div className='w-full ml-20 '>
          <div className='w-full  space-y-2'>
            <div className='w-full text-xl font-bold text-GlobalColor '>Reset Password</div>
            <div className='w-1/2 text-sm font-normal  text-black'>
              Set your new password so you can login and access
            </div>
          </div>

          <div  className='w-full md:w-3/4'>
          <InputPassword
             
              id='newPassword'
              placeholder='New Password'
              errorMessage={formik.touched.new_password && formik.errors.new_password ? formik.errors.new_password : ''}
              onchange={(value: string) => {
                formik.setFieldValue('new_password', value)
              }}
              values={formik.values.new_password}
              {...formik.getFieldProps('new_password')}
            />
            <div className='w-full md:-mt-4'>
              <InputPassword
             
                id='resetPassword'

                placeholder='Confirm Password'
                errorMessage={formik.touched.confirm_password && formik.errors.confirm_password ? formik.errors.confirm_password : ''}
                onchange={(value: string) => {
                  formik.setFieldValue('confirm_password', value)
                }}
                values={formik.values.confirm_password}
                {...formik.getFieldProps('confirm_password')}
              />
            </div>
          </div>
          <div className='w-full md:w-3/4 md:mt-6'>
            <Buttons btnName='CONFIRM' data-qa='confirmbtn' type='submit' id='resetbtn' loading={loading} />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ResetPassword
