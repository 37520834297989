import React from 'react'
import a from "public/asset/image/p2p_energy_how_it_works.webp"
import { toAbsoluteUrl } from 'src/helpers'

type Props = {}
const IMG_P2P_ENERGY_HOW_IT_WORKS=toAbsoluteUrl('/asset/image/p2p_energy_how_it_works.png')
const IMG_P2P_ENERGY_BENEFITS_BG=toAbsoluteUrl('/asset/image/P2PBenifitBackground.png')
const P2pEnergyHowItWorks = (props: Props) => {
    return (
        <div className='h-[600px] p-8'  style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_BENEFITS_BG + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className='max-w-[80vw] mx-auto'>
                <div className='flex flex-col justify-center items-center space-y-2'>
                    {/* <span className='text-sm  uppercase'>How  P2P trading Works</span>
                    <span className='h-[2px] bg-primary w-[50px]'></span> */}
                    <span className='font-bold text-3xl'>How it works?</span>
                </div>
                <div className='flex justify-center items-center mt-12 '>
                    <img src={IMG_P2P_ENERGY_HOW_IT_WORKS} style={{height:"300px"}} />
                </div>
            </div>
        </div>
    )
}

export default P2pEnergyHowItWorks