import React from 'react'
import { Outlet } from 'react-router-dom'

const ForgotLayOut = () => {
  return (
    <>
    <div className='w-full h-[100%] md:bg-primaryDarkBlue lg:relative'>
        <div className='w-full lg:fixed h-[100%] md:border-x-[80px] md:border-y-[80px] lg:border-x-[80px] lg:border-y-[80px] md:border-[#026597] lg:bg-[#026597] lg:flex lg:flex-1 '>

            <Outlet />

        </div>
    </div>
</>
  )
}

export default ForgotLayOut
