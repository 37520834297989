import React from 'react'
import CreatePass from './Components/CreatePass'
import { Button } from 'src/components/ui/button'

type Props = {}

const CreatePasswordMain = (props: Props) => {
    return (
        <div className='p-6 font-Montserrat'>
            <div className='mb-10'>
                <div className='text-gray-500 font-bold'>User Onboarding</div>
                <div className='text-gray-600 text-xs'>Peer To Peer  Energy Trading Application</div>
            </div>

            <div className='mb-4'>
                <div className={`text-black text-xs font-bold `}>Please Enter Password</div>
                <CreatePass />
            </div>
        </div>
    )
}

export default CreatePasswordMain