import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, onboardingReducerState } from '../../../../../redux/Slicer/onboardingSlicer'
import { RootState } from '../../../../../redux/store'

type Props = {}

const BasicDetails = (props: Props) => {

    const { fetchUserData, urlObj,verifyTokenData }: any = useSelector((state: RootState) => onboardingReducerState(state))

    const basicDetails = useMemo(() => fetchUserData, [fetchUserData])

    const dispatch = useDispatch()




    return (
        <div>
            <div className='w-full grid grid-cols-3 gap-y-2 mb-4'>
                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs' data-testid="basic-details-Consumername">Consumer Name</div>
                    <div className='text-xs font-bold text-black' data-testid="basic-details-name">{basicDetails?.name ? basicDetails?.name : "-"}</div>
                </div>

                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Phone</div>
                    <div className='text-xs font-bold text-black' data-testid="basic-details-phone">{basicDetails?.phone ? basicDetails?.phone : "-"}</div>
                </div>

                <div className='w-full flex flex-col'>
                    <div className='text-gray-600 text-xs'>Email</div>
                    <div className='text-xs font-bold text-black' data-testid="basic-details-email">{basicDetails?.email ? basicDetails?.email : "-"}</div>
                </div>
            </div>
        </div>
    )
}

export default BasicDetails