import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

import axios, { AxiosError } from "axios"


interface RegestrationState {
    dropId: number
    data: any,
    error: boolean,
    message: string | undefined,
    isLoading: boolean,
    consumerType: string | undefined
}

interface PostData {
    first_name: string,
    last_name: string,
    username: string,
    email: string,
    password: string,
    roles: string[],
    phone: string,


}

const initialState: RegestrationState = {
    dropId: 0,
    data: [],
    error: false,
    message: undefined,
    isLoading: false,
    consumerType: '',
}

export const regestrationP2pService: any = createAsyncThunk(
    'registration/regestrationP2pService',
    async (posData: PostData, thunkApi: any) => {
        try {
            const data = undefined
            return data;
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const regestrationSlicer = createSlice({
    name: 'regestrationSlicer',
    initialState,
    reducers: {
        UpdateDropId(state, action: PayloadAction<any>) {
            state.dropId = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        updateConsumerType(state, action: PayloadAction<any>) {
            state.consumerType = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(regestrationP2pService.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoading = false
        });
        builder.addCase(regestrationP2pService.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(regestrationP2pService.rejected, (state, action) => {
            state.isLoading = false;
            state.message = action.payload || 'Error Occured'
        })



    }
})

export const { UpdateDropId, setError, updateConsumerType } = regestrationSlicer.actions;
export const regestrationState = (state: RootState) => state.registration
export default regestrationSlicer.reducer