import dayjs from 'dayjs';
import { IndianRupee, Zap } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Button } from 'src/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card'
import WalletHistoryMain from '../WalletHistoryMain';
import { axiosWithToken } from 'src/service/axioseSetup';
import PaymentForm from 'src/modules/PaymenModule/PaymentForm';

type Props = {}
interface UserDetails {
    currentBalance: number;
    lastPaymentDate: string; // You might want to use Date type if you parse it
    totalDueAmount: number;
    dueMonths: any[]; // Assuming dueMonths is an array of strings
    totalDueCount: number;
    energyBalance: number;
}


const WalletDetails = (props: Props) => {


    const [useDetails, setuseDetails] = useState<UserDetails>({
        currentBalance: 0,
        lastPaymentDate: "",
        totalDueAmount: 0,
        dueMonths: [],
        totalDueCount: 0,
        energyBalance: 0
    })
    const [showPaymentForm, setshowPaymentForm] = useState<boolean>(false)


    useEffect(() => {
        handleFetchWalletDetails()
        return () => { }
    }, [])


    const handleFetchWalletDetails = async () => {
        try {
            const response = await axiosWithToken('/transaction/details').then((d: any) => d.data)
            console.log({ response });

            if (response?.code == 200) {
                setuseDetails(response.data)
            }
        } catch (error) {

        }
    }


    return (
        <div className='w-full h-full'>
            <div className='grid grid-cols-4 gap-4 h-full'>
                <div className='flex flex-col space-y-4 h-full'>
                    <Card className='space-y-6'>
                        <CardContent className='h-full p-4'>
                            <>
                                <div className='flex justify-center items-center h-[180px] bg-blue-50 rounded-md'>
                                    <div className='flex flex-col items-center  space-y-2'>
                                        <div>Current Balance</div>
                                        <div className="text-3xl font-bold text-center text-green-500 flex space-x-2 items-center"><span><IndianRupee className='text-bold' /></span><span>{useDetails.currentBalance}</span></div>
                                        {/* <div>Energy Balance</div>
                                        <div className="text-3xl font-bold text-center  text-yellow-600 flex space-x-2 items-center"><span><Zap className='text-bold' /></span><span>{useDetails.energyBalance}</span></div> */}
                                    </div>
                                </div>
                                <div className='space-y-2 mt-4'>
                                    <div className='font-bold text-md text-gray-500 mb-4'>EMI Details</div>
                                    <div>
                                        <div className="text-gray-500 text-sm">Total Pending Due's (#)</div>
                                        <div>{useDetails.totalDueCount}</div>
                                    </div>
                                    <div>
                                        <div className="text-gray-500 text-sm">Total Due Amount (₹)</div>
                                        <div>{useDetails.totalDueAmount}</div>
                                    </div>
                                    <div>
                                        <div className="text-gray-500 text-sm">Last Payment Date </div>
                                        <div>{useDetails.lastPaymentDate ? dayjs(useDetails.lastPaymentDate).format("DD/MM/YYYY") : ""}</div>
                                    </div>
                                    <div className='flex justify-center'>
                                        <Button onClick={()=>setshowPaymentForm(true)} className='mt-5'  >+  Add Payment</Button>
                                        <PaymentForm showPaymentForm={showPaymentForm} setShowPaymentDialog={(e: any) => setshowPaymentForm(e)} />
                                    </div>
                                </div>
                            </>
                        </CardContent>
                    </Card>
                </div>
                <div className='col-span-3 h-full'>
                    <WalletHistoryMain />
                </div>
            </div>
        </div>
    )
}

export default WalletDetails