
import React from 'react'
import { DialogContent, DialogHeader, Dialog, DialogTitle } from '../../dialog_md'
interface FormPopupProps {
    open: boolean;
    setOpen?: (val: boolean) => void
    children?: React.ReactNode,
    hideCrossIcon?: boolean
}
const Modal_md = ({ open, setOpen,hideCrossIcon, children }: FormPopupProps) => {

    return (

        <Dialog open={open} onOpenChange={setOpen} >
            <DialogContent className="w-[100%] flex flex-col items-center px-2 border border-border_primaryDarkBlue z-9999"  
            onPointerDownOutside={(event) => event.preventDefault()}
            hideCrossIcon={hideCrossIcon}
            >
            <DialogTitle></DialogTitle>
                {children}
            </DialogContent>
        </Dialog>

    )
}

export default Modal_md
