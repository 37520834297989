import { useFormik } from 'formik'
import { Smartphone, User } from 'lucide-react'
import React, { useState } from 'react'

import LoginImage from 'src/assets/images/20824344_6343823 1.png'
import Inputs from 'src/components/ui/common/InputComp/Inputs'
import LInputComp from 'src/components/ui/common/InputComp/LInputComp'
import RInputComp from 'src/components/ui/common/InputComp/RInputComp'

import { cn, ENUM_STATUS_CODE } from 'src/helpers'
import { number } from 'yup'
import { ForgotPasswordMobileNoInitialVal, ForgotPasswordMobileNoProps, validationSchemaForgotPasswordMobile } from './modules'
import Buttons from 'src/components/ui/common/Button/Buttons'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner';
import axios, { AxiosError } from 'axios'
import { loginViaConsumerNumberForgotPassword, loginViaPhoneForgotPassword } from 'src/service/services'
import { updatePhoneNumber } from 'src/redux/Slicer/loginSlicer'
import { updateError, updateErrorCode } from 'src/redux/Slicer/errorHadlingSlicer'


const EnterMobileNo = () => {

    const [loading,setLoading]=useState<boolean>(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const formik = useFormik<ForgotPasswordMobileNoProps>({
        initialValues: ForgotPasswordMobileNoInitialVal,
        onSubmit: async (values, { resetForm, setValues }) => {
            // console.log('Form Values:', values);

            resetForm()
        const postData={
            ...values
        }
        if(postData){
            henadleSendOtpApi(postData)
            // dispatch(updatePhoneNumber(values.consumerNo))
        }
            
        },

        validationSchema: validationSchemaForgotPasswordMobile
    })

    const henadleSendOtpApi = async (postData: any) => {
        setLoading(true)
        try {
          let apiResp = await loginViaConsumerNumberForgotPassword(postData);
          dispatch(updateErrorCode(apiResp.code))
          if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
            navigate('otp-forgot')
            // dispatch(updateLoginData(apiResp?.data))
    
            formik.resetForm();
            formik.setValues(ForgotPasswordMobileNoInitialVal)
            dispatch(updatePhoneNumber(apiResp?.data?.phone||""))
           
          } else {
            dispatch(updateErrorCode(apiResp.code))
            dispatch(updateError(apiResp.message))
            // toast.error(apiResp.message)
          }
          return apiResp.data
        } catch (err) {
          let error = err as Error | AxiosError
          if (axios.isAxiosError(error)) {
            dispatch(updateErrorCode(error.response?.data?.code))
            dispatch(updateError(error.response?.data.message))
            // toast.error(error.response?.data.message)
          }
          else {
            dispatch(updateError(error.message))
            // toast.error(error.message)
          }
        } finally {
        setLoading(false); // Set loading state to false when request completes (whether success or failure)
      }
      }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='w-full p-4 h-[100%] md:rounded-xl  md:border md:border-gray-100 md:bg-white lg:flex lg:flex-row-reverse lg:items-center lg:justify-center '>
                <div className='p-2 w-full md:flex md:justify-center  '>
                    <img src={LoginImage} alt='IMAG' className='md:w-3/4  lg:w-3/4 ' />
                </div>
                <div className='w-full md:mt-8 lg:mt-0'>
                    <div className='w-full ml-20 space-y-4'>
                        <div className='w-full text-xl font-bold text-GlobalColor '>Forgot Password</div>
                        <div className='w-1/2 text-sm font-normal  text-black'>
                            Enter your <span className='font-bold'>Consumer Number</span> and we will send you 6 digit code for verification
                        </div>
                    </div>
                    <div className='w-full ml-20 space-y-4'>

                        <div className='w-full md:w-3/4 '>
                            <LInputComp iconName={(className: string) => <User className='lg:w-5 lg:h-5  w-6 h-6 md:mr-2 -mt-3' />}
                                // label='Enter Phone Number'
                                placeholder='Enter Consumer Number'
                                id='phone'
                                type='text'
                                regex={new RegExp('/[^0-9]+/g')}
                                maxLength={10}
                                errorMessage={formik.touched.consumerNo && formik.errors.consumerNo ? formik.errors.consumerNo : ''}
                                value={formik.values.consumerNo}
                                onchange={(e: any) => formik.setFieldValue('consumerNo', String(e))}

                            />
                        </div>
                        <div className='w-full md:w-3/4 '>
                            <Buttons btnName='Get OTP' data-qa='confirmbtn' type='submit' id='confirmbtn' loading={loading}/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default EnterMobileNo