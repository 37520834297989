import React, { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";


import { WithChildren } from "src/helpers";
import AccessAuthorityCheck from "src/modules/AccessAuthorityCheck";

import { useAuth } from "src/modules/Auth/Core";

import AppLayout from "src/modules/Layouts/AppLayout";
import WalletDetails from "src/modules/WalletHistory/WalletDetails/WalletDetails";

const AppRoutes = () => {
  // console.log("app route")
  const { currentUser, auth } = useAuth();
  const NewDashboardMain = lazy(
    () => import("src/modules/NewDashboard/NewDashboardMain")
  );
  const HistoryMains = lazy(() => import("src/modules/History/HistoryMains"));
  const TradeMain = lazy(() => import("src/modules/Trade/TradeMain"));
  const ProfileMain = lazy(() => import("src/modules/NewProfile/ProfileRoutePage"));
  // const PortfolioPage=lazy(()=>import('src/Pages/PortfolioPage/PortfolioPage'))
  const VerificationReportMain = lazy(
    () => import("src/modules/VerificationReport/VerificationReportMain")
  );
  const ContactUs = lazy(
    () => import("src/modules/Auth/Contactus/Component/ContactUs")
  );
  const BillMain = lazy(() => import('src/modules/Bill&Payment/BillMain'))
  // const BillMain = lazy(
  //   () => import("src/modules/New_Bill&Payment/NewBillPaymentMain")
  // );
  const StepwiseRegistrationMain = lazy(
    () => import("src/modules/StepwiseRegistration/StepwiseRegistrationMain")
  );
  const NewTradeMain = lazy(() => import("src/modules/NewTrade/NewTradeMain"));
  const NpclDashboardMain = lazy(
    () => import("src/modules/NpclDashboard/NpclDashboardMain")
  );
  const UserSelection = lazy(
    () => import("../modules/Auth/UserSelection/UserSelectionMain")
  );
  const NewPortFolioMain = lazy(
    () => import("../modules/NewPortfolio/NewPortFolioMain")
  );
  const FaqMain = lazy(
    () => import("src/modules/Auth/FAQPage/FaqMain")
  );

  const WalletHistoryMain = lazy(
    () => import("src/modules/WalletHistory/WalletHistoryMain")
  );
  {/* <Route path='/faqs' element={<FaqMain />} /> */ }
  return (
    <Routes>
      <Route element={<AppLayout />}>
        {auth?.isDefault ? (
          <Route path="/*" element={<Navigate to="/dashboard" />} />
        ) : (
          <Route path="/*" element={<Navigate to="/user-selection" />} />
        )}

        {/* <Route path="/dashboard1" element={<DashboardMain />} /> */}
        <Route
          path="/npcl-dashboard"
          element={
            <SuspensedView>
              <NewDashboardMain />
            </SuspensedView>
          }
        />
        {/* <Route path="/my-dashboard" element={<MyDashboard />} /> */}
        <Route
          path="/order-history"
          element={
            <SuspensedView>
              <HistoryMains />
            </SuspensedView>
          }
        />
        <Route
          path="/trade"
          element={
            <SuspensedView>
              <AccessAuthorityCheck>
                <TradeMain />
              </AccessAuthorityCheck>
            </SuspensedView>
          }
        />
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <ProfileMain />
            </SuspensedView>
          }
        />
        <Route
          path="/portfolio"
          element={
            <SuspensedView>
              <NewPortFolioMain />
            </SuspensedView>
          }
        />
        <Route
          path="faq"
          element={
            <SuspensedView>
              <FaqMain />
            </SuspensedView>
          }
        />
        {/* <Route
          path="/testing-report"
          element={
            <SuspensedView>
              <VerificationReportMain />
            </SuspensedView>
          }
        /> */}
        <Route
          path="/contact-us"
          element={
            <SuspensedView>
              <ContactUs />
            </SuspensedView>
          }
        />
        {/* {
                    auth?.consumerType === ENUM_CONSUMER_TYPE?.PROSUMER ? (
                        auth?.isLoadDetailsAvl && auth?.isMeterDetailsAvl && auth?.isPlantDetailsAvl && auth?.isVerified === 'APPROVED' ? (
                            <Route path='/*' element={<Navigate to="/dashboard" />} />
                        ) : (

                            <Route path="/step" element={<SuspensedView><StepwiseRegistrationMain /></SuspensedView>} />
                        )
                    ) : (
                        auth?.isLoadDetailsAvl && auth?.isMeterDetailsAvl && auth?.isVerified === 'APPROVED' ? (

                            <Route path='/*' element={<Navigate to="/dashboard" />} />
                        ) : (
                            <Route path="/step" element={<SuspensedView><StepwiseRegistrationMain /></SuspensedView>} />
                        )
                    )
                } */}

        <Route
          path="/invoice"
          element={
            <SuspensedView>
              <BillMain />
            </SuspensedView>
          }
        />
        <Route
          path="/portfolio-trading"
          element={
            <SuspensedView>
              <NewTradeMain />
            </SuspensedView>
          }
        />
        <Route
          path="/wallet-history"
          element={
            <SuspensedView>
              <WalletHistoryMain />
            </SuspensedView>
          }
        />
         <Route
          path="/wallet-details"
          element={
            <SuspensedView>
              <WalletDetails />
            </SuspensedView>
          }
        />

        <Route
          path="/dashboard"
          element={
            <SuspensedView>
              <NpclDashboardMain />
            </SuspensedView>
          }
        />
      </Route>
      <Route
        path="user-selection"
        element={
          <SuspensedView>
            <UserSelection />
          </SuspensedView>
        }
      />


    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }: any) => {
  // const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      "0": "#0EA5E9",
    },
    barThickness: 2,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default AppRoutes;
