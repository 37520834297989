import React from "react";
import PrivacyPolicyHeader from "./Component/PrivacyPolicyHeader";
import { Card } from "src/components/ui/card";
import { Link } from "react-router-dom";
import PrivarcyPolicyContent from "./Component/PrivarcyPolicyContent";

const PrivacyPolicyMain = () => {
  return (
    <div className="w-full p-12 bg-primaryDarkBlue h-screen  font-Roboto text-gray-600 font-normal text-sm">
      <Card className="w-full bg-white h-full p-4 rounded-sm overflow-y-scroll no-scrollbar">
        <PrivarcyPolicyContent />
      </Card>
    </div>
  );
};

export default PrivacyPolicyMain;
