import { X } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'src/components/ui/button'
import { onboardingReducerState } from 'src/redux/Slicer/onboardingSlicer'
import { RootState } from 'src/redux/store'

const ConsumerErrorMessage = () => {

  const { verifyTokenData }: any = useSelector((state: RootState) => onboardingReducerState(state))



  return (
    <div className='w-full h-full flex justify-center items-center text-black'>
      <div className='w-2/5 bg-[#F5F5F5] py-4 rounded-lg'>
        <div className='w-full flex justify-center items-center my-2'>
          <div className='w-8 h-8 rounded-full flex justify-center items-center bg-[#FF6341]'><X className='text-white w-5 h-5' /></div>
        </div>
        <div className='w-full text-center text-sm font-medium uppercase text-grayLable mb-2'>Error while checking user</div>
        <div className='w-full text-center text-[10px] font-normal uppercase text-grayLable'>{verifyTokenData?.reason===null ||verifyTokenData?.reason===undefined?'Consumer not found':verifyTokenData?.reason}</div>
        <div className='w-full flex justify-center items-center mt-4'>
          {/* <Button className='bg-green_1 hover:bg-green_1 uppercase text-xs font-medium px-4 ' onClick={<a></a>}>Go to npcl portal</Button> */}
          <a  className='bg-green_1 hover:bg-green_1 text-white uppercase text-xs font-medium p-3 rounded-xl' href='https://www.noidapower.com/' target='_blank'>Go to npcl portal</a>
        </div>

      </div>
    </div>
  )
}

export default ConsumerErrorMessage