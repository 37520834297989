import { combineReducers, configureStore } from "@reduxjs/toolkit";
import regestrationSicer from "./Slicer/regestrationSlicer";
import dashboardSlicer from "./Slicer/dashboardSlicer";
import dropDownSlice from "./Slicer/dropDownSlice";
import loginSlicer from "./Slicer/loginSlicer";
import prosumerConListSlicer from "./Slicer/prosumerConListSlicer";
import historySlicer from "./Slicer/historySlicer";
import portfolioSlicer from "./Slicer/portfolioSlicer";
import stepwiseRegestrationSlicer from "./Slicer/stepwiseRegestrationSlicer";
import billSlicer from "./Slicer/billSlicer";
import errorHadlingSlicer from "./Slicer/errorHadlingSlicer";
import directTradingSlicer from "./Slicer/directTradingSlicer";
import profileSlicer from "./Slicer/profileSlicer";
import onboardingSlicer from "./Slicer/onboardingSlicer";
// import  notificationSlicer  from "./Slicer/NotificationSlicer";
import notificationSlicer from "./Slicer/notificationSlicer";
import {
  persistStore,
  persistReducer,

  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import walletReducerState from "./Slicer/walleteSlicer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["registration", "login", "onboarding"], // Only these slices will be persisted
};

const rootReducer = combineReducers({
 
  registration: regestrationSicer,
  dashboard: dashboardSlicer,
  dropDown: dropDownSlice,
  login: loginSlicer,
  list: prosumerConListSlicer,
  history: historySlicer,
  portFolio: portfolioSlicer,
  stepWise: stepwiseRegestrationSlicer,
  bill: billSlicer,
  errorBoundry: errorHadlingSlicer,
  direct: directTradingSlicer,
  profile: profileSlicer,
  onboarding: onboardingSlicer,
  notification: notificationSlicer,
  wallet: walletReducerState,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // reducer: {
  //     registration: regestrationSicer,
  //     dashboard: dashboardSlicer,
  //     dropDown: dropDownSlice,
  //     login: loginSlicer,
  //     list: prosumerConListSlicer,
  //     history: historySlicer,
  //     portFolio: portfolioSlicer,
  //     stepWise: stepwiseRegestrationSlicer,
  //     bill: billSlicer,
  //     errorBoundry: errorHadlingSlicer,
  //     direct:directTradingSlicer,
  //     profile:profileSlicer,
  //     onboarding:onboardingSlicer
  // }
  reducer: persistedReducer,
})
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
