import React from 'react'
import { Outlet } from 'react-router-dom'

const LoginLayout = () => {
    return (
        <>
            <div className='w-full h-[100%] md:bg-primaryBlue lg:relative'>
                <div className='w-full lg:fixed h-[100%] md:border-x-[80px] md:border-y-[80px] lg:border-x-[80px] lg:border-y-[80px] md:border-[#0670B0] lg:bg-primaryBlue'>

                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default LoginLayout
