import { Eye, EyeOff, LockIcon } from 'lucide-react'
import React, { InputHTMLAttributes, useState } from 'react'
import { Input } from 'src/components/ui/input'
import { Label } from 'src/components/ui/label'
interface InputsComp extends InputHTMLAttributes<HTMLInputElement> {

    lable?: string
    lableClass?: string,
    errorMessage?: string
    cls?: string
    values?: any;
    onchange?: (e: any) => void
}
const InputPassword: React.FC<InputsComp> = ({ lable, lableClass, errorMessage, cls, values,onchange, ...rest }) => {
    const [showPassword, setShowPassword] = useState(false);
    // const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);

    };
    return (
        <div className='w-full my-2'>
            <Label htmlFor={rest.id} className={`text-grayLable text-sm font-bold ml-2 ${lableClass}`}>{lable}</Label>
            <div className=" w-full relative mt-2  shadow-sm ">
                <div className="pointer-events-none absolute inset-y-0 -left-1 flex items-center px-2">
                    <LockIcon className='lg:w-6 lg:h-6  w-6 h-6 md:mr-2 p-1' />
                </div>
                <Input
                value={values}
                    type={showPassword ? 'text' : 'password'}
onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
   
      let cleanedValue = e.target.value
      onchange && onchange(Number(cleanedValue))
  
  }}
                    {...rest}
                    className={`block w-full border border-input border-[#575757]   rounded-md py-1.5 pl-7 pr-20 placeholder:text-gray-400 placeholder:text-xs  ${cls}`} />

                <span className='cursor-pointer absolute inset-y-0 right-2 flex items-center'>
                    {showPassword ? (
                        <EyeOff size={16} onClick={togglePasswordVisibility} />
                    ) : (
                        <Eye size={16} onClick={togglePasswordVisibility} />
                    )}
                </span>
            </div>

            <p className='text-sm text-red-600 ml-2 font-medium'>{errorMessage}</p>
        </div>
    )
}

export default InputPassword

