import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Navigations from 'src/Navigation/index';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { AuthProvider } from './modules/Auth/Core';
import { OnlineStatusProvider } from './modules/Auth/Core/OnlineStatusContext';
import { PersistGate } from 'redux-persist/integration/react';

// Disable Right-Click
document.addEventListener('contextmenu', (event) => {
  event.preventDefault();
});

// Disable Developer Tools Shortcut Keys
document.addEventListener('keydown', (event) => {
  if (
    event.key === 'F12' || 
    (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J')) ||
    (event.ctrlKey && event.key === 'U')
  ) {
    event.preventDefault();
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <OnlineStatusProvider>
      <AuthProvider>
        <Navigations />
      </AuthProvider>
      </OnlineStatusProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
