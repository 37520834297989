import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";

import { DataTablePagination } from "../components/data-table-pagination";
import { Loader2 } from "lucide-react";
import { ScrollArea } from "src/components/ui/scroll-area";
import { DataTablePaginationServer } from "./data-table-pagination_server";
import { PaginationState } from "src/helpers";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isLoading: boolean,
  pagination?: PaginationState
}

export function DataTable<TData, TValue>({ columns, data, isLoading, pagination: data_pagination }: DataTableProps<TData, TValue>) {

  const [rowSelection, setRowSelection] = React.useState({});

  const [current_pagination, setPagination] = React.useState<any>({
    pageIndex: 0,
    pageSize: 10,
  })

  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    rowCount: data_pagination?.items_per_page,
    enableRowSelection: true,
    manualPagination: true, 
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="w-full space-y-4 h-full">
      {/* <DataTableToolbar table={table} /> */}

      <div className="w-full  rounded-md border h-full">
        <ScrollArea className="w-full  whitespace-nowrap rounded-md border h-full overflow-auto  md:w-full md:whitespace-normal " >
          <Table className="w-full h-full relative">
            <TableHeader
            className={` w-full text-xs sticky top-0 left-0 right-0 table-auto z-99999`}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => {
                  const original: any = row.original;

                  return (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                      className="z-99"
                    >
                      {row.getVisibleCells().map((cell) => {
                        // console.log("finding penny",cell)
                        return (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {isLoading ? <div className='flex justify-center items-center '>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                    </div> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>


    </div>


  );
}
