import { ParkingMeter, Check } from 'lucide-react'
import React, { useMemo } from 'react'
import { Card } from 'src/components/ui/card'

type Props = {}

const MeterDetails = (data: any) => {

    // console.log(data?.data)
    const basicDetails = useMemo(() => data?.data, [data])
    // basicDetails?.map((data: any, index: number) => { console.log(data?.meterNo) })

    return (
        <div className='grid grid-flow-col space-x-6'>
            {basicDetails && basicDetails?.map((data: any, index: number) =>
                <Card className='rounded-md p-4 shadow-none w-64 border-black'>
                    <div className='grid grid-flow-col  space-x-8'>
                        <div className=''>
                            <ParkingMeter />
                        </div>
                        <div className='font-bold'>
                            {data?.meterNo ? data?.meterNo : "-"}
                        </div>
                        <div className="w-[25px] h-[25px] rounded-full bg-green_1 flex justify-center items-center">
                            <Check className="text-white" size={16} strokeWidth={"2px"} />
                        </div>
                    </div>
                </Card>
            )}
        </div>
    )
}

export default MeterDetails