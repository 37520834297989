import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons"

import { Button } from "src/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select"
import { PaginationState } from "src/helpers"



interface DataTablePaginationPropsState {
  pagination: PaginationState,
  onChangeItemPerPage: (e: any) => void
  onChangePage: (e: any) => void
}


export function DataTablePaginationServer({ pagination, onChangeItemPerPage, onChangePage }: DataTablePaginationPropsState) {

  const startIndex = ((Number(pagination.page) - 1) * pagination.items_per_page) + 1
  const endIndex = Math.min(startIndex + pagination.items_per_page, parseInt(pagination.total_records || '0', 10));

  return (
    <div className="w-full flex items-center justify-between px-2">

      <div className="w-full flex justify-between items-center space-x-2 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium hidden sm:flex">Rows per page</p>
          <Select
            value={(pagination.items_per_page || 10).toString()}
            onValueChange={(value) => {
              onChangeItemPerPage(value)
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={pagination.items_per_page || "10"} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div>Showing <span>{startIndex}</span> to <span>{endIndex}</span> of <span>{pagination?.total_records}</span></div>
        </div>
        <div className="flex items-center">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium ">
            Page {pagination.page} of{" "}
            {pagination.last_page}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => onChangePage(1)}
              disabled={pagination.page == 1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => onChangePage(Number(pagination.page) - 1)}
              disabled={pagination.page == 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => onChangePage(Number(pagination.page) + 1)}
              disabled={Number(pagination.last_page) == Number(pagination.page)}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => onChangePage(Number(pagination.last_page))}
              disabled={Number(pagination.last_page) == Number(pagination.page)}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>

      </div>
    </div>
  )
}
