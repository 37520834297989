import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { RootState } from "../store"
import axios, { AxiosError } from "axios"
import { getCalenderData, getMarketDepth, getNextTrades, getOTSGraphDetails } from "../../service/services"
import { QueryState, stringifyRequestQuery } from "../../helpers"
import { stat } from "fs/promises"
import { toast } from "sonner"
// import { toast } from 'react-toastify';

interface DashboardState extends QueryState {
    dashBoardState: boolean,
    tradesData: any,
    start_date: string | undefined,
    end_date: string | undefined,
    error: boolean,
    isLoading: boolean,
    message: string | undefined,
    dataChanged: boolean,
    currentCalenderData: any,
    user: any,
    page: number
    items_per_page: 10 | 30 | 50 | 100,
    calenderData: any,
    nextTradesData: any,
    sellDepthData: any,
    buyDepthData: any,
}

const initialState: DashboardState = {
    dashBoardState: true,
    start_date: undefined,
    end_date: undefined,
    tradesData: [],
    error: false,
    isLoading: false,
    message: undefined,
    dataChanged: false,
    items_per_page: 10,
    page: 1,
    currentCalenderData: undefined,
    user: undefined,
    calenderData: [],
    nextTradesData: [],
    sellDepthData: [],
    buyDepthData: [],
}

const transformToQueryString = (data: any) => {

    const filterkeys: string[] = ["start_date", "end_date", "user", ];
    const f_keys: string[] = [""]
    const q_keys: string[] = [""];

    let filters: any = {};
    let _f: any = {};
    let _q: any = {};

    data && Object.entries(data).map(([key, value]) => {
        if (filterkeys.length > 0 && filterkeys.includes(key)) {
            filters[key] = value
        }
        else if (f_keys.length > 0 && f_keys.includes(key)) {
            _f[key] = value
        }
        else if (q_keys.length > 0 && q_keys.includes(key)) {
            _q[key] = value
        }
    })

    // console.log({ filters, _f, _q });


    return stringifyRequestQuery({
        filter: filters,
        f: _f,
        q: _q,
        items_per_page: data.items_per_page,
        page: data.page
    })

}

export const calederDataFetch: any = createAsyncThunk(
    'dashboard/calederDataFetch',
    async (data: any, thunkApi: any) => {

        try {
            const getFilterState = thunkApi.getState().dashboard;
            // console.log(getFilterState);

            let newFilterState = { ...getFilterState }

            delete newFilterState["items_per_page"];
            delete newFilterState["page"];

            const queryString = transformToQueryString(newFilterState);
            // console.log({ queryString })
            const response: any = await getCalenderData(data?.user_id, queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                toast.error(errorMessage)
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                toast.error(error.message)
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }

)

export const tradesDataFetch: any = createAsyncThunk(
    'dashboard/tradesDataFetch',
    async (data: any, thunkApi: any) => {

        try {
            const getFilterState = thunkApi.getState().dashboard;
            // console.log(getFilterState);
            console.log(data)

            let newFilterState = { ...getFilterState }

            delete newFilterState["items_per_page"];
            delete newFilterState["page"];

            if (data && data?.switchValue == 'market') {
                delete newFilterState["user"];
            }



            const queryString = transformToQueryString(newFilterState);
            // console.log({ queryString })
            const response: any = await getOTSGraphDetails(queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                toast.error(errorMessage)
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                toast.error(error.message)
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }

)

export const marketDepthBuyDataFetch: any = createAsyncThunk(
    'dashboard/marketDepthBuyDataFetch',
    async (data: any, thunkApi: any) => {


        try {
            const { currentDate }: any = data;

            const getFilterState = thunkApi.getState().dashboard;
            // console.log(getFilterState);

            let newFilterState = { ...getFilterState }

            // delete newFilterState["items_per_page"];
            delete newFilterState["page"];
            delete newFilterState["user"];
            delete newFilterState["start_date"];
            delete newFilterState["end_date"];

            const queryString = transformToQueryString(newFilterState);
            console.log({ queryString })
            console.log(data)

            const postData = {
                date: currentDate,
                purchaseType: "buy",
                orderType: "market"
            }

            const response: any = await getMarketDepth(postData, queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                toast.error(errorMessage)
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                toast.error(error.message)
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }

)

export const marketDepthSellDataFetch: any = createAsyncThunk(
    'dashboard/marketDepthSellDataFetch',
    async (data: any, thunkApi: any) => {

        try {
            const { currentDate }: any = data;
            const getFilterState = thunkApi.getState().dashboard;
            // console.log(getFilterState);

            let newFilterState = { ...getFilterState }

            // delete newFilterState["items_per_page"];
            delete newFilterState["page"];
            delete newFilterState["user"];
            delete newFilterState["start_date"];
            delete newFilterState["end_date"];


            const queryString = transformToQueryString(newFilterState);
            // console.log({ queryString })

            const postData = {
                date: currentDate,
                purchaseType: "sell",
                orderType: "market"
            }

            const response: any = await getMarketDepth(postData, queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                toast.error(errorMessage)
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                toast.error(error.message)
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }

)

export const nextTradesDataFetch: any = createAsyncThunk(
    'dashboard/nextTradesDataFetch',
    async (data: any, thunkApi: any) => {

        try {
            const getFilterState = thunkApi.getState().dashboard;
            // console.log(getFilterState);

            let newFilterState = { ...getFilterState }

            delete newFilterState["items_per_page"];
            delete newFilterState["page"];

            const queryString = transformToQueryString(newFilterState);
            // console.log({ queryString })
            const response: any = await getNextTrades(queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.data?.message;
                // console.log(errorMessage);
                toast.error(errorMessage)
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                // console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                // console.log(error.response?.data.message)
                toast.error(error.response?.data.message)
                return thunkApi.rejectWithValue(error.response?.data.message);

            } else {
                toast.error(error.message)
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }

)



export const dashboardSlicer = createSlice({
    name: "dashboardSlicer",
    initialState,
    reducers: {
        updateOpen(state, action: PayloadAction<any>) {
            state.dashBoardState = action.payload
            // console.log(state?.dashBoardState)
        },
        updateCurrentCalederData(state, action: PayloadAction<any>) {
            state.currentCalenderData = action.payload
        },
        updateStartDate(state, action: PayloadAction<any>) {
            state.start_date = action.payload
        },
        updateUser(state, action: PayloadAction<any>) {
            state.user = action.payload
        },

        updateEndDate(state, action: PayloadAction<any>) {
            state.end_date = action.payload
        },
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        setPaggination: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload;
            // state.page = action.payload.pageIndex;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(tradesDataFetch.fulfilled, (state, { payload }) => {
            state.tradesData = payload;
            state.isLoading = false;
            state.error = false;
            state.message = undefined
        });
        builder.addCase(tradesDataFetch.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
        });
        builder.addCase(tradesDataFetch.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            // state.message = payload || 'Error Occured'
        });

        builder.addCase(nextTradesDataFetch.fulfilled, (state, { payload }) => {
            state.nextTradesData = payload;
            state.isLoading = false;
            state.error = false;
            state.message = undefined
        });
        builder.addCase(nextTradesDataFetch.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
        });
        builder.addCase(nextTradesDataFetch.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            // state.message = payload || 'Error Occured'
        });

        builder.addCase(marketDepthBuyDataFetch.fulfilled, (state, { payload }) => {
            state.buyDepthData = payload;
            state.isLoading = false;
            state.error = false;
            state.message = undefined
        });
        builder.addCase(marketDepthBuyDataFetch.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
        });
        builder.addCase(marketDepthBuyDataFetch.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            // state.message = payload || 'Error Occured'
        });

        builder.addCase(marketDepthSellDataFetch.fulfilled, (state, { payload }) => {
            state.sellDepthData = payload;
            state.isLoading = false;
            state.error = false;
            state.message = undefined
        });
        builder.addCase(marketDepthSellDataFetch.pending, (state) => {
            state.isLoading = true;
            state.error = false;
            state.message = "";
        });
        builder.addCase(marketDepthSellDataFetch.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = true;
            // state.message = payload || 'Error Occured'
        });
    }


})

export const { updateOpen, updateStartDate, updateEndDate, setError, resetChangedState, resetError, updateCurrentCalederData, updateUser, setPaggination } = dashboardSlicer.actions;
export const dashboardReducerState = (state: RootState) => state.dashboard;
export default dashboardSlicer.reducer;

