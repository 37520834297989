import * as Yup from "yup";

export interface LoginViaPhoneProps {
  phone: string | undefined;
  checkvalue: string | undefined;
  password: string | undefined;
}

export interface LoginViaEmailProps {
  email: string | undefined;
  checkvalue: string | undefined;
  password: string | undefined;
}

export const validationSchemaLoginViaPhone = Yup.object().shape({
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .required("Mobile number is required"),
  checkvalue: Yup.string().optional(),
  password: Yup.string()
    .required("Password is required")
    .min(8, 'Password must contain 8 or more characters')
    .max(18, 'Password must be less than or equal to 18 characters')
    .test('lowercase', 'Password must contain at least 1 lowercase letter', value => /[a-z]/.test(value))
    .test('uppercase', 'Password must contain at least 1 uppercase letter', value => /[A-Z]/.test(value))
    .test('number', 'Password must contain at least 1 number', value => /[0-9]/.test(value))
    .test('special', 'Password must contain at least 1 special character', value => /[!@#$%^&*(),.?":{}|<>]/.test(value)),
});

export const LoginViaPhoneInitialVal = {
  phone: '',
  checkvalue: undefined,
  password: '',
};

export const LoginViaEmailInitialVal = {
  email: '',
  checkvalue: undefined,
  password: '',
};

export interface LoginViaConsumerNoProps {
  consumerNo: string | undefined;
  checkvalue: boolean;
  password: string | undefined;
}
export const validationSchemaLoginViaCosumerNo = Yup.object().shape({
  consumerNo: Yup.string()
    .matches(/^[0-9]{10}$/, "Consumer number must be 10 digits")
    .required("Consumer number is required")
    .matches(/^[0-9]+$/, "Consumer number must contain only digits"),
  checkvalue: Yup.boolean().optional(),
  password: Yup.string()
  .required("Password is required")
  .min(8, 'Password must contain 8 or more characters')
  .max(18, 'Password must be less than or equal to 18 characters')
  .test('lowercase', 'Password must contain at least 1 lowercase letter', value => /[a-z]/.test(value))
  .test('uppercase', 'Password must contain at least 1 uppercase letter', value => /[A-Z]/.test(value))
  .test('number', 'Password must contain at least 1 number', value => /[0-9]/.test(value))
  .test('special', 'Password must contain at least 1 special character', value => /[!@#$%^&*(),.?":{}|<>]/.test(value)),
});

export const LoginViaConsumerNoInitialVal = {
  consumerNo: undefined,
  checkvalue: false,
  password: undefined,
};

export const validationSchemaLoginViaEmail = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  checkvalue: Yup.string().optional(),
  password: Yup.string()
  .required("Password is required")
  .min(8, 'Password must contain 8 or more characters')
  .max(18, 'Password must be less than or equal to 18 characters')
  .test('lowercase', 'Password must contain at least 1 lowercase letter', value => /[a-z]/.test(value))
  .test('uppercase', 'Password must contain at least 1 uppercase letter', value => /[A-Z]/.test(value))
  .test('number', 'Password must contain at least 1 number', value => /[0-9]/.test(value))
  .test('special', 'Password must contain at least 1 special character', value => /[!@#$%^&*(),.?":{}|<>]/.test(value)),
});
