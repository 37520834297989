// import React, { useState } from "react";
// import LoginViaConsumerNo from "./Components/LoginViaConsumerNo";

// import LOtp from "./Components/LOtp";
// import { ENUM_STATUS_CODE, toAbsoluteUrl } from "src/helpers";
// import { Link } from "react-router-dom";
// import { useAuth } from "../Core";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "src/redux/store";
// import { loginReducerState } from "src/redux/Slicer/loginSlicer";
// import { loginOtpValidateApi } from "src/service/services";
// import { updateError, updateErrorCode } from "src/redux/Slicer/errorHadlingSlicer";
// import axios, { AxiosError } from "axios";
// const NpclBanner = toAbsoluteUrl("/asset/image/NPCL_Login_Page_with_Oct.png");
// const OCT_LOGO = toAbsoluteUrl("/asset/image/OCT_Logo_Horizontal.png");
// const NewOtpPage = () => {
//   const [newotp, setNewOtp] = useState<string>();
//   const [loading, setLoading] = useState<boolean>(false);
//   const { setCurrentUser, saveAuth } = useAuth();
//   const dispatch = useDispatch();
//   const { phone, consumerNo } = useSelector((state: RootState) =>
//     loginReducerState(state)
//   );

//   const otpVerification = async () => {
//     setLoading(true);

//     try {
//       let postData = {
//         consumerNo: consumerNo,
//         otp: newotp,
//       };
//       let apiResp = await loginOtpValidateApi(postData);
//       dispatch(updateErrorCode(apiResp.code));
//       if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
//         setCurrentUser({
//           userResponse: apiResp?.data?.user,
//           isAuthenticated: true,
//           user_id: apiResp?.data?.user?.id,
//           email: apiResp?.data?.user?.email,
//           phone: apiResp?.data?.user?.phone,
//           name: apiResp?.data?.user?.name,
//         });

//         saveAuth({
//           api_token: apiResp.data?.tokens?.access?.token,
//           refreshToken: apiResp.data?.tokens?.refresh?.token,
//           user_id: apiResp?.data?.user?._id,
//           consumerType: apiResp?.data?.user?.consumerType,
//           isLoadDetailsAvl: apiResp?.data?.user?.isLoadDetailsAvl,
//           isMeterDetailsAvl: apiResp?.data?.user?.isMeterDetailsAvl,
//           isPlantDetailsAvl: apiResp?.data?.user?.isPlantDetailsAvl
//             ? apiResp?.data?.user?.isPlantDetailsAvl
//             : false,
//           user_name: apiResp?.data?.user?.name,
//           isVerified: apiResp?.data?.user?.isVerified,
//           endUserID: apiResp?.data?.user?.endUserID,
//           isDefault: apiResp?.data?.user?.isDefault,
//           consumerNumber: apiResp?.data?.user?.consumerNumber,
//           userCode: apiResp?.data?.user?.usercode,
//           verificationRemark: apiResp?.data?.user?.verificationRemark,
//         });
//       } else {
//         dispatch(updateErrorCode(apiResp.code));
//         dispatch(updateError(apiResp.message));
//       }
//       return apiResp.data;
//     } catch (err) {
//       let error = err as Error | AxiosError;
//       if (axios.isAxiosError(error)) {
//         dispatch(updateErrorCode(error.response?.data.code));
//         dispatch(updateError(error.response?.data.message));
//       } else {
//         dispatch(
//           updateError("An unexpected error occurred. Please try again later.")
//         );
//       }
//     } finally {
//       setLoading(false);
//     }
//   };
//   return (


//     <div className="w-full h-screen font-Roboto overflow-y-scroll">
//     <div
//       className="w-full h-full flex flex-col md:items-end items-center justify-center relative bg-primaryBlue/10 bg-contain xl:bg-cover bg-no-repeat"
//       style={{
//         backgroundImage: `url(${NpclBanner})`,
//       }}
//     >
//       <div className="w-[90%] md:w-[40%] lg:w-[35%] xl:w-[30%] py-4 sm:py-2 px-6 md:py-4 lg:py-4 lg:mr-10 md:mr-2 border-2 border-border_primaryDarkBlue rounded-xl md:-mt-40 lg:mt-0 xl:mt-40 2xl:mt-28 mt-48 xsm:mt-52 sm:mt-96">

//         <LOtp />
//       </div>

//       <div className="w-full absolute top-[25%] xxsm:top-[27%] xsm:top-[29%] sm:top-[48%]  md:top-[70%] lg:top-[82%] xl:top-[88%] 2xl:top-[90%] lg:pl-10 xl:pl-20 2xl:pl-32">
//         <div className="w-full flex flex-col sm:flex-row sm:justify-center lg:justify-stretch md:items-center text-sm lg:text-lg font-normal font-Roboto space-x-2">
//           <div className="space-x-2 flex items-center justify-center ">
//             <div>In association with</div>
//             <img
//               src={OCT_LOGO}
//               alt="oct_logo"
//               className="w-[126px] h-[35px] lg:w-[190px] lg:h-[50px]"
//             />
//           </div>

//           <div className="flex justify-center items-center space-x-2">
//             <span>For more information or registration </span>{" "}
//             <Link
//               to={
//                 "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
//               }
//               target="_blank"
//               className="text-text_primaryDarkBlue underline underline-offset-4 hover:underline-offset-8"
//             >
//               Click Here
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>

//   </div>
//   );
// };

// export default NewOtpPage;


import React from "react";
import a from "public/asset/image/NPCL.png";
import { toAbsoluteUrl } from "src/helpers";
import { Link } from "react-router-dom";
import LOtp from "./Components/LOtp";


const BLUE_BACKGROUND = toAbsoluteUrl("/asset/image/Blue_background.png");
const NPCL_SAURMITRA = toAbsoluteUrl("/asset/image/NPCL_Saur_1.png");
const NPCL_main = toAbsoluteUrl("/asset/image/NPCL_Main.png");
const RP_POWER = toAbsoluteUrl("/asset/image/RPSG-P2P.png");
const Npcl_logo = toAbsoluteUrl("/asset/image/NPCL.png");
const OCT_LOGO = toAbsoluteUrl("/asset/image/OCT_Logo_Horizontal.png");
const NewOtpPage = () => {
  return (
    <div
      className="w-full   bg-no-repeat bg-cover font-Roboto"
      style={{
        backgroundImage: `url(${BLUE_BACKGROUND})`,
      }}
    >
      <div className="w-full flex flex-col md:flex-row  md:h-[80%]">
        <div className="md:w-[50%]  lg:w-[60%] w-full space-y-1">
          <div className="w-full px-2 xsm:px-10 sm:px-16 md:px-4 lg:px-32 text-xs sm:text-sm md:text-[16px] lg:text-xl font-medium font-Montserrat my-10 space-y-1">
            <div className="w-full pl-2">Presenting</div>
            <img src={NPCL_SAURMITRA} alt="npcl-saurmitra" />
            <div className="pl-2">
              A <b>Peer to Peer Energy Trading</b> Platform
            </div>
            <div className="pl-2">
              <span className="whitespace-nowrap">
                For more information or registration{" "}
              </span>{" "}
              <Link
                to={
                  "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                }
                target="_blank"
                className="text-text_primaryDarkBlue text-xs md:text-sm underline underline-offset-4 hover:underline-offset-8 whitespace-nowrap"
              >
                Click Here
              </Link>
            </div>
          </div>

          <div className="w-full px-14 hidden md:flex">
            <img src={NPCL_main} alt="npcl-main" className="" />
          </div>
        </div>
        <div className="md:w-[50%] lg:w-[40%] w-full flex flex-col  h-full  justify-between items-center md:mt-8 lg:mt-12 px-2 md:px-0">
          <div className="flex space-x-20 items-center md:px-10 md:py-10 py-2">
            <img src={RP_POWER} alt="RP_POWER" className="w-24 xl:w-40"/>
            <img src={Npcl_logo} alt="NPCL-LOGO" className="w-24 xl:w-40"/>
          </div>

          <div className="sm:w-[80%] w-full border-2 border-border_primaryDarkBlue rounded-xl py-8 px-10 ">
 
            <LOtp />
          </div>
        </div>
      </div>


      <div className="w-full  space-x-2  h-[20%] bg-primaryDarkBlue/10 md:bg-none flex flex-col md:flex-row items-center md:mt-8 justify-center ">
        <div>In association with</div>
        <img src={OCT_LOGO} alt="oct_logo" className="" />
      </div>
    </div>
  );
};

export default NewOtpPage;
