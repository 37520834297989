import { Check } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Buttons from 'src/components/ui/common/Button/Buttons'

type Props = {
    setOpen: (val: boolean) => void
}

const ConfirmationModal = ({ setOpen }: Props) => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/')
        setOpen(false)
    }
    return (
        <div>
          

            <div className="w-full flex flex-col items-center">
                <div className="w-12 h-12 bg-green_1 rounded-full flex justify-center items-center">
                    {/* <img src={TickMark} className="w-full" /> */}
                    <Check className="text-white w-14 font-bold" strokeWidth={'2px'} />
                </div>
                <div className="text-2xl font-bold text-text_green_1 mt-2">Congratulations!</div>
                <div className="w-full   space-y-2 my-2 text-center">
                    <p className="text-sm font-semibold text-grayLable">Your P2P account along with wallet has been credited successfully</p>
                    <p className="font-normal text-xs text-grayLable">We've sent a confirmation email to your registered email address.</p>
                    <p className="font-normal text-xs text-grayLable">Please check your inbox.</p>

                </div>
                <div className="w-full mt-2 mb-4 ">
                    <Buttons type='submit'
                        onClick={handleClick} btnName="OK" />
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal