import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "../../../components/ui/card";
import React, { useEffect, useMemo, useState } from "react";
import {
  AlignJustify,
  Bell,
  BellIcon,
  History,
  LogOut,
  MapPin,
  Minus,
  RotateCcw,
  Search,
  User,
} from "lucide-react";
import Logo from "src/assets/images/Electreecity-LogoPNG.png";
import { getAuth, useAuth } from "../../Auth/Core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, otherMenuForHeading } from "../SideBar/modules";
import dayjs from "dayjs";
import { SidebarProps } from "../SideBar/Sidebarlayout";
import { convertToCamelCase } from "../../../helpers";
import SwitchUser from "../../../assets/images/SwitchUser.svg";
import NotificationBell from "./NotificationBell";

const Headers = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const { logout, auth } = useAuth();

  const { pathname } = useLocation();
  const menuOptions = [...Menu, ...otherMenuForHeading];

  const heading = useMemo(() => {
    for (const cur of menuOptions) {
      if (cur.path && pathname === cur.path) {
        return cur.name;
      } else if (cur.options && cur.options.length > 0) {
        for (const ind of cur.options) {
          if (ind.path && pathname.includes(ind.path)) {
            return `${cur.name}-${ind.name}`;
          }
        }
      }
      // else if (pathname.includes("profile")) {
      //   return "My Profile";
      // }
    }
    return ""; // Default case if no match found
  }, [menuOptions, pathname]);

  const [currentTime, setCurrentTime] = useState(new Date());


  const formattedDate = dayjs(currentTime).format("DD-MM-YYYY");
  const formattedTime = dayjs(currentTime).format("HH:mm ");
  const navigate = useNavigate();
  return (
    <header className="w-full sticky top-0 z-999 flex bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-md drop-shadow-md lg:rounded-md">
      <nav className="w-full flex items-center justify-between h-12 px-1  md:py-4 md:px-6">
        <div className="text-sm text-textLightBlack font-normal flex  items-center space-x-4">
          <div className="md:hidden sm:flex">
            <div
              className=" p-1 bg-gray-200 rounded-md "
              onClick={() => setSidebarOpen(!sidebarOpen)}
              data-testid="alignJustify"
            >
              <AlignJustify />
            </div>
          </div>
          <div> {heading}</div>
        </div>
        <div className="flex space-x-2 items-center">
        <div
            className="flex text-textLightBlack text-sm font-normal whitespace-nowrap"
            title={`Platform Id : ${auth?.userCode}`}
          >
            {" "}
            {auth?.user_name || "-"} {`(${auth?.consumerNumber || ""})`}
          </div>
          

          <div className=" rounded-full p-1 whitespace-nowrap">
            <Link to="/user-selection" title="Switch User">
              {/* <User color="#FFFFFF" size={20} /> */}
              <img src={SwitchUser} alt="switchUser" className="w-6 h-6" />
            </Link>
          </div>
          <NotificationBell />

        </div>
      </nav>
    </header>
  );
};

export default Headers;
