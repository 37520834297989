import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";
import axios, { AxiosError } from "axios";
import { updateError } from "./errorHadlingSlicer";

import { stringifyRequestQuery } from "../../helpers";

interface StepwiseRegestration {
    error: boolean,
    message: string | undefined,
    isLoading: boolean,
    currentStep: number,
    currentUserInfo: any,
    dataChanged: boolean,

    loadDetail:any
    meterDetails:any
    plantDetail:any
}

const stepwiseInitialState: StepwiseRegestration = {
    error: false,
    message: undefined,
    isLoading: false,
    currentStep: 1,

    currentUserInfo: {
        form_1_loadDetails: {
            monthlyAverage: undefined,
            dailyAverage: undefined,
            load: undefined,
        },
        form_2_MeterDetails: {
            serialNumber: undefined,
            meterNo: undefined,
            manufacturer: undefined,
            model: undefined,
        },
        form_3_PlantDetails: {
            meterNo: undefined,
            type: "solar",

            installedCapacity: undefined,

            moduleType: undefined,
            numberOfPanels: undefined,
            mountingType: undefined,
            azimuth: undefined,
            tiltAngle: undefined,
            inverterManufacturer: undefined,
            numberOfInverters: undefined,
            substation: undefined,
            feeder: undefined,
            transformer: undefined,
            moduleManufacturer: undefined,
            plantCode: undefined

        }
    },

    
    dataChanged: false,

    loadDetail:{
        monthlyAverage: undefined,
        dailyAverage: undefined,
        load: undefined,
    },
    meterDetails:{
        serialNumber: undefined,
        meterNo: undefined,
        manufacturer: undefined,
        model: undefined,
    },
    plantDetail:{
        meterNo: undefined,
        type: "solar",
        //   plantCode: undefined,
        //   name: undefined,
        installedCapacity: undefined,
        //   contractCap: undefined,
        //   lifeSpan: undefined,
        //   dailyAvg: undefined,
        //   monthlyAvg: undefined,
        // panelMfg: undefined,
        // panelTyp: undefined,
        moduleType: undefined,
        numberOfPanels: undefined,
        mountingType: undefined,
        azimuth: undefined,
        tiltAngle: undefined,
        inverterManufacturer: undefined,
        numberOfInverters: undefined,
        substation: undefined,
        feeder: undefined,
        transformer: undefined,
        moduleManufacturer: undefined,
        plantCode: undefined
    }
}
const transformToQueryString = (data: any) => {
    const filterkeys: string[] = [
        "start_date",
        "end_date",
        "purchase_type",
        "user",
        'orderType',
        ' date'
    ];
    const f_keys: string[] = [""];
    const q_keys: string[] = [""];

    let filters: any = {};
    let _f: any = {};
    let _q: any = {};

    data &&
        Object.entries(data).map(([key, value]) => {
            if (filterkeys.length > 0 && filterkeys.includes(key)) {
                filters[key] = value;
            } else if (f_keys.length > 0 && f_keys.includes(key)) {
                _f[key] = value;
            } else if (q_keys.length > 0 && q_keys.includes(key)) {
                _q[key] = value;
            }
        });



    return stringifyRequestQuery({
        filter: filters,
        f: _f,
        q: _q,
        items_per_page: data.items_per_page,
        page: data.page,
    });
};



export const stepwiseRegestrationSlicer = createSlice({
    name: 'regester/stepwiseRegestrationSlicer',
    initialState: stepwiseInitialState,
    reducers: {
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        setCurrentStep(state, action: PayloadAction<any>) {
            state.currentStep = action.payload
        },
        setCurrentUserInfo(state, action: PayloadAction<any>) {
            state.currentUserInfo = { ...state.currentUserInfo, ...action.payload }
        }
    },


})


export const { setError, resetError, resetChangedState, setCurrentStep, setCurrentUserInfo } = stepwiseRegestrationSlicer.actions;
export const stepwiseReducerState = (state: RootState) => state.stepWise;
export default stepwiseRegestrationSlicer.reducer;