import React, { useEffect, useMemo, useRef, useState } from "react";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
// @ts-ignore
import debounce from "lodash.debounce";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../../../../components/ui/input-otp";
import Buttons from "src/components/ui/common/Button/Buttons";
import { loginOtpValidateApi, loginWithoutOtp } from "src/service/services";
import { useDispatch, useSelector } from "react-redux";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Slicer/errorHadlingSlicer";
import { RootState } from "src/redux/store";
import {
  onboardingReducerState,
  updateOtpOBJ,
} from "src/redux/Slicer/onboardingSlicer";
import { ENUM_STATUS_CODE } from "src/helpers";
import axios, { AxiosError } from "axios";
import { useAuth } from "../../Core";
import { Button } from "src/components/ui/button";

const OtpOnbording = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>();
  const[isOtop,setIsOtp]=useState<boolean>(false)
  const { urlObj, otpData,verifyTokenData }: any = useSelector((state: RootState) =>
    onboardingReducerState(state)
  );
  const [timeRemaining, setTimeRemaining] = useState(180);
  // const [resendTimere,setResendTimer]=useState(false)
  const { setCurrentUser, saveAuth } = useAuth();
  const dispatch = useDispatch();

  const phoneRef = useRef(urlObj);


  const objKey = useMemo(
    () => verifyTokenData && Object.keys(verifyTokenData).length > 0 && Object.keys(verifyTokenData)[0],
    [verifyTokenData]
  );
  

  const otpVerification = async () => {
    setLoading(true);
    try {
      let postData = {
        ...otpData,
        otp: Number(otp),
      };
      let apiResp = await loginOtpValidateApi(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        setCurrentUser({
          // ...apiResp?.data?.user,
          userResponse: apiResp?.data?.user,
          isAuthenticated: true,
          user_id: apiResp?.data?.user?.id,
          email: apiResp?.data?.user?.email,
          phone: apiResp?.data?.user?.phone,
          name: apiResp?.data?.user?.name,
          // ...apiResp?.data
        });

        saveAuth({
          api_token: apiResp.data?.tokens?.access?.token,
          user_id: apiResp?.data?.user?._id,
          consumerType: apiResp?.data?.user?.consumerType,
          isLoadDetailsAvl: apiResp?.data?.user?.isLoadDetailsAvl,
          isMeterDetailsAvl: apiResp?.data?.user?.isMeterDetailsAvl,
          isPlantDetailsAvl: apiResp?.data?.user?.isPlantDetailsAvl
            ? apiResp?.data?.user?.isPlantDetailsAvl
            : false,
          user_name: apiResp?.data?.user?.name,
          isVerified: apiResp?.data?.user?.isVerified,
          endUserID: apiResp?.data?.user?.endUserID,
          isDefault: apiResp?.data?.user?.isDefault,
          consumerNumber: apiResp?.data?.user?.consumerNumber,
          userCode: apiResp?.data?.user?.usercode,
          verificationRemark:apiResp?.data?.user?.verificationRemark
        });
      } else {
        dispatch(updateErrorCode(apiResp.code));
        dispatch(updateError(apiResp.message));
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateErrorCode(error.response?.data.code));
        dispatch(updateError(error.response?.data.message));
      } else {
        dispatch(updateError(error.message));
      }
    } finally {
      setLoading(false);
    }
  };

  const resendAgainLogin = async () => {
    setOtp("");

    try {
      let postData = {
        consumerNo: verifyTokenData?.consumerNo || null,
        email: verifyTokenData?.email || null,
        type: objKey && objKey,
        phone: verifyTokenData?.phone || null,
      };
      let apiResp: any = await loginWithoutOtp(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        // dispatch(updateSuccessMess("Otp sent sucessfully"));
        dispatch(updateOtpOBJ(apiResp.data && apiResp.data));
        setIsOtp(true)
        dispatch(
          updateSuccessMess(
            "We've resent the OTP to your registered number/email"
          )
        );
        // toast.success('Otp sent sucessfully')
        setTimeRemaining(180);
      } else {
        dispatch(updateError(apiResp.message));
        dispatch(updateErrorCode(apiResp.code));
        // toast.error(apiResp.message)
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateError(error.response?.data.messagee));
        dispatch(updateErrorCode(error.response?.data?.code));
        // toast.error(error.response?.data.message)
      } else {
        dispatch(updateError(error.message));
        // toast.error(error.message)
      }
    } finally {
      // Set loading state to false when request completes (whether success or failure)
    }
  };

  const debouncedResendAgainLogin = debounce(resendAgainLogin, 100);

  useEffect(() => {
    if (phoneRef) {
      
      debouncedResendAgainLogin();
    }
  }, [phoneRef]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ Call submit function here
        otpVerification();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [otp]);

  useEffect(() => {
    if(timeRemaining===0){
      setIsOtp(false)
    }
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  const isOtpComplete = useMemo(() => otp ? otp.length < 6 : true, [otp]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div>
        <h1 className="w-full text-center text-GlobalColor text-2xl font-bold">
          VERIFY USER
        </h1>
        <div className="w-full text-center font-normal text-xs text-grayLable">
          Peer To Peer Energy Trading Application
        </div>
        <div className="w-full text-center mt-8 font-semibold text-sm ">
          Please enter the verification code received on your registered mobile
          number.
        </div>
        <div className="w-full text-center mt-6 font-normal text-xs text-grayLable">
          The code will expire in {
            isOtop?`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
          :`3:00`} 
        </div>

        <div className="w-full flex justify-center mt-2">
          <InputOTP
            maxLength={6}
            pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
            className=""
            value={otp}
            onChange={(e: string) => setOtp(e)}
          >
            <InputOTPGroup className="">
              <InputOTPSlot index={0} />
            </InputOTPGroup>
            <InputOTPGroup className="">
              <InputOTPSlot index={1} />
            </InputOTPGroup>
            <InputOTPGroup className="">
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPGroup className="">
              <InputOTPSlot index={3} />
            </InputOTPGroup>
            <InputOTPGroup className="">
              <InputOTPSlot index={4} />
            </InputOTPGroup>
            <InputOTPGroup className="">
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </div>
        <div className="w-full text-center font-normal text-grayLable text-xs mt-2">
          Didn’t receive any code?
          {/* <span
            className="text-red-400 font-bold cursor-pointer hover:underline underline-offset-2 ml-1"
            role="button"
            onClick={() => resendAgainLogin()}
          >
            Resend 
          </span>{" "} */}
          <Button
            className="text-red-500 text-sm font-semibold cursor-pointer ml-1 bg-white hover:bg-white border border-none"
            // role="button"
            disabled={(timeRemaining === 0)||isOtpComplete}
            onClick={resendAgainLogin}
            variant={"link"}
          >
            Resend
          </Button>
        </div>
        <div className="w-full flex justify-center items-center mt-6">
          <div className="w-1/2">
            <Buttons
              onClick={otpVerification}
              btnName={`${
                otp?.split("")?.length === 6 ? "CONFIRM" : "VERIFY  & PROCEED"
              }`}
              data-qa="otpVerifys"
              id="otpVerify"
              // disabled={!isOtpComplete}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpOnbording;
