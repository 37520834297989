import React from "react";
import { Link } from "react-router-dom";
import LoginViaConsumerNo from "./Components/LoginViaConsumerNo";
import P2penrgyBenefits from "./LandingPage/P2penrgyBenefits";
import { toAbsoluteUrl } from "src/helpers";
import P2pEnergyOurProducts from "./LandingPage/P2pEnergyOurProducts";
import P2pEnergyHowItWorks from "./LandingPage/P2pEnergyHowItWorks";
import P2pDownloadApp from "./LandingPage/P2pDownloadApp";
import Faq from "../FAQPage/Component/Faq";
import FaqMain from "../FAQPage/FaqMain";
import FaqLoginPage from "../FAQPage/FaqLoginPage";
import LoginLanding from "./LandingPage/LoginLanding";

const NewLoginPage = () => {
  const NPCL_REDIRECTION = process.env.REACT_APP_BASE_URL_NPCL_REDIRECTION_URL;
  const NpclBanner = toAbsoluteUrl("/asset/image/NPCL_Login_Page_with_Oct.png");
  const OCT_LOGO = toAbsoluteUrl("/asset/image/OCT_Logo_Horizontal.png");

  const IMG_P2P_ENERGY_BENEFITS_BG = toAbsoluteUrl(
    "/asset/image/P2PBenifitBackground.png"
  );
  return (
    // <div className="w-full h-screen font-Roboto overflow-y-scroll">
    //   <div
    //     className="w-full h-full flex flex-col md:items-end items-center justify-center relative bg-primaryBlue/10 bg-contain xl:bg-cover bg-no-repeat"
    //     style={{
    //       backgroundImage: `url(${NpclBanner})`,
    //     }}
    //   >
    //     <div className="w-[90%] md:w-[40%] lg:w-[35%] xl:w-[30%] py-10 sm:py-2 px-6 md:py-4 lg:py-10 lg:mr-10 md:mr-2 border-2 border-border_primaryDarkBlue rounded-xl md:-mt-40 lg:mt-0 xl:mt-40 2xl:mt-28 mt-0 xsm:mt-20 sm:mt-60">
    //       <div className="text-lg font-bold">Login</div>
    //       <div className="text-sm text-text_primaryDarkBlue  font-semibold mt-2">
    //         Consumer Number
    //       </div>
    //       <LoginViaConsumerNo />
    //     </div>

    //     <div className="w-full absolute top-[80%]  xsm:top-[85%] sm:top-[90%] md:top-[61%] lg:top-[18%] xl:top-[26%] 2xl:top-[24%]">
    //       <div className="w-full flex justify-center text-sm lg:text-lg font-bold font-Roboto items-center space-x-2 lg:pr-56 xl:pr-44 ">
    //         <span className="whitespace-nowrap">For more information or registration </span>{" "}
    //         <Link
    //           to={
    //             "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
    //           }
    //           target="_blank"
    //           className="text-text_primaryDarkBlue underline underline-offset-4 hover:underline-offset-8 whitespace-nowrap"
    //         >
    //           Click Here
    //         </Link>
    //       </div>
    //     </div>
    //     <div className="w-full absolute top-[85%]  xsm:top-[90%] sm:top-[95%]  md:top-[65%] lg:top-[75%] xl:top-[90%] 2xl:top-[93%] lg:pl-10 ">
    //       <div className="w-full space-x-2 flex flex-col xxsm:flex-row  items-center justify-center ">
    //         <div>In association with</div>
    //         <img
    //           src={OCT_LOGO}
    //           alt="oct_logo"
    //           className=""
    //         />
    //       </div>
    //       {/* <div className="w-full flex flex-col sm:flex-row sm:justify-center lg:justify-stretch md:items-center text-sm lg:text-lg font-normal font-Roboto space-x-2">
    //         <div className="w-full space-x-2 flex items-center justify-center ">
    //           <div>In association with</div>
    //           <img
    //             src={OCT_LOGO}
    //             alt="oct_logo"
    //             className="w-[126px] h-[35px] lg:w-[190px] lg:h-[50px]"
    //           />
    //         </div>

    //         <div className="flex justify-center items-center space-x-2">
    //           <span>For more information or registration </span>{" "}
    //           <Link
    //             to={
    //               "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
    //             }
    //             target="_blank"
    //             className="text-text_primaryDarkBlue underline underline-offset-4 hover:underline-offset-8"
    //           >
    //             Click Here
    //           </Link>
    //         </div>
    //       </div> */}
    //     </div>
    //   </div>
    //   <P2penrgyBenefits />
    //   <P2pEnergyOurProducts />
    //   <P2pEnergyHowItWorks />
    //   <P2pDownloadApp />
    //   <div
    //     className="w-full h-full px-8"
    //     style={{
    //       backgroundImage: "url(" + IMG_P2P_ENERGY_BENEFITS_BG + ")",
    //       backgroundRepeat: "no-repeat",
    //       backgroundSize: "cover",
    //       backgroundPosition: "center",
    //     }}
    //   >
    //     <FaqLoginPage />
    //   </div>
    // </div>

    <div className="w-full  font-Roboto overflow-y-scroll">
      <LoginLanding />
      <P2penrgyBenefits />
      <P2pEnergyOurProducts />
      <P2pEnergyHowItWorks />
      <P2pDownloadApp />
    </div>
  );
};

export default NewLoginPage;
